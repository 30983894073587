import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { RecientesComponent } from './recientes/recientes.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { ConsultasComponent } from './consultas/consultas.component';
import { InformacionComponent } from './informacion/informacion.component';
import { SalirComponent } from './salir/salir.component';
import { MisdatosComponent } from './misdatos/misdatos.component';
import {APP_BASE_HREF} from '@angular/common';
import { MisasuntosComponent } from './misasuntos/misasuntos.component';
import { MisdocumentosComponent } from '../documentos/misdocumentos/misdocumentos.component';
import { EnviarComponent } from '../documentos/enviar/enviar.component';
import { ImpuestosComponent } from '../documentos/impuestos/impuestos.component';

import { NominasComponent } from '../empleados/nominas/nominas.component';
import { ResnomComponent } from '../empleados/resnom/resnom.component';
import { DocsempComponent } from '../empleados/docsemp/docsemp.component';
import { CostesComponent } from '../empleados/costes/costes.component';
import { PortalComponent } from '../empleados/portal/portal.component';
import { HorarioComponent } from '../empleados/horario/horario.component';
import { SolicitudesComponent } from '../empleados/solicitudes/solicitudes.component';
import { HdiarioComponent } from '../empleados/hdiario/hdiario.component';
import { HdiarioglobalComponent } from '../empleados/hdiarioglobal/hdiarioglobal.component';
import { ConsultapermisosComponent } from '.././empleados/consultapermisos/consultapermisos.component';
import { ConsultaglobalComponent } from '.././empleados/consultaglobal/consultaglobal.component';
import { ConsultaglobalmonthComponent } from '.././empleados/consultaglobalmonth/consultaglobalmonth.component';

import { ReportglobalComponent } from '.././empleados/reportglobal/reportglobal.component';
import { ReportglobalmonthComponent } from '.././empleados/reportglobalmonth/reportglobalmonth.component';
import { MisspunchSolicitudesComponent } from '../empleados/misspunch-solicitudes/misspunch-solicitudes.component';
import { IncidenciasComponent } from '../empleados/incidencias/incidencias.component';
import { ComplaintsComponent } from '.././empleados/complaints/complaints.component';
import { ClientcollaboratorsComponent } from '.././empleados/clientcollaborators/clientcollaborators.component';
import { ClientcollaboratorComponent } from '.././empleados/clientcollaborator/clientcollaborator.component';

import { ClientdepartmentsComponent } from '.././empleados/clientdepartments/clientdepartments.component';
import { ClientdepartmentComponent } from '.././empleados/clientdepartment/clientdepartment.component';


import { ClientprojectsComponent } from '.././empleados/clientprojects/clientprojects.component';
import { ClientprojectComponent } from '.././empleados/clientproject/clientproject.component';
import { ClientprojectdetailComponent } from '.././empleados/clientprojectdetail/clientprojectdetail.component';
const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    children: [
          
		  {
            path: 'recientes',
            component: RecientesComponent
      },
      {
        path: 'docenviar',
        component: EnviarComponent
      },
      {
        path: 'docmisdocs',
        component: MisdocumentosComponent
      },
      {
        path: 'docimpuestos',
        component: ImpuestosComponent
      },
      {
        path: 'incidencias',
        component: IncidenciasComponent
      },
      {
        path: 'nominas',
        component: NominasComponent
      },
      
      {
        path: 'portalemp',
        component: PortalComponent
      },

      {
        path: 'consultapermisos',
        component: ConsultapermisosComponent
      },
      {
        path: 'consultaglobal',
        component: ConsultaglobalComponent
      },
      {
        path: 'consultaglobalmonth',
        component: ConsultaglobalmonthComponent
      },
      {
        path: 'complaints',
        component: ComplaintsComponent
      },
      {
        path: 'clientcollaborators',
        component: ClientcollaboratorsComponent
      },
      {
        path: 'clientcollaborator',
        component: ClientcollaboratorComponent
      },
      {
        path: 'clientdepartments',
        component: ClientdepartmentsComponent
      },
      {
        path: 'clientdepartment',
        component: ClientdepartmentComponent
      },
      {
        path: 'clientprojects',
        component: ClientprojectsComponent
      },
      {
        path: 'clientproject',
        component: ClientprojectComponent
      },
      {
        path: 'clientprojectdetail',
        component: ClientprojectdetailComponent
      },
      
      {
        path: 'horarioemp',
        component: HorarioComponent
      },

      {
        path: 'hdiario/:idEmpleado/:ej/:mes',
        component: HdiarioComponent
      },
      {
        path: 'hdiarioglobal/:idEmpleado/:ej/:fromMes/:toMes',
        component: HdiarioglobalComponent
      },
      {
        path: 'hdiarioglobal/:idEmpleado/:ej/:mes',
        component: HdiarioglobalComponent
      },
      {
        path: 'reportglobal/:idEmpleado/:idClient/:ej/:mes',
        component: ReportglobalComponent
      },
      {
        path: 'reportglobalMonth/:idEmpleado/:idClient/:ej/:frommes/:tomes',
        component: ReportglobalmonthComponent
      },
      
      {
        path: 'solicitudesemp',
        component: SolicitudesComponent
      },
      {
        path: 'misspunchsolicitudesemp',
        component: MisspunchSolicitudesComponent
      },

      {
        path: 'resnom',
        component: ResnomComponent
      },
      {
        path: 'docsemp',
        component: DocsempComponent
      }
      ,
      {
        path: 'costes',
        component: CostesComponent
      },
		 
		  {
            path: 'misasuntos',
            component: MisasuntosComponent
			
          },
		  {
            path: 'consultas',
            component: ConsultasComponent
			
          },
		  {
            path: 'misdatos',
            component: MisdatosComponent
			
          },
		  {
            path: 'informacion',
            component: InformacionComponent
			
          },
		  {
            path: 'salir',
            component: SalirComponent
          },
     
        ]
      }
    ];


@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule { }

