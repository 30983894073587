import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType,HttpHeaders } from  '@angular/common/http';
import { map } from  'rxjs/operators';
import {GlobaldataService } from './globaldata.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  SERVER_URL: string = "http://localhost:8080/serviceODE/";
  
  constructor(private httpClient: HttpClient,private gds:GlobaldataService) { }
  
  public upload(data,uploadURL) {
    return this.httpClient.post<any>(uploadURL, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
		//console.log(event.type);
      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
		  //console.log("=>"+event.loaded);
          return { type:event.type,status: 'progress', message: progress };

        case HttpEventType.Response:
          return { type:event.type,response:event.body};
        default:
			//console.log("->"+event);
			return {type:event.type, response:`Unhandled event: ${event.type}`};
      }
    })
    );
  }
  /* ========================================================
  CODIGO NECESARIO PARA LLAMAR - CON NOTIFICACIONES DE PROGRESO:
    let results;
	await new Promise((resolve, reject) => {
		let su=this.us.upload(formData,this.gds.urlBaseDL+'files').subscribe((res)=>{
		if(res['type']==HttpEventType.Response)
		{
				su.unsubscribe();
				resolve(res);
		}
	});	
	}).then((res)=>{
		results=res;
		console.log(res);
	});
	============================================================*/
  
	public uploadWithoutProgress(data,uploadURL) {
		return this.httpClient.post<any>(uploadURL, data, {}).toPromise();
	}

   public uploadPUT(data,uploadURL) {
		
	//return this.httpClient.post<any>(uploadURL, data);
	
    return this.httpClient.put<any>(uploadURL, data);
	
  }
  
  
  
	public formToDict(formulario:any)
	{
		let map = new Map<string,string>();
		
		for (let ele=0;ele<formulario.nativeElement.elements.length;ele++)
		{
			console.log(formulario.nativeElement.elements[ele].name+" " +formulario.nativeElement.elements[ele].type);
			console.log(formulario.nativeElement.elements[ele].value);
			if(formulario.nativeElement.elements[ele].type=='text')
				map.set(formulario.nativeElement.elements[ele].name,formulario.nativeElement.elements[ele].value);
			else if(formulario.nativeElement.elements[ele].type=='checkbox')
				map.set(formulario.nativeElement.elements[ele].name,formulario.nativeElement.elements[ele].value);
				
		}
		return map;
	}
	
	public submitForm(formulario,uploadURL,dict) 
	{
		//  ====================================================
		//let dict={uno:'1234',dos:'3456'};
		/*let dict:any= new Map<string,string>();
		dict.set("A",'1');
		dict.set("B",'2');
		dict.set("C",'3');
		*/
		//  ====================================================
		
		const formData = new FormData();
				
		let map2:Map<string,string>=this.formToDict(formulario);
		console.log(map2);
		map2.forEach((value: string, key: string) => {
			//console.log(key, value);
			formData.append(key, value);
		});
		
		if(dict instanceof Map) // Map<string,string>
		{	
			dict.forEach((value: string, key: string) => {
				//console.log(key, value);
				formData.append(key, value);
			});
		}
		else if(dict instanceof Object) //Object
		{
			for(let o in dict)
			{
				//console.log(o,dict[o]);
				formData.append(o, dict[o]);
			}
		}
		
		for (let ele=0;ele<formulario.nativeElement.elements.length;ele++)
		{
			if(formulario.nativeElement.elements[ele].type=='file')
			{
				const files: { [key: string]: File } = formulario.nativeElement.elements[ele].files;
				for (let key in files)
				{
					if (!isNaN(parseInt(key))) 
					{
						console.log("Ficheros:"+key+" ===> " +files[key]+" " +files[key].name);
						formData.append(formulario.nativeElement.elements[ele].name, files[key], files[key].name);
					}
				}
			}
		}
			
		return this.upload(formData,uploadURL);
	}
  
	public viewDoc(ev)
	{
		var id=0;
		if(ev.target.hasAttribute("data-id"))
			id=ev.target.attributes['data-id'].value;
		else
			if(ev.target.parentElement.hasAttribute("data-id"))
				id=ev.target.parentElement.attributes['data-id'].value;
			else
				id=0;
		console.log(id);
		if(id>0)
		{
			window.open(this.gds.urlBaseDL+'documentos/'+id+'/file?updateVisto=true');
		}
		return false;
	}
  
  
}