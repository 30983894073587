<div class="grid r-grid r-grid12-6 clientcollaborator-css" style="width:'100%';">
    
    <div class="g-w12field">
        <input type="hidden" id="idClient_collaborator" [disabled]="readonly" [(ngModel)]="clientcollaborator.idClient_collaborator"
         />
        

        </div> 
        

    <div class="g-w12field">
        <label for="complaintPrivacy">Empresa</label>

        <div class=" mt-5">
         <div class="row">
             <div class="col-md-6">
               <ul class="list-group">
                  <li class="list-group-item" *ngFor="let item of categoryList">
                    <input type="checkbox" [(ngModel)]="item.isSelected" name="list_name" value="{{item.value}}" (change)="isAllSelectedCheck()" [readonly]="item.readonly"/>
                    {{item.label}}
                  </li>
                </ul>
               </div>
            </div>
         </div>
        <!-- <p-dropdown placeholder="Seleccione"  id="clientId"  [options]="categoryList" [(ngModel)]="clientcollaborator.clientId"  [showClear]="true"  [readonly]="true"></p-dropdown> -->
        
         
    </div>

  
   

    

    <div class="g-w12field" >
        <label>Colaborador</label>
        <input pInputText id="collab_name" [(ngModel)]="clientcollaborator.collab_name" styleClass="fecha-hora"   />
    </div>



    <div class="g-w12field" >
        <label>Usuario/Email</label> 
        <input pInputText id="collab_email" [(ngModel)]="clientcollaborator.collab_email" styleClass="fecha-hora"    />
    </div>

    <div class="g-w12field" >
        <label>Contraseña</label>
        <input pInputText id="collab_password" [(ngModel)]="clientcollaborator.collab_password" styleClass="fecha-hora"    />
    </div>















    <div class="g-w12field">
       <input type="checkbox" [(ngModel)]="clientcollaborator.access_documents_recent"  name="access_documents_recent"  class="checkboxWidth"   />
        Restringir acceso a: Documentos: Recientes
         
    </div>

    <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_documents_senddocument"  name="access_documents_senddocument" class="checkboxWidth" />
        Restringir acceso a: Documentos: Enviar documentos
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_documents_mydocument"  name="access_documents_mydocument" class="checkboxWidth" />
        Restringir acceso a: Documentos: Mis documentos
          
     </div>


     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_documents_taxes"  name="access_documents_taxes" class="checkboxWidth" />
        Restringir acceso a: Documentos: Impuestos
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_consultation"  name="access_consultation" class="checkboxWidth" />
        Restringir acceso a: Consulta
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_issues"  name="access_issues"  class="checkboxWidth"/>
        Restringir acceso a: Asuntos
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_information"  name="access_information"class="checkboxWidth" />
        Restringir acceso a: Información
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_incidences"  name="access_labor_incidences" class="checkboxWidth" />
        Restringir acceso a: Laboral: Incidencias
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_names"  name="access_labor_names" class="checkboxWidth" />
        Restringir acceso a: Laboral: Nóminas
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_payrollsummery"  name="access_labor_payrollsummery" class="checkboxWidth" />
        Restringir acceso a: Laboral: Resumen nóminas
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_docemployees"  name="access_labor_docemployees"  class="checkboxWidth" />
        Restringir acceso a: Laboral: Doc. Empleados
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_paidgrandchildren"  name="access_labor_paidgrandchildren" class="checkboxWidth" />
        Restringir acceso a: Laboral: Pagos netos
          
     </div> 

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_employeeportal"  name="access_labor_employeeportal"  class="checkboxWidth"/>
        Restringir acceso a: Laboral: Portal empleados
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_request"  name="access_labor_request" class="checkboxWidth" />
        Restringir acceso a: Laboral: Solicitudes
          
     </div>


     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_entryexit_request"  name="access_labor_entryexit_request"  class="checkboxWidth"/>
        Restringir acceso a: Laboral: Solicitudes Entrada/Salida
          
     </div>


     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_time"  name="access_labor_time" class="checkboxWidth" />
        Restringir acceso a: Laboral: Horario
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_checkpermissions"  name="access_labor_checkpermissions" class="checkboxWidth" />
        Restringir acceso a: Laboral: Consulta permisos
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_globalquery"  name="access_labor_globalquery" class="checkboxWidth" />
        Restringir acceso a: Laboral: Consulta global
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_labor_complaintsportal"  name="access_labor_complaintsportal" class="checkboxWidth" />
        Restringir acceso a: Laboral: Portal Denuncias
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_mydata"  name="access_mydata" class="checkboxWidth" />
        Restringir acceso a: Mis datos
          
     </div>

     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_collaborator"  name="access_collaborator" class="checkboxWidth" />
        Restringir acceso a: Colaboradores
          
     </div>


     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_mylatest_documents"  name="access_mylatest_documents" class="checkboxWidth" />
        Restringir acceso a: Mis últimos documentos 
          
     </div>


     <div class="g-w12field">
        <input type="checkbox" [(ngModel)]="clientcollaborator.access_mylatest_affairs"  name="access_mylatest_affairs"  class="checkboxWidth"/>
        Restringir acceso a: Mis últimos asuntos 
          
     </div>







    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w2">
               
                <p-button *ngIf="!readonly" label="Guardar" (click)="solicitar()"></p-button>
                <p-button *ngIf="readonly" label="Guardar" (click)="saveClientcollaborator()" style="margin-right:10px"></p-button>
                <!-- <p-button *ngIf="readonly" label="Guardar" (click)="saveClientcollaborator()" style="margin-right:10px"></p-button> -->
               
            </div>
        </div> 
    </div>


</div>

<p-toast position='center'></p-toast>