<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Report</p>

<!-- <button (click)="exportAsExcel()">Exportar como Excel</button> -->

<br/>


	
<p-toast position='center'></p-toast>





            
    <div class="p-grid p-justify-start">

        <div class="g-w4field">
            <div class="f-box f-jcsb">
                <div class="f-w1" style="margin-top:10px"><p-button  (onClick)="exportAsExcel()">Exportar como Excel</p-button></div>
            </div>
        </div>

    <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let filamonths of allmonths">
    <table id='tablaModelos' border="1" class='tablaCuadro' (click)="gds.viewDoc($event)" #excelTable >
    <thead>
        <tr><td>{{filamonths.monthName}} {{ejercicio}} </td><td colspan="32"></td></tr>
    
   

    <tr><td>DIAS</td><td>01</td><td>02</td><td>03</td><td>04</td><td>05</td><td>06</td><td>07</td><td>08</td><td>09</td><td>10</td><td>11</td><td>12</td><td>13</td><td>14</td><td>15</td><td>16</td><td>17</td><td>18</td><td>19</td><td>20</td><td>21</td><td>22</td><td>23</td><td>24</td><td>25</td><td>26</td><td>27</td><td>28</td><td>29</td><td>30</td><td>31</td><td>Total</td></tr>
</thead>

   

    <tbody  *ngIf="!loading[filamonths.month]" >
    <tr *ngFor="let fila of allsaldos[filamonths.month]" [ngClass]="fila.styleClass">
        <td>
           
            {{fila.empleado}} {{fila.idEmpleado}} 
        </td>
        <td *ngFor="let i of rango_modelos">
            <span [innerHTML]="htmlStr[i]"></span>
           
            {{fila[i]}}
        
        </td>
        <td>{{fila.total}} </td>  
    </tr>
    <!-- <tr><td>Total</td><td colspan="31"></td><td >{{subtotal[filamonths.month]}}</td> </tr> -->
    <tr>
        <td>Total</td>
        <td *ngFor="let k of totalMonthDay[filamonths.month]">
           
            {{k}}
        
        </td>
        <td >{{subtotal[filamonths.month]}}</td> 
    </tr>
   
  </tbody>
 

    <tbody  *ngIf="loading[filamonths.month]">
    <tr>
    <td colspan="33" >Loading..
        <div id="loader">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="loading"></div>
        </div>

    </td>
    </tr>
    </tbody>

   

    </table>
    </div>







 

    <div class="p-col-12 p-md-12 p-lg-8">
    <BR/>
    <table><TR><TD>Empresa/s :</TD>

        <TD *ngFor="let k of allClientsStyle" [ngClass]="k.styleClass" style='background:"k.styleClass"'>{{k.Cliente}}</TD>

       
    
    </TR></table>
    </div>
    
    </div>
