import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { Router,ActivatedRoute,Params  } from "@angular/router";

import { Input,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import {MenuItem} from 'primeng/api';     
import {ChartModule} from 'primeng/chart';
import {TableModule} from 'primeng/table';

import { FechaTipadaPipe } from '../../libs/fechaTipada.pipe';
import { Utilidades } from '../../libs/utilidades';
import { UtilService } from '../../servicios/util.service';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {FileUpload} from 'primeng/fileupload';

import { Respuesta } from '../../respuesta';
import { stringify } from 'querystring';
import { map } from 'rxjs/internal/operators/map';
import * as moment from 'moment';

@Component({
  selector: 'app-reportglobal',
  templateUrl: './reportglobal.component.html',
  styleUrls: ['./reportglobal.component.scss']
})
export class ReportglobalComponent implements OnInit {
  [x: string]: any;

  idEmpleado:Number=0;
  saldos:any=[];
  allsaldos:any=[];
  ejercicio: number;
  mes:string="01";
  empleado:string="";
  idClient:string="";
  getDayCountStr:any={};
  subtotal:any="";

  name = "Angular ";
  htmlStr: any = "";

  @ViewChild("myNameElem") myNameElem: ElementRef;
  @ViewChild("myNameElem12") myNameElem12: ElementRef;
  getValue() {
  console.log("ooooooooooooooooo",this.myNameElem.nativeElement.value);
  this.myNameElem12.nativeElement.value = "soko123";

  this.htmlStr = 'aaaaaaaaaaaaaaaaaaa';
  
  }
  resetValue() {
  this.myNameElem.nativeElement.value = "soko";
  }
  

   //itemsArbol:TreeNode[];	
	//items10: MenuItem[];
	//menuId: number=0;
	data: any;
	cars:any;
	//ejercicios:any;
	//ejercicio: number=0;
	ejercicio2: number=0;
	documento:string="";
	rango_modelos:any;
	color:any;
	
	//materias:any;
	materia:number=0;
	tiposModelo:any;
	tipos:any;
	strFilterModelos:string="";
	//strFilterMaterias:string="";
	tabla:Array<Array<Object>>=[];

  idClientArr:any = [];
  allClients:any = [];
  allClientsStyle:any = [];
  styleClassArr:any = [];
  returnval:any="";
  nodes:any = [];
  getDayCountItem:any = [];
  month:any = [];
  disfrutados:any = [];
  loading:any = false;

	
	//@ViewChild('envioDoc') envioDoc:FileUpload;  //ElementRef
  
  constructor(private httpClient: HttpClient,public gds:GlobaldataService,private us:UtilService,private messageService: MessageService,
    private router:Router,private routera: ActivatedRoute)
    {
     // this.rango_modelos=Utilidades.range(2,13);
      this.rango_modelos=Utilidades.range(1,31);

      console.log("raaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",this.rango_modelos);
		
      this.color=['#FABF8F','red','#FABF8F','#FABF8F'];

      ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];

      this.month['01']='Enero';
      this.month['02']='Febrero';
      this.month['03']='Marzo';
      this.month['04']='Abril';
      this.month['05']='Mayo';
      this.month['06']='Junio';
      this.month['07']='Julio';
      this.month['08']='Agosto';
      this.month['09']='Septiembre';
      this.month['10']='Octubre';
      this.month['11']='Noviembre';
      this.month['12']='Diciembre';
     
      
    
    
    this.data = {
              labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
              datasets: [
                  {
                      label: 'Facturas recibidas de Telefonica S.L.',
                      backgroundColor: '#42A5F5',
                      borderColor: '#1E88E5',
                      data: [65, 59, 80, 81, 56, 55, 40,46]
                  }
              ]
          }
    }

  ngOnInit(): void {
    this.routera.params.subscribe((params: Params) => 
    {
      this.idEmpleado = params['idEmpleado'];
      this.idClient = params['idClient'];
      this.ejercicio = params['ej'];
      this.mes = params['mes'];
      this.loading = true;
      console.log("loading..............",this.loading);
      this.loadSaldosDiarios();

    });

   

    this.idClientArr=this.idClient.split(",");
    this.idClientArr  =this.idClientArr.map(Number);
    this.getDayCountItem=[];

    this.monthname=this.month[this.mes]; 


    // let url2 = 'clientes?'
    // + "&fields=idCliente,Cliente"
    // + '&where=1=1 and   idCliente in(' + this.idClient + ') '
    // + '&order=idCliente asc';

    //  this.httpClient.get(this.gds.urlBaseDL + url2).toPromise().then((r) => {
    //               this.allClients = r['resp'];
    //  }); 

    
    this.gds.subCompanyObservable.subscribe((data: any) => {
      console.log("name....",data);
      this.allClients = data;
     
     });
   
     console.log("subcompanies....",this.allClients);


      let data=this.allClients;
      let mainList = [];
      let idClienteArr=[];
      let styleColor =['#B8CCE4','#C4D79B','#FFAEC9','#FABF8F'];
      let styleClass =['mod-mensual','mod-trimestral','mod-acuenta','mod-anual'];
      let styleClassArr=[];


      
      


      for (let s=0;s<data.length;s++) {
      let list = {};
      console.log(this.idClientArr,"ropppppppppppppppp",data[s].idCliente);
      

      if(this.idClientArr.indexOf(data[s].idCliente) != -1) {

      list["idCliente"] = data[s].idCliente;
      list["Cliente"] =  data[s].Cliente;
      list["styleClass"] = styleClass[s];
      mainList.push(list);
      idClienteArr.push(data[s].idCliente);
      styleClassArr[data[s].idCliente]=styleClass[s];
      }


      }

      console.log("dodloxxxxxxxxxxxxxxxxbolo",mainList);
      this.allClientsStyle=mainList;
      this.styleClassArr=styleClassArr;


    var queryparams="where=C.idCliente="+this.gds.id_cliente;
		queryparams+="&joins=clientes(idTipoCliente=>idTipo)";
		queryparams+="&fields=C.idCliente,T.*"
		this.httpClient.get<Respuesta>(this.gds.urlBaseDL+'tipocliente?'+queryparams).toPromise().then((res)=>{
			console.log(res);
			this.tiposModelo=res.resp[0];
			this.tiposModelo["anuales"]=this.tiposModelo["anuales"].split(",");
			this.tiposModelo["trimestrales"]=this.tiposModelo["trimestrales"].split(",");
			this.tiposModelo["acuenta"]=this.tiposModelo["acuenta"].split(",");
			this.tiposModelo["mensuales"]=this.tiposModelo["mensuales"].split(",").filter((m:String)=>{return m.substr(0,1)!='['});
			
			var l=this.tiposModelo["anuales"];
			l=l.concat(this.tiposModelo["trimestrales"]);
			l=l.concat(this.tiposModelo["acuenta"]);
			l=l.concat(this.tiposModelo["mensuales"]);

			this.tipos=l;

			l=l.filter((m:String)=>{return m.substr(0,1)!='['});
			//console.log(l)

			l=l.map((m:String)=>{return m.trim();});
			this.strFilterModelos=l.join("|");
			//console.log(this.strFilterModelos);		

			//console.log(l.filter((m:String)=>{return m.substr(0,1)=='['}));

			//this.strFilterMaterias=this.tipos.filter((m:String)=>{return m.substr(0,1)=='['}).map((t)=>{return "'"+t.substr(1,t.length-2)+"'";}).join(",");
			//console.log(this.strFilterMaterias);
			
			this.filtrarImpuestos();
		});

		//this.ejercicio=this.gds.ejercicios[0].value;
		this.ejercicio2=this.gds.ejercicios[0].value;
	

		/*this.gds.get_materias().then((res)=>{
			var mats=(res.filter(mat => mat[1] === 0 && mat[0]!=200));
			this.materias=mats.map((mat) => {
				return { label: mat[2], value: mat[0] };
			});
		});*/
		

		//SELECT D.*,M.materia as materia,M2.materia as submateria,tercero,C.cliente as cliente,U.nombre as user,U2.nombre as user2 FROM Documentos as D LEFT JOIN Terceros as T ON D.idTercero=t.idTercero LEFT JOIN Clientes as C ON C.idCliente=D.idCliente LEFT JOIN Materias as M ON D.idMateria=M.idMateria LEFT JOIN Materias as M2 ON D.idSubMateria=M2.idMateria LEFT JOIN Usuarios as U2 ON D.idUserCreate=U2.idUsuario LEFT JOIN Usuarios as U ON D.idUsuario=U.idUsuario  WHERE D.idCliente=205 AND(etiquetas rlike '.2018') AND ((etiquetas rlike '^100.|^180.|^190.|^200.|^347.|^ 390.|^202.|^111.|^115.|^303.')  OR M2.materia='Nóminas' OR M2.materia='Resumen Nominas')





  }
  
  goback_hora() {
    //this.router.navigate(['main/horarioemp']);
    this.router.navigate(['main/consultaglobal']);
  }


  horaToMins(hora)
  {
    return parseInt(hora.substr(0,2))*60+parseInt(hora.substr(3,2));
  }

  minsToHora(m)
  {
    return (String(Math.floor(m/60))).padStart(2,"0")+":"+(String(m%60)).padStart(2,"0");
  }


   getDayCount(idEmpleado,idCliente,ejercicio,mes,day)
  {

    let dayitem = day;
    dayitem = dayitem.toString().padStart(2, '0');

    let itemDate=dayitem+"/"+mes;
    let date=ejercicio+"-"+mes+"-"+dayitem;


    let url2='sol/solicitudes?'
    +"&fields=idsolicitud,fechainicio,fechafin,fechas";
    url2+="&where=estado in('ACEPTADA') ";
    if( idEmpleado !=0 )
    {
      url2+='and idEmpleado in ('+idEmpleado+')'; 
    }
    //url2+='and idCliente in('+idClient+") ";
    url2+="and ( year(fechainicio)="+ejercicio+" or year(fechafin)="+ejercicio+") and (month(fechainicio)="+mes+" or month(fechafin)="+mes+") ";
    url2+="and ( '"+date+"' >= fechainicio and fechafin >= '"+date+"') "; 
    url2+="and fechas LIKE '@@@"+itemDate+"@@@' " ;
    // url2+='&order=E.idCliente asc'
    // + '&groupby=T.empleado';


    return  this.httpClient.get(this.gds.urlBaseDL + url2).toPromise();
  


  }



  handleError() {
    throw new Error('Method not implemented.');
  }

  getTotal(idEmpleado,idCliente,ejercicio,mes)
  {
    return idEmpleado+"--"+idCliente+"--"+ejercicio+"--"+mes;
  }
 
  
  public loadSaldosDiarios()
	{
    // let url2='empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
    // +"&fields=E.idEmpleado,T.empleado,H.tipo,date_format(H.inicio,'%25d/%25m/%25Y') as H_fecha,time_format(time((H.inicio)),'%25H:%25i') as H_entrada,time_format(time((H.fin)),'%25H:%25i') as H_salida,time_format(sec_to_time((timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo,H.detalle,H.localizacion"
    // +'&where=E.baja in(0,1) and E.idEmpleado='+this.idEmpleado+" and year(H.inicio)="+this.ejercicio+" and month(H.inicio)="+this.mes
    // +'&order=inicio'
    // +'&tree=empleado->fichajes,horario.fecha->inout=>[horario.fecha,horario.entrada,horario.salida,horario.saldo,horario.tipo,horario.detalle,horario.localizacion]';


   


    let url2='empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
    +"&fields=E.idEmpleado,T.empleado,H.tipo,date_format(H.inicio,'%25d/%25m/%25Y') as H_fecha,time_format(time((H.inicio)),'%25H:%25i') as H_entrada,time_format(time((H.fin)),'%25H:%25i') as H_salida,time_format(sec_to_time((timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo,H.detalle,H.localizacion,E.idCliente";
    url2+='&where=E.baja in(0) and (H.tipo="" or H.tipo="visita") ';
    if( this.idEmpleado !=0)
    {
      url2+='and E.idEmpleado in ('+this.idEmpleado+')'; 
    }
    url2+='and idCliente in('+this.idClient+") ";
    url2+="and year(H.inicio)="+this.ejercicio+" and month(H.inicio)="+this.mes;
    url2+='&order=E.idCliente asc'
    + '&groupby=T.empleado';



     this.httpClient.get(this.gds.urlBaseDL+url2).toPromise().then(async (r)=>{
      console.log("alll",r['resp']);
      console.log("----",r['resp'][0]);
      

      this.saldos = r['resp'];

      console.log("inssaldos....",this.saldos);

      console.log("saldos....",this.saldos);


      let saldata=this.saldos;
      let salmainList = [];
      let salidClienteArr=[];
      let salstyleColor =['#B8CCE4','#C4D79B','#FFAEC9','#FABF8F'];
      let salstyleClass =['mod-mensual','mod-trimestral','mod-acuenta','mod-anual'];

      let subtotal=0;
      
      for (let d=0;d<saldata.length;d++) {
      let sallist = {};
     //console.log(this.idClientArr,"xxxxxxxxxxxxxxxx",saldata[d].idCliente);
  
  
  
        sallist["idEmpleado"] = saldata[d].idEmpleado;
        sallist["empleado"] = saldata[d].empleado;
        let  idEmpleado = saldata[d].idEmpleado;
      

          let tot=0;
          for(var day of this.rango_modelos) {
          let daycount="daycount"+day;
          let dayitem = day;
          dayitem = dayitem.toString().padStart(2, '0');
          let itemDate=dayitem+"/"+this.mes;
          let date=this.ejercicio+"-"+this.mes+"-"+dayitem;

          console.log("DDDDDDD-------------",this.isValidDate(date));
          let countday="";
          if(this.isValidDate(date))
          {
            // var getDayCount =this.getDayCount(saldata[d].idEmpleado,saldata[d].idCliente,this.ejercicio,this.mes,day);
           this.getDayCountStr =await this.getDayCount(saldata[d].idEmpleado,saldata[d].idCliente,this.ejercicio,this.mes,day);
           console.log("kkkkkkkkkkkkkkkkkxxxxxxxxxxxxxxxx", this.getDayCountStr);
           console.log("kkkkkkkkkkkkkkkkkxxxxxxxxxxxxxxxx", this.getDayCountStr.resp);
            countday=this.getDayCountStr.resp;

          }
          else{
             countday="-";
          }

          



          sallist[day]=countday;

          if(countday=='X')
          {
            tot=tot+1;
          }


          }

        sallist["idCliente"] =  saldata[d].idCliente;
        sallist["styleClass"] = this.styleClassArr[saldata[d].idCliente];

        sallist["total"] = tot;

        salmainList.push(sallist);
        salidClienteArr.push(saldata[d].idCliente);
    
        subtotal=subtotal+tot;
  
      }
  
      console.log("eeeeeeeeeepppppooook",salmainList);
      this.subtotal=subtotal;
      this.allsaldos=salmainList;
      this.loading = false;
      console.log("loading..............",this.loading);
      
    });



   



    
  }

   isValidDate(date) {
        var formats = [
        moment.ISO_8601,
        "YYYY-MM-DD"
        ];
   
      return moment(date, formats, true).isValid();

    }


  filtrarImpuestos()
	{
		this.tabla=[];
		for(var m of this.tiposModelo["mensuales"])
		{
			if(m.substr(0,1)=='[') m=m.substr(1,m.length-2);
			var t=[0,m];
			for(let m=2;m<14;m++) t.push('x');
			this.tabla.push(t);
		}


		for(var m of this.tiposModelo["trimestrales"])
		{
			if(m.substr(0,1)=='[') m=m.substr(1,m.length-2);
			var t=[1,m];
			for(let m=2;m<14;m++) t.push('');
			t[4]='x';t[7]='x';t[10]='x';t[13]='x';
			this.tabla.push(t);
		}


		for(var m of this.tiposModelo["acuenta"])
		{
			if(m.substr(0,1)=='[') m=m.substr(1,m.length-2);
			var t=[2,m];
			for(let m=2;m<14;m++) t.push('');
			t[5]='x';t[11]='x';t[13]='x';
			this.tabla.push(t);
		}


		for(var m of this.tiposModelo["anuales"])
		{
			if(m.substr(0,1)=='[') m=m.substr(1,m.length-2); 
			var t=[3,m];
			for(let m=2;m<14;m++) t.push('');
			t[13]='x';
			this.tabla.push(t);
		}

		
		var filtro="idCliente="+this.gds.id_cliente+" AND fechaEjercicio LIKE '"+this.ejercicio2+"%' AND ((etiquetas rlike '^MOD,+("+this.strFilterModelos+")+($|,)') ";
		//if(this.strFilterMaterias.length>0) filtro+=" OR M.materia IN ("+this.strFilterMaterias+") )"; else 
		filtro+=")";
console.log(filtro);
		// this.httpClient.post<Respuesta>(this.gds.urlBaseDL+'documentos/query',{
		// 	fields:'D.idDocumento,D.etiquetas,M.materia,substr(fechaejercicio,6,2) as fecha'
		// 	,joins:"materias(idSubMateria)"
		// 	,where:filtro
		// }).subscribe((res)=>{
		// 	console.log(res);
		// 	const i_meses={'01':2,'02':3,'03':4,'04':5,'05':6,'06':7,'07':8,'08':9,'09':10,'10':11,'11':12,'12':13,
		// 	'T1':4,'T2':7,'T3':10,'T4':13,'P1':5,'P2':11,'P3':13,'':13 };
		// 	for(let modelo of res.resp)
		// 	{
		// 		for(let i=0;i<this.tipos.length;i++)
		// 		{
		// 			//if (modelo['etiquetas'].startsWith("MOD,"+this.tabla[i][1]) || modelo['M_materia']==this.tabla[i][1])
		// 			if (modelo['etiquetas'].startsWith("MOD,"+this.tabla[i][1]))
		// 			{
		// 				//console.log(modelo['fecha'],i_meses[modelo['fecha']]);
		// 				this.tabla[i][i_meses[modelo['fecha']]]=modelo['idDocumento'];
		// 				break;
		// 			}
		// 		}
		// 	}
		// });
	}
	
	isNumber(obj:Object)
	{
		return (typeof(obj) === 'number');
		//return typeOf(obj);
	}
	

}
