<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Mis documentos</p>

	<br/> 
	
	<p-toast position='center'></p-toast>
	
	
	
	<div class="p-grid p-justify-start">
		<div class="p-col-12 p-md-12 p-lg-12">			
		<fieldset class="fieldset-custom">
		<div class="p-grid">
		<div class="p-col-12">
		<legend>Filtro de documentos</legend>
		<label>Nombre del documento contiene</label>
		<input id="float-input" type="text" size="30" pInputText class="voraz" autocomplete="off" [(ngModel)]="documento"> 
		</div>
		
		<div class="p-col-6">
		<label>Ejercicio</label>
		<p-dropdown placeholder="(todos)" styleClass="voraz" id="float-input" [autoDisplayFirst]="false" [showClear]="true" [options]="gds.ejercicios" [(ngModel)]="ejercicio"></p-dropdown>
		</div>
		
		<div class="p-col-6">
		<label>Materia</label>
		<p-dropdown placeholder="(todas)" styleClass="voraz" id="float-input" [autoDisplayFirst]="false" [showClear]="true" [options]="gds.materias" [(ngModel)]="materia"></p-dropdown>	
		</div>
		
		<div class="p-col-12">
		<div class="centrado"><button pButton type="button" label="Filtrar" class="ui-button-raised" (click)="filtrarDocumentos();"></button></div>
		</div>
		
		</div>
		
		</fieldset> 
		</div> 
	</div>
	
    <div *ngIf="ejercicio!=null">Se muestrarán los documentos correspondientes al ejercicio {{ejercicio}}. Utilice la 'X' en Ejercicio si quiere ver todos.!</div>

	<br/>
	<!-- <p-tree  selectionMode="single" (onNodeSelect)="gds.viewTreeDoc($event)" [value]="itemsArbol" styleClass="arbol-docs"  [style]="{width: '100%'}"> -->

		<p-tree  selectionMode="single" (onNodeSelect)="selectMisDocument($event)" [value]="itemsArbol" styleClass="arbol-docs"  [style]="{width: '100%'}">
		
		<ng-template let-node pTemplate="rama"> 
			{{node.label}}
		</ng-template>
		
		<ng-template let-node pTemplate="doc"  >
			<p>[{{node.data['fechaEjercicio']}}] {{node.label}}</p> <!--   -----{{node.idDocumento}}-----{{node.restricted}} style="color:red" -->
		</ng-template>
		
	</p-tree>
	
	
	
	
	

	
	

