import { Component, OnInit, Input } from '@angular/core';
import {Table} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';


@Component({
  selector: 'app-nominas',
  templateUrl: './nominas.component.html',
  styleUrls: ['./nominas.component.scss']
})
export class NominasComponent implements OnInit {
 
  ejercicio: number;
  mes:string="01";
  nominas:any=[];
  total:number=0;

  constructor(private httpClient: HttpClient,public gds:GlobaldataService) { 
  }

  /*@Input()
  public set ejercicio(ej) {this._ejercicio=ej;this.loadNominas();}
  public get ejercicio() {return this._ejercicio;}

  @Input()
  public set mes(m) {this._mes=m;this.loadNominas();}
  public get mes() {return this._mes;}*/

  ngOnInit(): void {
    this.ejercicio=this.gds.ejercicio_actual;
    this.mes=this.gds.mes_actual;
    this.loadNominas();
  }

  public click_tabla(e)
  {
    if('data-id' in e.target.attributes) this.gds.viewIDDoc(e.target.attributes['data-id'].value);
  }

  public loadNominas()
	{
   
    // let url2='empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    // +',salario(E.idEmpleado=>S.idEmpleado)'
    // +',documentos(E.idEmpleado=>D.idEmpleado)'
    // +'&fields=idTrabajador,E.idEmpleado,T.empleado,S.importe,D.idDocumento,D.descripcion'
    // +'&where=E.idCliente='+this.gds.id_cliente+' and S.ano='+this.ejercicio+' and S.mes='+this.mes
    // +' and D.idSubMateria=260 and (D.idDocumento is null OR ((etiquetas=\'NOMINA\' or etiquetas like \'NOMINA,%25\') and D.fechaEjercicio=\''+this.ejercicio+'-'+this.mes+'\'))'
    // +'&order=T.empleado'
    // +'&tree=idEmpleado(empleado,importe)->nominas=>[documentos.idDocumento,documentos.descripcion]&compacto=true';  //&compacto=true

    let url2='empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +',salario(E.idEmpleado=>S.idEmpleado)'
    +',documentos(E.idEmpleado=>D.idEmpleado)'
    +'&fields=idTrabajador,E.idEmpleado,T.empleado,S.importe,D.idDocumento,D.descripcion'
    +'&where=E.idCliente='+this.gds.id_cliente+' and S.ano='+this.ejercicio+' and S.mes='+this.mes
    +' and D.idSubMateria=260 and (D.idDocumento is null OR ((etiquetas=\'NOMINA\' or etiquetas like \'NOMINA,%25\' or etiquetas=\'RECIBO\' or etiquetas like \'RECIBO,%25\') and D.fechaEjercicio=\''+this.ejercicio+'-'+this.mes+'\'))'
    +'&order=T.empleado'
    +'&tree=idEmpleado(empleado,importe)->nominas=>[documentos.idDocumento,documentos.descripcion]&compacto=true';  //&compacto=true

    console.log(this.gds.urlBaseDL+url2);
    return this.httpClient.get(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      this.nominas=r['resp'];
      this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);
    });
	}

}
