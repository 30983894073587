<div class="grid r-grid r-grid12-6 complaint-css" style="width:'100%';">

    <div class="g-w12field">
        <input type="hidden" id="idComplaint" [disabled]="readonly" [(ngModel)]="complaint.idComplaint"
         />
         <input type="hidden" id="complaintHandledClientId" [disabled]="readonly" [(ngModel)]="complaint.complaintHandledClientId"
         />

        </div>
        
    
    <div class="g-w12field">

        <b>Fecha denuncia: </b>  {{complaint.complaintDate}}
        <!-- <label for="complaintDate">Complaint Date</label>
        <p-calendar id="complaintDate" [(ngModel)]="complaint.complaintDate" [showIcon]="true" [locale]="dateConfig"
            [touchUI]="true" [readonlyInput]="true" [disabled]="readonly" dateFormat="dd/mm/yy"></p-calendar> -->
    </div>

    <div class="g-w12field">
        <b>Privacidad: </b>  {{complaint.complaintPrivacy}}
        <!-- <label for="complaintPrivacy">Complaint Privacy</label>

        
        <p-dropdown placeholder="Seleccione"  id="complaintPrivacy"  [options]="privacy" [(ngModel)]="complaint.complaintPrivacy"  [showClear]="true"></p-dropdown> -->

            
    </div>

    <div class="g-w12field">

        <b>Tipo de denuncia: </b>  {{complaint.complaintType}} 
        <!-- <label for="complaintType">Complaint Type</label>
       <p-dropdown placeholder="Seleccione" id="complaintType" [options]="type" [(ngModel)]="complaint.complaintType" [showClear]="true"></p-dropdown> -->

        
    </div>

    <div class="g-w12field">
        <b>Persona a cargo de la denuncia: </b> {{complaint.complaintHandledClientName}}
        <!-- <label for="complaintDesc">Complaint Desc</label>
        <textarea [disabled]="readonly" id="complaintDesc" [rows]="5" [cols]="30" pInputTextarea
            [(ngModel)]="complaint.complaintDesc" autoResize="autoResize"></textarea> -->
    </div>

    <div class="g-w12field">
        <b>Descripción: </b> {{complaint.complaintDesc}}
        <!-- <label for="complaintDesc">Complaint Desc</label>
        <textarea [disabled]="readonly" id="complaintDesc" [rows]="5" [cols]="30" pInputTextarea
            [(ngModel)]="complaint.complaintDesc" autoResize="autoResize"></textarea> -->
    </div>

    <div *ngIf=" complaint.complaintAttachDocument!='' && complaint.complaintAttachDocument!=null" class="g-w12field">
        <a href="#" (click)='cbl.viewIDDocClient(complaint.complaintAttachDocument)'>Documento adjunto</a>
    </div>

    <div class="g-w12field">
        <label for="complaintStatus"><b>Estado: </b></label>
     
         
         <p-dropdown placeholder="Seleccione" id="complaintStatus"    [options]="status" [(ngModel)]="complaint.complaintStatus" [showClear]="true"></p-dropdown>
    </div>

    
    <div class="g-w12field" >
        <label for="complaintDesc"><b>Historial de mensajes: </b></label>
        <hr>


        <div class="g-w12field" *ngFor="let item of allcomplaintcommunication">
            {{item.communicationDate}} >> Enviado por: {{item.userName}} >> "{{item.complaintCommunication}}"  <hr>
        </div>



    </div>

    <div class="g-w12field" *ngIf="readonly">
        <label for="complaintDesc"><b>Enviar nuevo mensaje: </b></label>
        <textarea  id="complaintCommunication" [rows]="5" [cols]="30" pInputTextarea
        [(ngModel)]="complaint.complaintCommunication"   autoResize="autoResize"></textarea>
    </div>



    
      

    

    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w2">
                <p-button *ngIf="!readonly" label="Solicitar" (click)="solicitar()"></p-button>
                <p-button *ngIf="readonly" label="Enviar mensaje" (click)="saveComment()" style="margin-right:10px"></p-button>
                <p-button *ngIf="readonly" label="Guardar" (click)="saveComment()" style="margin-right:10px"></p-button>
                <p-button *ngIf="readonly" label="Cerrar" (click)="cerrar()"></p-button>
            </div>
        </div>
    </div> 



</div>

<!-- <p-toast position='center'></p-toast> -->