
<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Publicaciones informativas</p>

<div class="p-grid p-align-left   p-justify-start">
    
	<div class="p-col-12 p-md-12 p-lg-6" *ngFor="let doc of docs">
		<p-card [style]="{width: '95%','min-height':'300px'}" styleClass="ui-card-custom" header="{{doc['label']}}">

		<div>
		
			<p-scrollPanel [style]="{width: '100%', height: '220px'}" styleClass="custom-scroll-bar">
			
			<ul (click)="gds.viewDoc($event);" style="white-space: nowrap;" class="customlista">
				<li *ngFor="let item of doc.children" [attr.data-id]='item.idDocumento'>
						<i class="fa fa-file icono"></i>
						{{item.descripcion}}
				</li>
			
			</ul>
			</p-scrollPanel>
		
		</div>
		
		</p-card>
	</div>

</div>
