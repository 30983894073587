import { Component, OnInit } from '@angular/core';
import {Input,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import {MenuItem} from 'primeng/api';     
import {ChartModule} from 'primeng/chart';
import {TableModule} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { FechaTipadaPipe } from '../../libs/fechaTipada.pipe';
import { Utilidades } from '../../libs/utilidades';
import { UtilService } from '../../servicios/util.service';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {FileUpload} from 'primeng/fileupload';
import { Router,ActivatedRoute  } from "@angular/router";
import { ConfirmdocumentComponent } from 'src/app/documentos/confirmdocument/confirmdocument.component';
import {DialogService} from 'primeng/dynamicdialog';


@Component({
  selector: 'app-misdocumentos',
  templateUrl: './misdocumentos.component.html',
  styleUrls: ['./misdocumentos.component.scss']
})
export class MisdocumentosComponent implements OnInit {

  itemsArbol:TreeNode[];	
	//items10: MenuItem[];
	//menuId: number=0;
	data: any;
	//cars:any;
	//ejercicios:any;
	ejercicio: number=0;
	ejercicio2: number=0;
	documento:string="";
	//rango_modelos:any;
	color:any;
	
	//materias:any;
	materia:number=0;
	tiposModelo:any;
	strFilterModelos:string="";
	strFilterMaterias:string="";
	tabla:Array<Array<Object>>=[];
	
	
	//@ViewChild('envioDoc') envioDoc:FileUpload;  //ElementRef
	
	constructor(private httpClient: HttpClient,public gds:GlobaldataService,private us:UtilService,private messageService: MessageService,private router:Router, private routera: ActivatedRoute,public dialogService:DialogService)
	{
		
		//this.rango_modelos=Utilidades.range(2,14);
		
		//this.color=['#FABF8F','red','#FABF8F','#FABF8F'];
		
	this.itemsArbol=[];

	/*const payload = new HttpParams()
		.set('ejercicio', '2017')
		.set('materia', '2')
		//.set('texto', 'COSO')
		.set('facturas', 'false');
		
	this.httpClient.post<TreeNode[]>('http://localhost:8080/serviceODE/documentos/tree',payload).subscribe((res)=>{
        console.log(res);
		this.itemsArbol=res;
    });*/
	
}

	async ngOnInit()
	{ 
		this.materia=null;
		
		//const payload = new HttpParams()
		//.set('cliente', String(this.gds.id_cliente));
		
		//var p_tipos:Promise<Object[]>=this.httpClient.get<Object[]>(this.gds.urlBaseDL+'documentos/tiposModelos',{params:payload}).toPromise();
		
		this.ejercicio=this.gds.ejercicios[0].value;
		this.ejercicio2=this.gds.ejercicios[0].value;
		this.applyFilter();

		/*this.gds.get_materias().then((res)=>{
			var mats=(res.filter(mat => mat[1] === 0 && mat[0]!=200));
			this.materias=mats.map((mat) => {
				return { label: mat[2], value: mat[0] };
			});
		});*/
		
		/*
		this.gds.get_ejercicios().then((res)=>{
			this.ejercicios=res.map((ej) => {
				return { label: ej[0], value: ej[1] };
			});

			this.ejercicio=res[0][1];
			this.ejercicio2=res[0][1];
			this.applyFilter();
			
			p_tipos.then((res)=>{
				console.log(res);
				this.tiposModelo=res;
				for(var modelo of res)
				{
					if(modelo[0]!=0) this.strFilterModelos+='|^'+modelo[1]+'.';
					else
					{
						var mat:string=modelo[1];
						mat=mat.substr(1,mat.length-2);
						this.strFilterMaterias+=",'"+mat+"'";
					}
				}
				this.strFilterModelos=this.strFilterModelos.substr(1);
				this.strFilterMaterias=this.strFilterMaterias.substr(1);
				this.strFilterMaterias="'Nóminas','Resumen Nominas'";
				console.log(this.strFilterModelos);
				console.log(this.strFilterMaterias);
				
				
				this.filtrarImpuestos();
			});
		});	
		*/




		//SELECT D.*,M.materia as materia,M2.materia as submateria,tercero,C.cliente as cliente,U.nombre as user,U2.nombre as user2 FROM Documentos as D LEFT JOIN Terceros as T ON D.idTercero=t.idTercero LEFT JOIN Clientes as C ON C.idCliente=D.idCliente LEFT JOIN Materias as M ON D.idMateria=M.idMateria LEFT JOIN Materias as M2 ON D.idSubMateria=M2.idMateria LEFT JOIN Usuarios as U2 ON D.idUserCreate=U2.idUsuario LEFT JOIN Usuarios as U ON D.idUsuario=U.idUsuario  WHERE D.idCliente=205 AND(etiquetas rlike '.2018') AND ((etiquetas rlike '^100.|^180.|^190.|^200.|^347.|^ 390.|^202.|^111.|^115.|^303.')  OR M2.materia='Nóminas' OR M2.materia='Resumen Nominas')
		
	}
	
	applyFilter()
	{
		//var payload = new HttpParams()
		//.set('facturas', 'false');
		
		//if(this.materia!=null) payload =payload.set('materia', String(this.materia))
		//if(this.ejercicio!=null) payload =payload.set('ejercicio', String(this.ejercicio))
		

		var mat=this.materia;
		var ej=this.ejercicio;
		if(mat==null) mat=0;
		if(ej==null) ej=0;

		console.log(mat);
		
		let filtro:string;
		if (mat!=0)
			filtro="D.idMateria="+mat;
		else
			filtro="D.idSubMateria<>0 and D.idSubMateria!=16 and D.idSubMAteria!=18";

		if(this.documento.length>0)
			filtro+=" AND D.descripcion like '%"+this.documento+"%'";

			var params={
			fields:"idDocumento,descripcion,idMateria,idSubMateria,fechaEntrada,tipoFecha,fechaEjercicio,restricted,permanente",
			joins:"materias(idMateria),materias(idSubMateria)",
			order:"M.materia,M2.materia,fechaEjercicio DESC,fechaIncorporacion",
			//where:"idCliente="+this.gds.id_cliente+" AND D.idSubMateria<>0 and D.idSubMateria!=16 and D.idSubMAteria!=18 AND seguridad=1 "+((ej!=0)?" AND (M.permanente=10 OR ejercicio="+ej+")":""),
			// where:"idCliente="+this.gds.id_cliente+" AND "+filtro+" AND seguridad=1 "+((ej!=0)?" AND (fechaEjercicio LIKE '"+ej+"%')":""),
			where:"idCliente="+this.gds.id_cliente+" AND M.permanente=0 AND "+filtro+" AND seguridad=1 "+((ej!=0)?" AND (fechaEjercicio LIKE '"+ej+"%')":""),
			tree:"materias.materia(idMateria,materias.materia),materias2.materia(idSubMateria)=>idDocumento,descripcion,fechaEntrada,tipoFecha,fechaEjercicio,restricted,permanente",
		};
		console.log(params);
		//var vlo=btoa(JSON.stringify(params));
		//this.httpClient.get<any>(this.gds.urlBaseDL+"documentos/?vlo="+Utilidades.packVLO(params)).subscribe((res)=>{
		this.httpClient.post<any>(this.gds.urlBaseDL+"documentos/query",{'vlo':Utilidades.packVLO(params)}).subscribe((res)=>{

			//console.log("RECIBIDO:",res);
			res=res.resp;
			
			for (var rama of res)
			{
				rama['collapsedIcon']="fa fa-folder";
				rama['expandedIcon']="fa fa-folder-open";
				rama['type']="rama";
				for (var subrama of rama['children'])
				{
					subrama['collapsedIcon']="fa fa-folder";
					subrama['expandedIcon']="fa fa-folder-open";
					subrama['type']="rama";
					for (var item of subrama['children'])
					{
						item['type']="doc";
						
						if(item['restricted']=='1')
						{
							item['icon']="fa fa-circle red-circle";
						}
						else{
							item['icon']="fa fa-file";
						}
						
						
                        item['label']=item['descripcion'];
						item['key']=item['idDocumento'];
						item['restricted']=item['restricted'];
						//item['data']={'fechaEntrada':item['fechaEntrada'].substr(0,10),'tipoFecha':item['tipoFecha'],'fechaEjercicio':item['fechaEjercicio']}
						item['data']={'fechaEjercicio':item['fechaEjercicio']};
					}
				}
			}
			console.log("PROCESADO:",res);
			this.itemsArbol=res;
		});
	}




	selectMisDocument(event){

		//alert("sss");

		console.log(event);

		console.log(event.node.restricted);
		if(event.node.restricted=='1')
		{

			// this.httpClient.get(this.gds.urlBaseDL + 'updaeCodeDocumentos/getDocumentosId/' + event.node.key+'/'+this.gds.id_cliente).toPromise();

			// this.httpClient.get(this.gds.urlBaseDL + 'sendWhatzappForDownloadDocumentos/getDocumentosId/' + event.node.key+'/'+this.gds.id_cliente).toPromise();


			// this.httpClient.get(this.gds.urlBaseDL+'tareas/'+idTarea,{
			// 			//cliente:this.gds.id_cliente
			// 		}).subscribe((res)=>{
			// 		console.log(res);
			// 		});

			this.httpClient.get(this.gds.urlBaseDL + 'updaeCodeDocumentos/getDocumentosId/' + event.node.key+'/'+this.gds.id_cliente).toPromise().then(async (r) => {
				//console.log(r.resp);
		  
				this.httpClient.get(this.gds.urlBaseDL + 'sendWhatzappForDownloadDocumentos/getDocumentosId/' + event.node.key+'/'+this.gds.id_cliente).toPromise();
				
			  });

 
			    //alert("sss");
					const ref = this.dialogService.open(ConfirmdocumentComponent, {
					  data:{id:event.node.key,confirmevent:event,confirmtype:'document'},
					  header: 'Documento protegido',
					  width: '70%' 
				  });

				  

			
		}
		else
		{
			this.gds.viewTreeDoc(event);
		}


	
	  }
	
		
	/*itemClick(event: Event) //, item: MenuItem, index: number
	{
		console.log(event);
	}*/
	
	filtrarDocumentos()
	{
		this.applyFilter();
	}


	
	
	/*filtrarImpuestos()
	{
		console.log("D.idSubMateria<>16 and D.idSubMateria<>18 and D.tipo<>1  AND ((etiquetas rlike '"
			+this.strFilterModelos+"') OR M2.materia IN ("+this.strFilterMaterias+") )");
		this.httpClient.post<Object[]>(this.gds.urlBaseDL+'documentos/query',{
			fields:'D.idDocumento,D.etiquetas,M2.materia as materia,month(fechaEntrada) as fecha'
			,cliente:this.gds.id_cliente
			,ejercicio:this.ejercicio2
			,excludeFacturas:'true'
			//,where:"D.idSubMateria<>16 and D.idSubMateria<>18 and D.tipo<>1  AND ((etiquetas rlike //'^100.|^180.|^190.|^200.|^347.|^ 390.|^202.|^111.|^115.|^303.')  OR M2.materia='Nóminas' OR //M2.materia='Resumen Nominas')"
			,where:" ((etiquetas rlike '"+this.strFilterModelos+"') OR M2.materia IN ("+this.strFilterMaterias+") )"
		}).subscribe((res)=>{
			console.log(res);
			this.tabla=[];
			for(let i=0;i<this.tiposModelo.length;i++)
			{
				this.tabla[i]=new Array<Object>(14);
				this.tabla[i][0]=this.tiposModelo[i][0];
				
				if(this.tiposModelo[i][1].startsWith("["))
					this.tabla[i][1]=this.tiposModelo[i][1].replace("[","").replace("]","");
				else
					this.tabla[i][1]=this.tiposModelo[i][1];
				if(this.tiposModelo[i][0]!=0) for(let m=2;m<15;m++) this.tabla[i][m]='';
				if(this.tiposModelo[i][0]==0) for(let m=2;m<15;m++) this.tabla[i][m]='x';
				if(this.tiposModelo[i][0]==1) {this.tabla[i][4]='x';this.tabla[i][7]='x';this.tabla[i][10]='x';this.tabla[i][13]='x';}
				if(this.tiposModelo[i][0]==2) {this.tabla[i][5]='x';this.tabla[i][11]='x';this.tabla[i][13]='x';}
				if(this.tiposModelo[i][0]==3) {this.tabla[i][14]='x';}
			}
			console.log(this.tabla);
			for(let modelo of res)
			{
				for(let i=0;i<this.tiposModelo.length;i++)
				{
					if (modelo[1].startsWith(this.tabla[i][1]))
					{
						if (this.tabla[i][0]!='3') this.tabla[i][1+modelo[3]]=modelo[0]; else this.tabla[i][14]=modelo[0];
						break;
					}
				}
			}
		});
	}
	*/
	isNumber(obj:Object)
	{
		return (typeof(obj) === 'number');
		//return typeOf(obj);
	}
	
	/*cmd_ver_modelo(ev)
	{
		//console.log(ev);
		this.gds.viewDoc(ev,true);
		return false;
	}*/
	
	/*myUploader(event) {
		const formData = new FormData();
		for (let key in event.files)
		{
			console.log("Ficheros:"+key+" ===> " +event.files[key]+" " +event.files[key].name);
			formData.append(event.files[key].name, event.files[key], event.files[key].name);
		}
			
		var idTarea:Number=0;
		this.httpClient.put(this.gds.urlBaseDL+'tareas/extranet',{
			cliente:this.gds.id_cliente
		}).subscribe((res)=>{
			idTarea=res["tarea"];
			console.log(idTarea);
			
			formData.append('tarea',String(idTarea));
			this.us.upload(formData,this.gds.urlBaseDL+'documentos/upload').subscribe((res)=>{
				if(res['type']==HttpEventType.Response)
				{
					console.log(res);
					this.envioDoc.clear();
					this.messageService.add({severity: 'info', summary: 'Envio de ficheros a la gestoría', detail: 'Ficheros recibidos con éxito!'});
					
					
					//this.httpClient.get(this.gds.urlBaseDL+'tareas/'+idTarea,{
					//	//cliente:this.gds.id_cliente
					//}).subscribe((res)=>{
					//console.log(res);
					//});
				}
			});
			
			
			
					
		});
		
	}*/


}
