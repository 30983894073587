import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import {CBLService} from 'src/app/servicios/cbl.service'
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Respuesta } from 'src/app/libs/respuesta';
import { formatDate } from '@angular/common';
import { Clientproject,Clientprojectemployee, Complaint, Documento } from 'src/app/libs/dataObjects'
import { MessageService, SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { InputNumberModule } from 'primeng/inputnumber'; 

@Component({
  selector: 'app-clientproject',
  templateUrl: './clientproject.component.html',
  styleUrls: ['./clientproject.component.scss'],
})
export class ClientprojectComponent implements OnInit {

  dateConfig: any; 
  clientproject: Clientproject = new Clientproject();
  clientprojectemployee: Clientprojectemployee = new Clientprojectemployee();
  uploadedFiles: any[] = [];
  readonly: boolean = false;
  remarkBody = {
    comment: null,
    file1: null,
    file2: null,
    file3: null
  };
  _pdf: SafeUrl;
  privacy:any[]=[];
  type:any[]=[];
  status:any[]=[];


  languages: SelectItem[] = [];
 selectedLanguage: any;
 applicant: any = {};

   dataLoaded = false;

   selectedCities1: string[] = [];

   selectedCities2: string[] = [];

   selectedCountries1: string[] = [];
 
   selectedCountries2: string[] = [];
 
   items: SelectItem[];
 
   item: string;
 
   cities: any[];
 
   countries: any[];


   

   public allclientcollaboratorcommunication: any = []; 

  @ViewChild('envioDoc') envioDoc: FileUpload;
  @ViewChild('fileUpload') fileUpload: any;
  @ViewChild('fileUpload2') fileUpload2: any;
  @ViewChild('fileUpload3') fileUpload3: any;


  @ViewChild('attachDocument') attachDocument: FileUpload;
  history: Respuesta;
  mes: string;
  selectedTeam: string;
  complaintStatus: string;
  subcompanies: any;
  categoryList: any[];
  clientesAll: any[];
  access:any[]=[];
  selectedAccess:any[]=[];

  empList: any[];
  pagetype: any;
  public allclientprojectemployee: any = []; 

  constructor(public cbl: CBLService, private messageService: MessageService,
    private httpClient: HttpClient, public dialogService: DialogService, public ref: DynamicDialogRef,public gds:GlobaldataService,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer) { 

    let id = this.config.data.id;
    console.log(id);

    // let pagetype = this.config.data.pagetype;
    // console.log(pagetype); 


    // if(pagetype=='edit')
    // {
    //   this.pagetype = pagetype;
    // }
    // else if(pagetype=='detail')
    // {
    //    this.pagetype = pagetype;
    // }

    this.pagetype = "edit";

    if (id) {
      //id=18;

      //alert(id);
      this.loadClientproject(id); 
      //this.getIncendias(id);
      this.readonly = true;
    }
    else {
      this.clientproject = new Clientproject();
     // this.complaint.fechainicio = new Date();

     this.clientprojectemployee = new Clientprojectemployee();
      this.readonly = false;
      
    }

    this.dateConfig = this.cbl.dateConfig;


    this.countries = [
      { name: "Australia", code: "AU" },
      { name: "Brazil", code: "BR" },
      { name: "China", code: "CN" },
      { name: "Egypt", code: "EG" },
      { name: "France", code: "FR" },
      { name: "Germany", code: "DE" },
      { name: "India", code: "IN" },
      { name: "Japan", code: "JP" },
      { name: "Spain", code: "ES" },
      { name: "United States", code: "US" }
    ];

    this.cities = [
      { name: "New York", code: "NY" },
      { name: "Rome", code: "RM" },
      { name: "London", code: "LDN" },
      { name: "Istanbul", code: "IST" },
      { name: "Paris", code: "PRS" }
    ];

  }

  ngOnInit(): void {

    this.mes = "Confidential";
    //this.selectedCity="Rome";
    
    console.log("rrrrrrrrrrrrrrr",this.mes);


    this.countries = [
      { name: "Australia", code: "AU" },
      { name: "Brazil", code: "BR" },
      { name: "China", code: "CN" },
      { name: "Egypt", code: "EG" },
      { name: "France", code: "FR" },
      { name: "Germany", code: "DE" },
      { name: "India", code: "IN" },
      { name: "Japan", code: "JP" },
      { name: "Spain", code: "ES" },
      { name: "United States", code: "US" }
    ];

    this.cities = [
      { name: "New York", code: "NY" },
      { name: "Rome", code: "RM" },
      { name: "London", code: "LDN" },
      { name: "Istanbul", code: "IST" },
      { name: "Paris", code: "PRS" }
    ];



   this.showClientdepartmentCommunication();

 
  //setTimeout(() => {
  let result = [
  {id: 1, name: 'Pakistan'},
  {id: 2, name: 'India'},
  {id: 3, name: 'Sri Lanka'},
  ];
  
  result.forEach(function(country) {
  this.countries.push({label: country.name, value: country.name});
  }, this);
  
  this.dataLoaded = true;
  
  // }, 1000);
  
  
  
  //this.applicant = {country: 'India'};
  


  // this.privacy = [
  //   { label: 'Anonymous', value: 'Anonymous' },
  //   { label: 'Confidential', value: 'Confidential' },
   
  // ];

  this.privacy = [
    { label: 'ANONIMO', value: 'ANONIMO' },
    { label: 'CONFIDENCIAL', value: 'CONFIDENCIAL' },
   
  ];
  this.loadcompanylist();
 

  // this.type = [
  //   { label: 'Harassment', value: 'Harassment' },
  //   { label: 'Misbehavior', value: 'Misbehavior' },
  //   { label: 'Misappropriation of resources', value: 'Misappropriation of resources' },
  //   { label: 'Safety and occupational risks', value: 'Safety and occupational risks' },
  //   { label: 'Others', value: 'Others' },
   
  // ];


  this.type = [
    { label: 'Acoso', value: 'Acoso' },
    { label: 'Mala conducta', value: 'Mala conducta' },
    { label: 'Apropiación indebida de recursos', value: 'Apropiación indebida de recursos' },
    { label: 'Seguridad y riesgos laborales', value: 'Seguridad y riesgos laborales' },
    { label: 'Otros', value: 'Otros' },
   
  ];


  // this.status = [
  //   { label: 'SENT', value: 'SENT' },
  //   { label: 'READ', value: 'READ' },
  //   { label: 'ANALYSIS', value: 'ANALYSIS' },
  //   { label: 'INVESTIGATION', value: 'INVESTIGATION' },
  //   { label: 'COMMUNICATION TO THE COMPLAINT', value: 'COMMUNICATION TO THE COMPLAINT' },
  //   { label: 'RESOLVED', value: 'RESOLVED' },
  // ];

  this.status = [
    { label: 'ENVIADO', value: 'ENVIADO' }, 
    { label: 'LEIDO', value: 'LEIDO' },
    { label: 'ANALISIS', value: 'ANALISIS' },
    { label: 'INVESTIGACIÓN', value: 'INVESTIGACIÓN' },
    { label: 'COMUNICACIÓN AL DENUNCIANTE', value: 'COMUNICACIÓN AL DENUNCIANTE' },
    { label: 'RESUELTA', value: 'RESUELTA' },
  ];



  this.access = [
    { label: 'access_documents_recent', value: '1' }, 
    { label: 'access_documents_senddocument', value: '1' },
    { label: 'access_documents_mydocument', value: '1' },
    { label: 'access_documents_taxes', value: '1' },
    { label: 'access_consultation', value: '1' },
    { label: 'access_issues', value: '1' },
    { label: 'access_information', value: '1' },
    { label: 'access_labor_incidences', value: '1' },
    { label: 'access_labor_names', value: '1' },
    { label: 'access_labor_payrollsummery', value: '1' },
    { label: 'access_labor_docemployees', value: '1' },
    { label: 'access_labor_paidgrandchildres', value: '1' },
    { label: 'access_labor_employeeportal', value: '1' },
    { label: 'access_labor_request', value: '1' },
    { label: 'access_labor_entryexit_request', value: '1' },
    { label: 'access_labor_time', value: '1' },
    { label: 'access_labor_checkpermissions', value: '1' },
    { label: 'access_labor_globalquery', value: '1' },
    { label: 'access_labor_complaintsportal', value: '1' },
    { label: 'access_mydata', value: '1' },
    { label: 'access_collaborator', value: '1' },
    { label: 'access_mylatest_documents', value: '1' },
    { label: 'access_mylatest_affairs', value: '1' }
    
  ]; 

  //this.clientcollaborator.clientcollaboratorStatus='ENVIADO';



this.applicant = {country: 'India'}; 
//this.clientcollaborator.clientId =5001;

//alert(this.gds.id_cliente); 
this.clientproject.clientId = this.gds.id_cliente;
this.showProjectEmployeeDetail();

  }
  

  accessSelection(acc,event) {
    console.log("event...",event.checked)

    if (this.selectedAccess.includes(acc.label)) {
      this.selectedAccess = this.selectedAccess.filter(data => data != acc.label)
    } else {
      this.selectedAccess.push(acc.label)
    }
    console.log("this.acc", acc)
    console.log("this.selectedAccess", this.selectedAccess)
  }
  accessSelectionSelectAll(access,event) {
    console.log("event...all",event.checked)

    console.log("empleados", access)
    if(event.checked==true){

      this.selectedAccess = access.map(data => data.label)
    }else{
      this.selectedAccess=[];
    }
  }










   public async loadcompanylist()
  {

    this.gds.subCompanyObservable.subscribe((data: any) => {
      console.log("name....",data);
      this.subcompanies = data;
     
     });
   
     console.log("subcompanies....",this.subcompanies);



      //let data=this.subcompanies;
      let data=await this.getAllEmployee();
      console.log("xxxxxxxxxxxxxxxx--------",data);
      let mainList = [];
      let idClienteArr=[];
      for (let s=0;s<data.length;s++) {
      let list = {};
      //console.log("xxxxxxxxxxxxxxxx",data[s]);
      
      // list["idCliente"] = data[s].idCliente; 
      // list["Cliente"] =  data[s].Cliente;
      // list["isSelected"] = true;
      list["label"] = data[s].empleado; 
      list["value"] =  data[s].idEmpleado;
      //list["isSelected"] = true;
      mainList.push(list);
      idClienteArr.push(data[s].idCliente);
      }
      console.log("subcompanies refine....",mainList);
      console.log("idClienteArr refine....",idClienteArr);
      this.empList=mainList;
      this.clientesAll=idClienteArr;

  }








  async getAllEmployee(){

    //alert(this.gds.id_cliente);
    let url2='empleado?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=E.idEmpleado as idEmpleado,E.baja,T.empleado as empleado'
    +'&where=E.idCliente='+this.gds.id_cliente+' and E.baja=0 '
    //+'&where=E.idCliente='+this.gds.id_cliente+''
    //+'&order=E.idEmpleado DESC';  //&compacto=true
    + '&groupby=E.idEmpleado'
    + '&order=E.idEmpleado DESC'; 

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp;
      //this.nominasTemp = r['resp'];
     // console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      console.log("xxxxxxgogoxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }











  async getEmployeeName(id){
    let url2='empleado?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=E.idEmpleado as idEmpleado,T.empleado as empleado'
    +'&where=E.idEmpleado='+id+' '
    +'&order=E.idEmpleado DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['empleado'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }

  async getClientName(id){
    let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idCliente,Cliente'
    +'&where=idCliente='+id+' '
    +'&order=idCliente DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['Cliente'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }

  
async getPersenInChargeName(id){
  let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  +'&fields=idCliente,Cliente,encargadoDenuncias'
  +'&where=idCliente='+id+' '
  +'&order=idCliente DESC';  //&compacto=true

  //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

 // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

  //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
  var respuesta;
  await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
    let resp=r['resp'];
    respuesta=resp[0]['encargadoDenuncias'];
    //this.nominasTemp = r['resp'];
    console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
    /*this.total=0.0;
    this.nominas=this.nominas.map((nom)=>{
       if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
       return nom;
    });
    console.log(this.nominas,this.total);*/
  }); 

 return respuesta;
}









  showClientdepartmentCommunication()
  {
    
		// let url="fields=E.idempleado as value,T.empleado,S.idsolicitud";
		// url=url+"&joins=trabajador,solicitudes(E.idempleado=>S.idempleado)";
		// // url=url+"&where=E.idcliente="+this.gds.id_cliente+" and year(S.fechainicio)='"+this.ejercicio+"'";
    
		// url=url+"&groupby=E.idempleado,T.empleado"
		// url=url+"&order=T.empleado";

    let url2='complaintcommunication?'
    +'&fields=idComplaintCommunication,idComplaint,complaintCommunication,communicationType,communicationByEmployeetId,communicationByClientId,communicationDate';
    url2+='&where=idComplaint="'+this.config.data.id+'"  ';
   
    url2+='&order=idComplaintCommunication ASC';
    console.log("url.....fff............",url2);
		// this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).subscribe((res)=>{
    //   console.log("data.......",res.resp);
		// 	this.allcomplaintcommunication=res.resp;      
      
		// });


    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL+url2).toPromise().then(async (r)=>{
      //console.log(r);
      console.log("vvvvvvvvvv",r.resp);
      for(let p of r.resp)
      {
        //let dias=0;
        // for(let d of p.permisos)
        // {
          // let cd=p.fechas.split(",").length;
          // p['dias']=cd; 
          // if (cd>1)
          //   p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(p.fechafin,'dd/MM/yyyy','en-US');
          // else
          //   p['periodo']=p.fechainicio;

          // if(p.horainicio!=null && p.horafin!=null)
          // {
          //   p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
          // }
          // if(p.estado!="RECHAZADA") dias+=cd;

         // p['periodo']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(p.complaintDate,'dd/MM/yyyy','en-US');

         // p['periodo']=p.complaintDate;
        // }
        // let respEmp=this.getEmployeeName(p.idEmpleado);
        // console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
        //  p['dias']=dias;
        //  p['employeeName']=await this.getEmployeeName(p.idEmpleado);

       // let respEmp=this.getEmployeeName(p.idEmpleado);
        //console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
        // p['dias']=dias;
         if(p.communicationType=='Employee')
         {
          p['userName']=await this.getEmployeeName(p.communicationByEmployeetId);
         }
         else{ 
          p['userName']=await this.getClientName(p.communicationByClientId);
         }
         p['communicationDate']=formatDate(p.communicationDate,'dd/MM/yyyy HH:mm','en-US');
        //  p['complaintNodays']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(new Date(),'dd/MM/yyyy','en-US');

         
        
         
      }
      console.log(r.resp);
     // this.complaints=r.resp;
      this.allclientcollaboratorcommunication=r.resp;
      
    });
  }





  async checkUniqueUserName(username) {
   

    var respuesta;

    await this.httpClient.get<Respuesta>(this.cbl.urlBaseDL + 'client_department/checkUniqueUserName/' + username).toPromise().then((r)=>{

      console.log("xxxxxxxxxxxxxxxxxxxx.....",r[0])
      let resp=r[0].department_email;
      console.log("dddddddddddddddddd.....",r[0].department_email)
      if(resp==0)
      {
        respuesta=true;
      }
      else{
        respuesta=false;
      }
      
      //this.nominasTemp = r['resp'];
     // console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
      
    }); 
    return respuesta;




  }












  async solicitar() {
//alert(this.clientdepartment.clientId);
    if (this.clientproject.clientId == undefined || this.clientproject.clientId == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Client need to enter!' });
      return;
    }


    if (this.clientproject.project_name == undefined || this.clientproject.project_name == "") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Project Name need to enter!' });
      return;
    }


    if (this.clientproject.employees == undefined || this.clientproject.employees == "") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Employee need to enter!' });
      return;
    }

    if (this.clientproject.startDate == undefined || this.clientproject.startDate == "") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Start Date need to enter!' });
      return;
    }


    if (this.clientproject.endDate == undefined || this.clientproject.endDate == "") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End Date need to enter!' });
      return;
    }

    if (this.clientproject.total_no == undefined || this.clientproject.total_no == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Total no. need to enter!' });
      return;
    }

    //console.log("xxxxxxmmmmm",this.checkUniqueUserName(this.clientproject.project_name));

// if (await this.checkUniqueUserName(this.clientdepartment.department_email) === false) {
//   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Department Email should be Unique!' });
//   return;
// }




   
   

   


       


    //this.clientcollaborator.clientId = this.cbl.id_cliente;
    this.clientproject.clientId = this.clientproject.clientId;
    this.clientproject.project_name = this.clientproject.project_name;
    //this.clientproject.project_name = this.clientproject.project_name;
    this.clientproject.employees = this.clientproject.employees;
    this.clientproject.startDate = this.clientproject.startDate;
    this.clientproject.endDate = this.clientproject.endDate;
    this.clientproject.total_no = this.clientproject.total_no;
    

    //alert(this.clientproject.employees);
    //alert(this.clientproject.startDate);
    //alert(this.clientproject.endDate);

    
    this.cbl.insertClientproject(this.clientproject).then((p) => {
          if (p) {

          console.log("ooooooooooooookkkkkkkkkkkk",p);  
          var idClient_project=p.idClient_project; 

          console.log("idClient_project",idClient_project);

          // this.cbl.emailToClienteDepartment(idClient_project, "Nuevo Department", `New department Added`);

          this.clientprojectemployee.clientId = this.clientproject.clientId;
          this.clientprojectemployee.idClient_project = idClient_project;
          this.clientprojectemployee.idEmpleado = this.clientproject.employees;


          this.cbl.insertClientprojectEmployee(this.clientprojectemployee).then((k) => {
            if (k) {
  
            console.log("xxxxxxxccccccccccc",k);  
            var idClient_project_employee =k.idClient_project_employee ; 
  
            console.log("aaaaaaaaaaaaaaaas",idClient_project_employee );
  
            
            this.ref.close(true); 
  
            }
            });




          //this.ref.close(true); 

          }
          });
    // {
    //   this.cbl.emailToClienteColaborator(this.cbl.id_cliente, "Ha recibido una nueva solicitud en el Portal del Cliente", `Estimado ${this.cbl.name_cliente}:\n\nUn empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de incidencia << ${this.complaint.solicitud} >>.\n\nSaludos`);
    //    this.ref.close(true);
    // }


  }

 



  async checkUniqueUserNameEdit(idClient_department,username) {
   

    var respuesta;

    await this.httpClient.get<Respuesta>(this.cbl.urlBaseDL + 'client_department/checkUniqueUserNameEdit/' + username+'/'+idClient_department).toPromise().then((r)=>{

      console.log("xxxxxxxxxxxxxxxxxxxx.....",r[0])
      let resp=r[0].department_email;
      console.log("dddddddddddddddddd.....",r[0].department_email)
      if(resp==0)
      {
        respuesta=true;
      }
      else{
        respuesta=false;
      }
      
      //this.nominasTemp = r['resp'];
     // console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
      
    }); 
    return respuesta;




  }













  async saveClientproject() {  

//alert(this.clientcollaborator.clientId);
//console.log("aaaaaaaaaaaaaa--------------aaaaaaaaaaaaaa",this.selectedAccess);
if (this.clientproject.clientId == undefined || this.clientproject.clientId == 0) {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Client need to enter!' });
  return;
}


if (this.clientproject.project_name == undefined || this.clientproject.project_name == "") {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Project Name need to enter!' });
  return;
}


if (this.clientproject.employees == undefined || this.clientproject.employees == "") {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Employee need to enter!' });
  return;
}

if (this.clientproject.startDate == undefined || this.clientproject.startDate == "") {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Start Date need to enter!' });
  return;
}


if (this.clientproject.endDate == undefined || this.clientproject.endDate == "") {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End Date need to enter!' });
  return;
}

if (this.clientproject.total_no == undefined || this.clientproject.total_no == "") {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Total no. need to enter!' });
  return;
}


// this.clientproject.clientId = this.clientproject.clientId;
// this.clientproject.project_name = this.clientproject.project_name;
// //this.clientproject.project_name = this.clientproject.project_name;
// this.clientproject.employees = this.clientproject.employees;
// this.clientproject.startDate = this.clientproject.startDate;
// this.clientproject.endDate = this.clientproject.endDate;

//r.resp[0].startDate = formatDate(r.resp[0].startDate, 'dd/MM/yyyy', 'en-US');


//alert(this.clientproject.startDate);//22/06/2024
let employees = ","+this.clientproject.employees.toString()+",";

//let startDate= formatDate(new Date(this.clientproject.startDate),'yyyy-MM-dd', 'en-US');
//let endDate = formatDate(new Date(this.clientproject.endDate),'yyyy-MM-dd', 'en-US');


if (String(this.clientproject.startDate).indexOf("/") != -1)
{
  //alert("1");
    var dateArrStart=this.clientproject.startDate.split("/");
    var startDate=dateArrStart[2]+"-"+dateArrStart[1]+"-"+dateArrStart[0];
}
else{
  //alert("2");
   var startDate= formatDate(new Date(this.clientproject.startDate),'yyyy-MM-dd', 'en-US');
}


if (String(this.clientproject.endDate).indexOf("/") != -1)
{
  var dateArrEnd=this.clientproject.endDate.split("/");
  var endDate=dateArrEnd[2]+"-"+dateArrEnd[1]+"-"+dateArrEnd[0];
}
else{
  var endDate = formatDate(new Date(this.clientproject.endDate),'yyyy-MM-dd', 'en-US');

}



//alert(startDate); 



  
        this.httpClient.post(this.cbl.urlBaseBL + 'client_project/' + this.clientproject.idClient_project, {idClient_project:this.clientproject.idClient_project,clientId:this.clientproject.clientId,project_name:this.clientproject.project_name,employees:employees,startDate:startDate,endDate:endDate,total_no:this.clientproject.total_no}).toPromise().then((p) => {
          if (p) {


            let employees = this.clientproject.employees.toString();



            this.httpClient.post(this.cbl.urlBaseBL + 'client_project_employee/' + this.clientproject.idClient_project, {idClient_project:this.clientproject.idClient_project,clientId:this.clientproject.clientId,employees:employees}).toPromise().then((k) => {
              if (k) {
    
     
    
             this.ref.close(true);
    
              
    
              }
              });



          this.ref.close(true);



          }
          });

    
  }











  clientcollaboratorAttachDocument(complaintAttachDocument: any) {
    throw new Error('Method not implemented.');
  }

  cerrar() {
    this.ref.destroy();
  }



  loadClientproject(id) {
    let url2 = 'client_project?'
    +'&fields=idClient_project,clientId,project_name,employees,startDate,endDate,total_no'
    + '&where=idClient_project=' + id;

    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL + url2).toPromise().then(async (r) => {
      console.log(r.resp);
      // r.resp[0].horainicio = r.resp[0].horainicio.substr(0, 5);
      // r.resp[0].horafin = r.resp[0].horafin.substr(0, 5);
      // r.resp[0].fechainicio = formatDate(r.resp[0].fechainicio, 'dd/MM/yyyy', 'en-US');
      r.resp[0].idClient_project = r.resp[0].idClient_project;
      r.resp[0].clientId = r.resp[0].clientId;
      r.resp[0].project_name = r.resp[0].project_name;


        var employees=r.resp[0].employees;
        if (employees.charAt(employees.length - 1) === ',') {
        employees = employees.slice(0, -1);
        }
        var employees = (employees[0] == ',') ? employees.substr(1) : employees;

        let employeesArr=employees.split(",").map(Number);
        //var b = a.split(',').map(Number);


      r.resp[0].employees = employeesArr;
     // r.resp[0].collab_password = r.resp[0].collab_password;
      r.resp[0].startDate = formatDate(r.resp[0].startDate, 'dd/MM/yyyy', 'en-US');
      r.resp[0].endDate = formatDate(r.resp[0].endDate, 'dd/MM/yyyy', 'en-US');

      
      // if(r.resp[0].complaintHandledClientId!=0)
      // {
        
      //   r.resp[0].complaintHandledClientName = await this.getPersenInChargeName(r.resp[0].complaintHandledClientId); 
      // }
      // else{
      //   r.resp[0].complaintHandledClientName = '';
      // }
      
       this.clientproject = new Clientproject(r.resp[0]);
      //this.complaint = r.resp[0];
      //this.complaint.fechafin = this.complaint.fechainicio;

      console.log("tttttttttoooooooooooooo",this.clientproject);
    });
  }

  /**functions to cpature comments and upload files start */
  onBasicUpload(inputData, key) {
    for (const file of inputData.currentFiles) {
      const dataset = this.readFile(file, key);
    }
  }

  private readFile(file: File, key) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.remarkBody[key] = JSON.stringify(reader.result);
    };
  }

  saveComment_old() {
    this.httpClient.post<Respuesta>(this.cbl.urlBaseDL + 'incidenciaremark/' + this.config.data.id, this.remarkBody).toPromise().then((r) => {
      if (r) {
        this.remarkBody.comment = null;
        this.remarkBody.file1 = null;
        this.remarkBody.file2 = null;
        this.remarkBody.file3 = null;
        this.getIncendias(this.config.data.id);
        this.clear();
      }
    });
  }
  /**functions to cpature comments and upload files end */
  getIncendias(id) {
    this.httpClient.post<Respuesta>(this.cbl.urlBaseDL + 'getincidenciaremark/' + id, {}).toPromise().then((r) => {
      this.history = r;
    });
  }

  showpdf(file) {
    let fileArray:any[] = file.split(",");
      const [,type] = fileArray[0].split(';')[0].split('/');
      const linkSource = file;
      const downloadLink = document.createElement("a");
      const fileName = "document."+type;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
  }

  clear() {
    this.fileUpload.clear();
    this.fileUpload2.clear();
    this.fileUpload3.clear();

    this.attachDocument.clear();
    
  }









  showProjectEmployeeDetail()
  {
 

    let url2='client_project_employee?'
    +'&fields=idClient_project_employee,clientId,idClient_project,idEmpleado';
    //url2=url2+"&joins=trabajador,solicitudes(E.idempleado=>S.idempleado)";
    url2+='&where=idClient_project="'+this.config.data.id+'"  ';
   url2+='&order=idClient_project_employee ASC';
  
  
 




    console.log("url.....fff............",url2);
		
    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL+url2).toPromise().then(async (r)=>{
      //console.log(r);
      console.log("vvvvvvvvvv",r.resp);
      for(let p of r.resp)
      {
        

        
        p['employeeName']=await this.getEmployeeName(p.idEmpleado);
        p['mainDetail']=await this.getMainDetail(p.idEmpleado,p.idClient_project);

      
         
      }
      console.log("allclientprojectemployee------------",r.resp);
     // this.complaints=r.resp;
      this.allclientprojectemployee=r.resp;
      
    });
  }


  async getMainDetail(id,idClient_project){
    let url2='client_project_employee_horario?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    // +'&joins=client_project(client_project_employee_horario.idClient_project=>client_project.idClient_project)'
    +'&fields=idClient_project,hour,minute,dateTime,work_desc'
    +'&where=idEmpleado='+id+' AND idClient_project='+idClient_project+' '
    +'&order=dateTime DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then(async (r)=>{
      let resp=r['resp'];
      //respuesta=resp[0]['empleado'];

      for(let h of resp)
      { 
        
          h['projectName']=await this.getProjectName(h.idClient_project);
         
  
      }
      respuesta=resp;
      //this.nominasTemp = r['resp'];
      console.log("cocok.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }

  async getProjectName(id){
    let url2='client_project?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idClient_project,project_name'
    +'&where=idClient_project='+id+' '
    +'&order=idClient_project DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['project_name'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }






}
