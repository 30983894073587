import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { UtilService } from '../../servicios/util.service';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { Utilidades } from '../../libs/utilidades';

@Component({
  selector: 'app-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss']
})
export class InformacionComponent implements OnInit {

  docs:any;
  links:any=null;
  
  constructor(private httpClient: HttpClient,public gds:GlobaldataService,private us:UtilService) { }

  ngOnInit() {
	  
	// this.httpClient.get(this.gds.urlBaseDL+'documentos/links').subscribe((res)=>{
  //       console.log(res);
	// 	this.links=res;
  //   });
	
	// this.httpClient.post(this.gds.urlBaseDL+'documentos/tree',{
	// 	fields:'D.idMateria,""  as materia,D.idSubmateria,M2.materia as submateria,idDocumento,descripcion,"doc" as tipo, visto,cast(fechaEntrada as char) as fechaEntrada'
	// 	,orden:'materia,submateria,fechaIncorporacion DESC'
	// 	,where:'D.idSubmateria<>0'
	// 	,filterExtranet:true
	// }).subscribe((res)=>{
  //       console.log(res);
	// 	this.docs=res[0].children;
  //   });

  var params={
    fields:"idDocumento,descripcion,idMateria,idSubMateria,fechaincorporacion",
    joins:"materias(idSubMateria)",
    where:"idCliente=5001 and D.idMateria=200",
    order:"M.materia,fechaincorporacion desc",
    tree:"materias.materia(idSubMateria)=>idDocumento,descripcion,fechaincorporacion",
  };
  //materias.materia(idMateria,materias.materia),

  //var vlo=btoa(JSON.stringify(params));
  //this.httpClient.get<any>(this.gds.urlBaseDL+"documentos/?vlo="+Utilidades.packVLO(params)).subscribe((res)=>{
  // this.httpClient.post<any>(this.gds.urlBaseDL+"documentos/query",{'vlo':Utilidades.packVLO(params)}).subscribe((res)=>{

  //   console.log("RECIBIDO:",res);
  //   res=res.resp;
    
  //   for (var rama of res)
  //   {
  //     rama['collapsedIcon']="fa fa-folder";
  //     rama['expandedIcon']="fa fa-folder-open";
  //     for (var subrama of rama['children'])
  //     {
  //       subrama['collapsedIcon']="fa fa-folder";
  //       subrama['expandedIcon']="fa fa-folder-open";
  //       for (var item of subrama['children'])
  //       {
  //         item['type']="doc";
  //         item['icon']="fa fa-file";
  //         item['label']=item['descripcion'];
  //         item['key']=item['idDocumento'];
  //         item['data']={'fechaEntrada':item['fechaEntrada'].substr(0,10),'tipoFecha':item['tipoFecha']}
  //       }
  //     }
  //   }
  //   console.log("PROCESADO:",res);
  //   //this.itemsArbol=res;
  // });
  

  this.httpClient.post(this.gds.urlBaseDL+'multiple',
	[
		// {"method":"get","url":"documentos","params":{
		// 	"order":"fechaIncorporacion DESC",
		// 	"fields":'[idDocumento,descripcion,visto,fechaIncorporacion]',
		// 	"limit":10
		// 	,"where":"idCliente="+this.gds.id_cliente
		// }},
		{"method":"post","url":"documentos/query","params":{'vlo':Utilidades.packVLO(params)}}
	]
	).subscribe((res)=>{
    console.log(res);
    this.docs=res[0]['resp'];
    console.log(this.docs);
		//this.recientes=res[0]['resp'];
		//this.publicaciones=res[1]['resp'];
		//this.asuntos=res[2]['resp'];	
	});
}


  linksByMateria(mat)
  {
	  if(this.links==null) return [];
	  return this.links.filter(link=>("R"+link.tema.codigo)===mat);
  }
  
  

}
