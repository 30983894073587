import { Component, OnInit,AfterContentInit,ViewChild,ElementRef } from '@angular/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { HttpClient} from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { UtilService } from '../../servicios/util.service';
import { Respuesta } from '../../respuesta';

@Component({
  selector: 'app-costes',
  templateUrl: './costes.component.html',
  styleUrls: ['./costes.component.scss']
})
export class CostesComponent implements OnInit {

  //@ViewChild('myChart') myChart:ElementRef


  chartLabels: any;
  data: any;
  data_anual: any;
  options: any;
  options2: any;

  constructor(private httpClient: HttpClient,public gds:GlobaldataService,private us:UtilService) {}
  
  ngOnInit(): void 
  {
    this.chartLabels = ChartDataLabels;

    this.data = {
      labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
      datasets: [
          {
              label: 'Coste mensual',
              data: [65, 59, 80.7, 81, 56, 55, 40]
          }
      ]};

      //let filtro="idCliente="+this.gds.id_cliente+" AND fechaEjercicio LIKE '"+2020+"%'";
      //filtro+=" and (idSubMateria=215 or idSubMateria=33) and (etiquetas='NOMINAS' or etiquetas like 'NOMINAS,%' or etiquetas='LAB,RC' or etiquetas like 'LAB,RC,%') ";
      let prox_mes:number=parseInt(this.gds.mes_actual);//+1;
      
      this.httpClient.post<Respuesta>(this.gds.urlBaseDL+'salario/query',{
        fields:'S.mes, S.ano,sum(S.importe) as importemensual'
        ,where:'E.idCliente='+this.gds.id_cliente+' and ((S.ano='+(this.gds.ejercicio_actual-1)+' and S.mes>='+prox_mes+') or S.ano='+(this.gds.ejercicio_actual)+')'
        ,joins:'empleado(S.idEmpleado=>E.idEmpleado)'
        ,groupby:'S.ano,S.mes'
        ,orderby:'S.ano,S.mes'
      }).subscribe((res)=>{
        console.log(res);
        let anuales=[];
        let labels_ano=[];
        //for (let i=(this.gds.ejercicio_actual-2);i<=this.gds.ejercicio_actual;i++) labels_ano.push(i);
        for(let s of res.resp)
        {
          labels_ano.push(s['mes']+'/'+String(s['ano']).substring(2,4));
          anuales.push(Math.round(s['importemensual']/1000));
        }

        this.data_anual = {
          labels: labels_ano,
          datasets: [
              {
                  //label: 'Coste anual',
                  data: anuales,
                  //labels:['AAA','BBB','CCC']
              }
          ]
        }

      });

    

this.options2={
  title: {
    display: true,
    text: 'Pagos netos mensuales (miles de euros)',
    fontSize: 16
  },
  legend: {
    position: 'bottom',
    display:false
  },
  plugins: {
    datalabels: {
      color: 'darkgray',
      display: function(context) {
        return true;
      },
      font: {
        weight: 'bold'
      },
      formatter: this.formateador
    }
  },

  scales: {
    yAxes: [
     {stacked: true}
     /*{
      ticks: {
        callback: (label, index, labels) => {
          return label + 'K';
        }
      }
    }*/
    ]
  }

};

this.options = {
  plugins: {  //ChartDataLabels:{
    datalabels: {
      //color: 'red',
      align: 'center',
      anchor: 'center',
      display: function(context) {
          //console.log(context);
          return true;
        //return context.dataset.data[context.dataIndex] > 15;
      },
      font: {
        //weight: 'bold'
      },
      formatter: Math.round
    }
  },
  title: {
      display: true,
      text: 'Pagos netos mensuales',
      fontSize: 16
  },
  legend: {
      position: 'bottom',
      display:false
  },
  scales: {
    yAxes: [
      {
        ticks: {
          callback: (label, index, labels) => {
            return label + '%';
          }
        }
      }
    ],
    y: {
        type: 'linear',
        min: 50,
        max: 100
    }}/*,
  animation:{
    onProgress: this.drawDatasetPointsLabels(),
    onComplete: this.drawDatasetPointsLabels()
}*/
  };

}

public formateador(x)
{
  return x+"K";
}

  public drawDatasetPointsLabels()
  {
    //let myChart2=document.getElementById('myChart');
    //console.log(this.myChart);
    /*console.log("x");
    let myChart2=document.getElementById('myChart')  ;
    var ctx = document.getElementById('myChart').getContext("2d");
      myChart2.data.datasets[0]._meta[0].data.forEach(function(element,index){
      ctx.fillStyle = 'rgb(128, 128, 128)';
      var fontSize = 10;
      var fontStyle = 'normal';
      var fontFamily = 'Helvetica Neue';
      ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
      var dataString = myChart2.data.datasets[0].data[index].toString();
  
      if(index===0)
          ctx.textAlign = 'left';
      else if (index===myChart2.data.datasets[0]._meta[0].data.length-1)
          ctx.textAlign = 'right';
      else
          ctx.textAlign = 'center';
  
      ctx.textBaseline = 'middle';
      var padding = 5;
      var position = element.tooltipPosition();
      ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
      });
      */
      return;                   
  }
  


}
