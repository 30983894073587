<style>
    #clienProject .p-calendar .p-datepicker-touch-ui, .p-datepicker-touch-ui {
      left: 0% !important; 
      min-width: 50vw !important;
      position: fixed !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
    
    </style>
    


<div class="complaints-header" id="clienProject">
    <div class="center">
    </div>
    <!-- <div class="right">
        <div style=" display:flex;  flex-direction: row;">
            <p-dropdown
                [options]="yearArray"
                placeholder="Seleccionar año"
                optionLabel="year"
                [showClear]="true"
                [(ngModel)]="selectedYear"
                [style]="{'font-size':'10px'}"
                #dropDownThing
            >
            </p-dropdown>


            <button class="button" (click)="searchBasedOnYear()" style="margin-left: 4px; cursor: pointer;">Buscar</button>
        </div>
    </div> -->


    <!-- <div class="right">
        <div style=" display:flex;  flex-direction: row;">vvvvvvvvvvvvvvv

            <p-dropdown placeholder="Seleccione"  id="employees"  [options]="empList" [(ngModel)]="selectedEmployee"  [showClear]="true"  [readonly]="true"></p-dropdown>

            
            <button class="button" (click)="searchBasedOnProject()" style="margin-left: 4px; cursor: pointer;">Buscar</button>
        </div>
    </div> -->


    <div class="grid filtro r-grid ">

        <div class="g-w4field">
            <small>Empleado:</small>
            <p-dropdown [autoDisplayFirst]="false" [showClear]="true"  [options]="empList" [(ngModel)]="selectedEmployee" ></p-dropdown>


            <!-- <p-dropdown [autoDisplayFirst]="true" [showClear]="false" [options]="empleados" [(ngModel)]="selectedEmployee" optionLabel="empleado" ></p-dropdown> -->
        </div>

        <div class="g-w4field"> 
            <small>Proyecto:</small>
            <p-dropdown  [autoDisplayFirst]="false" [showClear]="true"  [options]="projectList" [(ngModel)]="selectedProject" ></p-dropdown>

        </div>

        <div class="g-w4field">
            <small>Desde:</small>
           
            <p-calendar id="fromDate" [(ngModel)]="selectedFromDate" [showIcon]="true" [locale]="dateConfig"
            [touchUI]="true" [selectOtherMonths]="true"  dateFormat="dd/mm/yy"></p-calendar>


        </div>

        <div class="g-w4field">
            <small>Hasta:</small>
            <p-calendar id="toDate" [(ngModel)]="selectedToDate" [showIcon]="true" [locale]="dateConfig"
            [touchUI]="true" [selectOtherMonths]="true"  dateFormat="dd/mm/yy"></p-calendar>
        </div>


        <div class="g-w4field">
            <div class="f-box f-jcsb">
                <div class="f-w1" style="margin-top:10px"><p-button  (onClick)="searchBasedOnProject()">Buscar</p-button></div>
            </div>
        </div>

    </div>




</div>


<div class="example-column">
    <p-table [value]="clientprojects" selectionMode="single" [(selection)]="selectedClientproject"
        [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
        currentPageReportTemplate="Mostrando {first} de {totalPages}" (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)">
        <ng-template pTemplate="header">
            <tr>
                <th>Proyecto </th>
                <th>Asignado a:</th>
                <th>Fecha inicio</th> 
                <th>Fecha fin</th>
                <th>Total (horas)</th>
                <th>Horas trabajadas</th>
                <th width="5%">Informes</th>
                <th width="10%">Acción</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-clientproject>
            <tr [pSelectableRow]="clientproject">
                
                <td ><a  (click)="selectClientprojectDetail(clientproject.idClient_project,'detail')">{{clientproject.project_name}}</a> </td>
                <!-- <td>{{clientproject.employeeName}}</td> -->
                <td>
                    <ul> 
                    <li *ngFor="let item of clientproject.employeeName; index as i ">
                        {{i+1}}. {{item}}
                    </li>
                    </ul>
                 </td>
                <td>{{clientproject.startDate}}</td>
                <td>{{clientproject.endDate}}</td>
                <td>{{clientproject.total_no}}</td>
                <td>{{clientproject.total_hour_worked}}</td>
                <td><button title='Ver detalle' pButton pRipple type="button" icon="fas fa-book" class="p-button-outlined"
                    (click)="selectClientprojectDetail(clientproject.idClient_project,'projectdetail')"></button></td>
                
                <td>
                    <button title='Ver detalle' pButton pRipple type="button" icon="fas fa-eye" class="p-button-outlined"
                    (click)="selectClientproject(clientproject.idClient_project)"></button>
                    &nbsp;
                    <button title='Borrar' pButton pRipple type="button" icon="fas fa-close" class="p-button-outlined"
                    (click)="deleteClientproject(clientproject.idClient_project,clientproject.project_name)">X</button>
                </td>
            </tr>
        </ng-template>
    </p-table>    
</div>

<div class="grid r-grid r-grid12-6" style="width:'100%';">
    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w1"><p-button label="Nuevo proyecto" (click)="nueva()" icon="fas fa-paper-plane" iconPos="left"></p-button></div>
            <br>
            <br>
            <br>
            <br>
            <br>
        </div>
    </div>
</div>
