import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { MainRoutingModule } from './main-routing.module';
import { RecientesComponent } from './recientes/recientes.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { BrowserModule } from '@angular/platform-browser';


import { HttpClientModule ,HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';






//import {LoginComponent} from './pages/login/login.component';
import { MainComponent } from './main.component';
import {APP_BASE_HREF} from '@angular/common';
import { MisdatosComponent } from './misdatos/misdatos.component';
import { ConsultasComponent } from './consultas/consultas.component';
import { InformacionComponent } from './informacion/informacion.component';
import { SalirComponent } from './salir/salir.component';
//import {VirtualScrollerModule} from 'primeng/virtualscroller';

import { FechaTipadaPipe } from '../libs/fechaTipada.pipe';
import { MisasuntosComponent } from './misasuntos/misasuntos.component';
import { AsuntoComponent } from '../componentes/asunto/asunto.component';

import { EnviarComponent } from '../documentos/enviar/enviar.component';
import { MisdocumentosComponent } from '../documentos/misdocumentos/misdocumentos.component';
import { ImpuestosComponent } from '../documentos/impuestos/impuestos.component';


import { NominasComponent } from '.././empleados/nominas/nominas.component';
import { DocsempComponent } from '.././empleados/docsemp/docsemp.component';
import { ResnomComponent } from '.././empleados/resnom/resnom.component';
import { CostesComponent } from '.././empleados/costes/costes.component';
import { PortalComponent } from '.././empleados/portal/portal.component';
import { HorarioComponent } from '.././empleados/horario/horario.component';
import { SolicitudesComponent } from '.././empleados/solicitudes/solicitudes.component';
import { HdiarioComponent } from '.././empleados/hdiario/hdiario.component';
import { HdiarioglobalComponent } from '.././empleados/hdiarioglobal/hdiarioglobal.component';
import { ConsultapermisosComponent } from '.././empleados/consultapermisos/consultapermisos.component';
import { ConsultaglobalComponent } from '.././empleados/consultaglobal/consultaglobal.component';
import { ConsultaglobalmonthComponent } from '.././empleados/consultaglobalmonth/consultaglobalmonth.component';
import { ReportglobalComponent } from '.././empleados/reportglobal/reportglobal.component';
import { ReportglobalmonthComponent } from '.././empleados/reportglobalmonth/reportglobalmonth.component';
import { AppCommonModule } from 'src/app/app.common.module';
import { RouterModule } from '@angular/router';
import { HeaderBreadcrumbComponent } from '../header-breadcrumb/header-breadcrumb.component';
import { MisspunchSolicitudesComponent } from '../empleados/misspunch-solicitudes/misspunch-solicitudes.component';

import { IncidenciasComponent } from '.././empleados/incidencias/incidencias.component';
import { ComplaintsComponent } from '.././empleados/complaints/complaints.component';
import { ComplaintComponent } from '.././empleados/complaint/complaint.component';

import { ClientcollaboratorsComponent } from '.././empleados/clientcollaborators/clientcollaborators.component';
import { ClientcollaboratorComponent } from '.././empleados/clientcollaborator/clientcollaborator.component';


import { ClientdepartmentsComponent } from '.././empleados/clientdepartments/clientdepartments.component';
import { ClientdepartmentComponent } from '.././empleados/clientdepartment/clientdepartment.component';


import { ClientprojectsComponent } from '.././empleados/clientprojects/clientprojects.component';
import { ClientprojectComponent } from '.././empleados/clientproject/clientproject.component';
import { ClientprojectdetailComponent } from '.././empleados/clientprojectdetail/clientprojectdetail.component';


import { ConfirmdocumentComponent } from '.././documentos/confirmdocument/confirmdocument.component';

@NgModule({
  declarations: [ImpuestosComponent,EnviarComponent,MisdocumentosComponent,FechaTipadaPipe,MainComponent,RecientesComponent, DocumentosComponent, MisdatosComponent, ConsultasComponent, InformacionComponent, SalirComponent, MisasuntosComponent, AsuntoComponent,
	NominasComponent,
  IncidenciasComponent,
    DocsempComponent,
    ResnomComponent,
	CostesComponent,PortalComponent,HorarioComponent,HdiarioglobalComponent,SolicitudesComponent,
	HdiarioComponent,ConsultapermisosComponent,ConsultaglobalComponent,ConsultaglobalmonthComponent,HeaderBreadcrumbComponent,MisspunchSolicitudesComponent,ReportglobalComponent,ReportglobalmonthComponent,ComplaintsComponent,ComplaintComponent,ClientcollaboratorsComponent,ClientcollaboratorComponent,ClientdepartmentsComponent,ClientdepartmentComponent,ConfirmdocumentComponent,ClientprojectsComponent,ClientprojectComponent,ClientprojectdetailComponent],
  imports: [
	AppCommonModule,
    CommonModule,
    MainRoutingModule,
	BrowserModule,
  RouterModule,
  ]
})
export class MainModule{}
export class RecientesModule { }


