import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SolicitudInterceptor } from '../interceptores/solicitud.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Utilidades } from '../libs/utilidades';
import { GlobaldataService } from '../servicios/globaldata.service';
import { Tarea,TIPO_TAREA } from '../dataobjects/clasesbl';
import {HttpEventType } from '@angular/common/http';
import { UtilService } from '../servicios/util.service';
import { Respuesta } from '../respuesta';

@Injectable({
    providedIn: 'root'
  })

export class Bl {

	constructor(private us:UtilService,private httpClient: HttpClient,public gds:GlobaldataService) { }

	/* =================================================
							DOCUMENTOS
	==================================================== */
	async send_documentos(ficheros,meta:Object,meta_files:Object={})
	{
		const formData = new FormData();
		for (let key in ficheros)
		{
			//console.log("Ficheros:"+key+" ===> " +ficheros[key]+" " +ficheros[key].name);
			formData.append("files", ficheros[key], ficheros[key].name);
		}
		formData.append('meta',JSON.stringify(meta));	
		if(Object.keys(meta_files).length>0) formData.append('meta_files',JSON.stringify(meta_files));
		let results;

		await this.us.uploadWithoutProgress(formData,this.gds.urlBaseDL+'files').then((res)=>{
			//console.log(res);
			results=res.resp.ids;
		});
		return results;
	}

	
	/* =================================================
							TAREAS
	==================================================== */
    public load_tarea(idTarea)
	{
		const prefijos:string[]=["Creación tarea desde ","Asignada a ","Enviado email a cliente "
        ,"Enviado doc por FAX a cliente ","Enviado sms a cliente ","Enviados docs a cliente "
        ,"Enviados docs a ","Peticion información a cliente ","","Finalización tarea "
		,"Enviado doc por FAX a ","Email de respuesta ","Nota ","Llamada saliente "
		,"Incorporacion Documentos ","Reunión ","Visita "];
		var url2='tareas?where=T.idTarea='+idTarea+'&joins=tramites(T.idTarea=>T2.idTarea),tareadocs(T2.idTramite=>T3.idTramite),documentos(T3.idDoc=>D.idDocumento),registroSalida(T2.idRegSalida=>R.idRegSalida)&order=T2.idTramite';
		url2+="&fields=Tarea,concepto,T2.idTramite,T2.fecha,T2.tramite,T2.tipo,D.idDocumento,D.Descripcion,R.nameDest,R.destinatario";
		url2+='&tree=idTarea(idTarea,Tarea,concepto),tramites.idTramite(tramites.idTramite,tramites.tipo,tramites.fecha,tramites.tramite,registroSalida.nameDest,registroSalida.destinatario)=>[documentos.idDocumento,documentos.Descripcion]';
		return this.httpClient.get(this.gds.urlBaseDL+url2).toPromise().then(res=>{
			var r={};
			console.log(res);
			r['tarea']=res['resp'][0].data.Tarea;
			r['consulta']=res['resp'][0].data.concepto;
			r['tramites']=res['resp'][0].children.map((tramite)=>{
				var nombre:string=prefijos[tramite.data['tipo']]+" "+tramite.data['tramite'];
	  			if (tramite.data['tipo']==6 || tramite.data['tipo']==10) nombre=nombre+" "+tramite.data['nameDest']+" ("+tramite.data['destinatario']+")";
				var docs=tramite.children.filter((doc)=>{return doc[0]!=null;}).map((doc)=>{
					return {id:doc[0],documento:doc[1]}
				});

				return {idTramite:tramite.data["idTramite"],
						tramite:nombre,
						docs:docs, 
						tipo:tramite.data["tipo"],
						fecha:tramite.data["fecha"].substr(0,10)}
			});
			console.log(r);
			return r;
		});
	}
	
	async nueva_tarea(tarea)
	{
		let idTarea=0;
		await this.httpClient.post<Respuesta>(this.gds.urlBaseDL+'tareas',tarea).toPromise().then((r)=>{
			console.log(r);
			idTarea=r.resp.idTarea;
		});
		return idTarea;
	}

	async add_docs_to_tarea(tarea,idTramite,docs)
	{
		let comands=[];

		if(idTramite==0)
		{
			let tramite={idTarea:tarea,tramite:"",idUsuario:0,tipo:14};
			comands.push({"method":"post","url":"tramites","params":tramite});
		}

		let datos=[];
		for(let d of docs) datos.push({idDoc:d,idTarea:tarea,idTramite:"#0.idTramite#"});
		comands.push({"method":"post","url":"tareadocs","params":datos});

		this.httpClient.post(this.gds.urlBaseDL+'multiple',comands).toPromise().then((r)=>{
			console.log(r);
		});

	}
}
