import { Component, OnInit, Input } from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {Table} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { Respuesta } from 'src/app/respuesta';
import { formatDate } from '@angular/common';
import { UtilService } from 'src/app/servicios/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Dropdown } from "primeng/dropdown"; 



//////////////////////////////new add/////////////////////////////////
import {CBLService} from 'src/app/servicios/cbl.service'
import { ComplaintComponent } from '../complaint/complaint.component';

//import {IncidenciaComponent } from '../incidencia/incidencia.component';

export class Complaint {
  estado: string;
  fechaInicio: Date;
  fechaFin: Date;
  observaciones: string;
  tipoIncidencia: number;
  adjuntos: any[] = []; 
  tipo: string;
  motivo: string;
}

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.scss']
})
export class ComplaintsComponent implements OnInit {

  public empleados=[];
  empleado:number=0;
  public ejercicio:number;
  complaints: any;
  selectedYear: any;
  currentSearchYear: string;
  complaintsTemp: any;
  selectedComplaint: Complaint = null;
  collapsed: boolean;
  selectedTipoComplaint: any;
  tempComplaint: Complaint;
  tiposComplaints: any;
  yearArray:any[] = [];
  //dialogService: any;
  
  //public dialogService:DialogService;

  constructor(public cbl: CBLService,private httpClient: HttpClient,public gds:GlobaldataService,private us:UtilService,private messageService: MessageService,
    private router:Router, private routera: ActivatedRoute,public dialogService:DialogService) { 
  }



  ngOnInit(): void {

    this.empleados=this.gds.getEmpleados();
		console.log(this.empleados);
		this.empleado=this.empleados[0].value;
		this.ejercicio=0;
    this.createYearPicker();
    this.filtrar();

  }
  
  public filtrarEj()
  {
        let old_emp=this.empleado;
        //console.log("EJ:"+this.ejercicio);
        if(this.ejercicio==0 || this.ejercicio==null)
        {
          this.empleados=this.gds.getEmpleados();
          let enc=this.empleados.find(x=>x.value==old_emp);
          //console.log("=>",old_emp,enc);
          if (enc!=undefined) this.empleado=old_emp;
          this.filtrar();
        }	
        else
        {
          let url="fields=E.idempleado as value,T.empleado as label";
          url=url+"&joins=trabajador,documentos(E.idempleado=>D.idempleado)&oder:T.empleado";
          url=url+"&where=E.idcliente="+this.gds.id_cliente+" and substr(D.fechaejercicio,1,4)='"+this.ejercicio+"'";
          url=url+"&groupby=E.idempleado,T.empleado"
          url=url+"&order=T.empleado";
          this.httpClient.get<Respuesta>(this.gds.urlBaseDL+"empleado?"+url).subscribe((res)=>{
            //console.log(res);
            this.empleados=res.resp;
            let enc=this.empleados.find(x=>x.value==old_emp);
            //console.log("--->",old_emp,enc);
            if (enc!=undefined) this.empleado=old_emp; else this.empleado=0;
            //console.log(this.empleado);
            this.filtrar();
          });
        }
  }


  selectComplaint(id){
    const ref = this.dialogService.open(ComplaintComponent, {
      data:{id:id},
      header: 'Detalle denuncia',
      width: '70%'
  });

  }



  async getEmployeeName(id){
    let url2='empleado?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=E.idEmpleado as idEmpleado,T.empleado as empleado'
    +'&where=E.idEmpleado='+id+' '
    +'&order=E.idEmpleado DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['empleado'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }

  public filtrar()
  {
    //alert("aaa");
    if(this.empleado==0 || this.empleado==null) {return;}
    let ej=this.ejercicio;
    if (ej==null) ej=0;

    console.log(this.empleado+"-------"+ej);


    // let url2='solicitudes?'
    // +'&fields=idsolicitud,solicitud,fechainicio,obs,fechafin,horainicio,horafin,fechas,estado'
    // +'&where=idEmpleado='+this.empleado+" and idTipoPermiso=0"
    // +'&order=fechainicio DESC';
    //+'&tree=solicitud->permisos=>fechainicio,fechafin,horainicio,horafin,fechas,estado';


    // let url2='solicitudes?'
    // +'&fields=idsolicitud,solicitud,fechainicio,obs,fechafin,horainicio,horafin,fechas,estado';
    // url2+='&where=idEmpleado="'+this.empleado+'" and idTipoPermiso=0 ';
   
    
    // if(ej!=0)
    // {
    //   url2+="and substr(fechainicio,1,4)='"+ej+"' ";
    //    }
  
    // url2+='&order=fechainicio DESC';


    // let url2='complaint?'
    // +'&fields=idComplaint,idEmpleado,complaintDate,complaintPrivacy,complaintType,complaintAttachDocument,complaintDesc,complaintStatus,complaintCommunication'
    // +'&where=idEmpleado='+this.cbl.id_empleado+""
    // +'&order=idComplaint DESC';


    let url2='complaint?'
    +'&fields=idComplaint,idEmpleado,complaintDate,complaintPrivacy,complaintType,complaintAttachDocument,complaintDesc,complaintStatus,complaintCommunication';
    //url2+='&where=idEmpleado="'+this.empleado+'"  ';
   
    
    if(ej!=0) 
    {
      url2+="and substr(complaintDate,1,4)='"+ej+"' ";
    }
  
    url2+='&order=idComplaint DESC';

    console.log(url2);
    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then(async (r)=>{
      //console.log(r);
      console.log("vvvvvvvvvv",r.resp);
      for(let p of r.resp)
      {
        let dias=0;
        // for(let d of p.permisos)
        // {
          // let cd=p.fechas.split(",").length;
          // p['dias']=cd; 
          // if (cd>1)
          //   p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(p.fechafin,'dd/MM/yyyy','en-US');
          // else
          //   p['periodo']=p.fechainicio;

          // if(p.horainicio!=null && p.horafin!=null)
          // {
          //   p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
          // }
          // if(p.estado!="RECHAZADA") dias+=cd;

         // p['periodo']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(p.complaintDate,'dd/MM/yyyy','en-US');

         // p['periodo']=p.complaintDate;
        // }
        // let respEmp=this.getEmployeeName(p.idEmpleado);
        // console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
        //  p['dias']=dias;
        //  p['employeeName']=await this.getEmployeeName(p.idEmpleado);

       // let respEmp=this.getEmployeeName(p.idEmpleado);
        //console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
         p['dias']=dias;
         if(p.complaintPrivacy=='CONFIDENCIAL')
         {
          p['employeeName']=await this.getEmployeeName(p.idEmpleado);
         }
         else{
          p['employeeName']="-";
         }

        //  p['complaintNodays']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(new Date(),'dd/MM/yyyy','en-US');

         let date = new Date(p.complaintDate);
         let currentDate = new Date();
     
         let days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);


         let date1 = formatDate(new Date(),'yyyy-MM-dd','en_US');
         let date2 = formatDate(p.complaintDate,'yyyy-MM-dd','en_US');

          if(date1>date2){
          console.log('---date1 is greater----');
          p['complaintNodays']= days;
          }else{
          console.log('---date2 is greater-----');
          p['complaintNodays']= "";
          }
        
         //p['complaintNodays']="10 Days";
         //this.getEmployeeName(p.idEmpleado);
        //r.resp.push(p);
       // r.resp.push(m);
      }
      console.log(r.resp);
      this.complaints=r.resp;
      this.complaintsTemp=r.resp;
      this.searchBasedOnYear()
      
    });

  }
  createYearPicker(){
    let currentYear = new Date().getFullYear();
    let earliestYear = 2010;

    while (currentYear >= earliestYear) {
     this.yearArray.push({"year":currentYear})
      currentYear -= 1;
    }
  }
 
  searchBasedOnYear(){
    let tempData  = [];
    console.log(this.selectedYear)
    if(this.selectedYear != null && this.selectedYear !== undefined){
        this.currentSearchYear = ""+this.selectedYear["year"]
        this.complaintsTemp.forEach(value =>{
          let yearFromDb:string = ""+value["periodo"];
          if(yearFromDb.indexOf(this.currentSearchYear)>-1)  {
            tempData.push(value);
          }       
        });         
    }else{
      this.currentSearchYear  = ''
      this.complaintsTemp.forEach(value =>{
        tempData.push(value);
      });
    }
    this.complaints = tempData;
  }

  onRowSelect(event) {
    this.collapsed = false;
    this.tempComplaint = this.selectedComplaint;
    this.selectedTipoComplaint = this.tiposComplaints[
      this.tempComplaint.tipoIncidencia - 1
    ];
  }
  onRowUnselect(event) {
    this.collapsed = true;
    this.tempComplaint = new Complaint();
    this.selectedComplaint = null;
    this.selectedTipoComplaint = null;
  }






  }