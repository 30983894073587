import { Component, OnInit,Input, 
  ChangeDetectorRef,
  ChangeDetectionStrategy, 
  ElementRef,
  ViewChild} from '@angular/core';
import {DialogModule} from 'primeng/dialog';
import {MenuItem} from 'primeng/api'; 
import {MenubarModule} from 'primeng/menubar';
import {TooltipModule} from 'primeng/tooltip';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { Routes, RouterModule,Router, ActivatedRoute } from '@angular/router';
import { SolicitudInterceptor } from '../interceptores/solicitud.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobaldataService } from '../servicios/globaldata.service';
import { UtilService } from '../servicios/util.service';
import {MessageService} from 'primeng/api';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import {ToastModule} from 'primeng/toast';
import { skip } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ReCaptcha2Component } from 'ngx-captcha';
@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss']
})
export class Login2Component implements OnInit {
  /// Code for REcaptcha
  aFormGroup: FormGroup;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;
/// Code for REcaptcha Ends

  display:boolean=false;
  display2:boolean=false;
  display3:boolean=false;
  rememberMe:boolean = false;
  rdrt:boolean = false;
  pwd_cliente:string="";
  cliente:string="";
  dni:string="";
  idCliente:string="";
  
  //angularProjectVersion:string="";
  //public angularProjectVersion?: string;
  angularProjectVersion:string="";


  
  /// Code for REcaptcha
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'normal';
  public lang = 'es';
  public type: 'image' | 'audio';
  public recaptchaSuccess = false;
  //public recaptchaSuccess = true; 
  /// Code for REcaptcha Ends
  // this is a test
  resetPassword=false;
    
  constructor( private cdr: ChangeDetectorRef, private formBuilder: FormBuilder, private httpClient: HttpClient,private router:Router,private actrout: ActivatedRoute,private gds:GlobaldataService,private messageService: MessageService) { }

  ngOnInit() {
  /* const rememberMe = localStorage.getItem('rememberMe');
    if(rememberMe == 'yes') {
      this.cliente = localStorage.getItem('client');
      this.pwd_cliente = localStorage.getItem('pwd');
      this.rememberMe = true;
      this.rdrt = false;
      console.log('form local'+this.cliente+this.pwd_cliente);
    } else {*/
      this.cliente = this.actrout.snapshot.paramMap.get('user');
      
      this.pwd_cliente = this.actrout.snapshot.paramMap.get('apikey');
      this.idCliente = this.actrout.snapshot.paramMap.get('idCliente');
     // this.rdrt = true;
      console.log('form url'+this.cliente+this.pwd_cliente);

      
      
   // }  
    
     
      if(this.cliente != null && this.pwd_cliente != null) {
        this.gds.loginCliente(this.cliente,this.pwd_cliente,this.idCliente).then((res)=>{
          if (res)
          { 
            this.gds.init(); 
            this.router.navigate(['/main/recientes']);  
          }
          else
            this.messageService.add({severity:'warn', summary:'ERROR', detail:'Usuario o contraseña no válidos!'}); 
          }); 
      }
      this.aFormGroup = this.formBuilder.group({
        recaptcha: ['', Validators.required]
      });


      
      let angularProjectVersion =this.gds.angularProjectVersion;
      console.log("ddddddddddddddd",angularProjectVersion);
      this.angularProjectVersion =""+angularProjectVersion+"";
     // alert(angularProjectVersion);
    
  }

  


  show_cokies()
  {
	  this.display=true;
  }
  
  show_rgpd()
  {
	  this.display2=true;
  }
   
  login()
  {
     /// Code for REcaptcha
    if(this.recaptchaSuccess){
       /// Code for REcaptcha Ends
      console.log('from login btn'+this.cliente+this.pwd_cliente+this.rememberMe);
      this.gds.loginCliente(this.cliente,this.pwd_cliente,this.idCliente).then((res)=>{
        console.log("res..fofo...",res)
      if (res)
      { 
        localStorage.setItem('client',this.cliente);
        localStorage.setItem('pwd',this.pwd_cliente);
        if(this.rememberMe) {
          localStorage.setItem('rememberMe', 'yes');
        }
        this.gds.init(); 
        this.router.navigate(['/main/recientes']); 
      }
      else
        this.messageService.add({severity:'warn', summary:'ERROR', detail:'Usuario o contraseña no válidos!'});
      },err=>{

        
          if(err.error.attempt>=5){
            this.messageService.add({severity:'warn', summary:'ERROR', detail:'Su cuenta está bloqueada debido al número de intentos fallidos de acceso. Para desbloquearla, por favor contáctenos. Saludos. Torquemada Asesores.'});
          }
        console.log("errr",err)
      });
      /// Code for REcaptcha Start 
    } else {
      this.messageService.add({severity:'warn', summary:'ERROR', detail:'Por favor confirme reCaptcha!'});
    }
     /// Code for REcaptcha Ends
   
  }

  /// Code for REcaptcha
  handleSuccess(data) {
    console.log(data);
    this.recaptchaSuccess = true;
  }
  /// Code for REcaptcha Ends
  /*view_recordar()
  {
	  this.display3=true;
  }*/
  
  //cmd_recordar()
  view_recordar()
  {	  

    if(this.cliente.length==0)
    {
      this.messageService.add({severity:'error', summary:'Error', detail:"Debe rellenar la caja Usuario con su DNI o CIF"});
      return;
    }

	  const payload = new HttpParams()
		.set('cif', this.cliente);
		
		this.httpClient.post(this.gds.urlBaseBL+'recordarpwd',payload).toPromise().then((res)=>{
			console.log(res);
			if(res["resp"]==='OK')
			{
				this.messageService.add({severity:'info', summary:'Recordatorio', detail:'La contraseña se ha enviado a su email'});
				this.display3=false;
			}	
		}).catch((e)=>{
      console.log(e);
      this.messageService.add({severity:'error', summary:'Error', detail:e.error.resp});
    });
	  
	  
  }
  reset_recordar() {
    this.resetPassword=false;
this.gds.resetPassword(this.cliente).subscribe(()=>{

})


  }
}
