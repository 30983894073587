import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'FechaTipadaPipe'})
export class FechaTipadaPipe implements PipeTransform {
  transform(value: string, tipo?: number): string {
    //return Math.pow(value, isNaN(exponent) ? 1 : exponent);
	if (tipo==0) return value;						// Normal
	var mes:number=Number(value.substring(5,7));
	var trimestre:number=Math.floor(mes/4)+1;
	var periodo:number=Math.floor(mes/3)+1;
	var anno:string=value.substring(0,4);
	if (tipo==1) return mes + "M-"+anno;  			// Mensual
	if (tipo==2) return trimestre + "T-"+anno;		// Trimestral
	if (tipo==3) return anno;			  			// Anual
	if (tipo==4) return periodo + "P-"+anno;		// Periodo
	return value+" " +mes;
  }
}