
import { Component, OnInit } from '@angular/core';
import {Table} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})

export class PortalComponent implements OnInit {
  empleados:any=[];
  departmentList:any=[];
  clientdepartment:any=[];
  public selectedEmpleados:any=[];

  constructor(private httpClient: HttpClient,public gds:GlobaldataService,private messageService: MessageService) { 
  }

  ngOnInit(): void {
    this.loadEmpleados();
    this.loadDepartments();
    
  }

  public invitar()
  {
    let i=0;
    for (let emp of this.selectedEmpleados)
    {
      if(emp.email!="")
      {
        i++;
        this.httpClient.post(this.gds.urlBaseBL+"invitaremp",{id:emp.idEmpleado}).toPromise().then(res=>{
          console.log(res);
        });
      }
    }
    this.messageService.add({severity: 'info', summary: 'Invitación al Portal Empleado', detail: i+' empleados invitados con éxito!'});
  }

  public loadEmpleados()
	{
    let url2='empleado?&joins=!{trabajador}(E.idTrabajador=>T.idTrabajador)'
    +"&fields=E.idEmpleado,T.empleado,E.idClient_department,T.telefonomovil,T.email,(E.password!='$)F3JAN4FG4LAJ;3') as invitado"
    +'&where=E.baja=0 and E.idCliente='+this.gds.id_cliente
    +'&order=T.empleado'

    return this.httpClient.get(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      console.log(r)
      this.empleados=r['resp'];
    //   this.empleados=this.empleados.map((emp)=>{
    //      emp['selected']=false;
    //      return emp;
    //   });
    //   console.log(this.empleados);
     });
	}


  public loadDepartments()
	{
    let url2='client_department?'
    +"&fields=C.idClient_department,C.department_name"
    +'&where=C.clientId='+this.gds.id_cliente
    +'&order=C.idClient_department'

    return this.httpClient.get(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      console.log(r)
      let data=r['resp'];
    //   this.empleados=this.empleados.map((emp)=>{
    //      emp['selected']=false;
    //      return emp;
    //   });
      // console.log("rrrrrrrrrrrrrr",this.departmentList);

      let mainList = [];
      //let idClienteArr=[];
      for (let s=0;s<data.length;s++) {
      let list = {};
      //console.log("xxxxxxxxxxxxxxxx",data[s]);
      
      // list["idCliente"] = data[s].idCliente; 
      // list["Cliente"] =  data[s].Cliente;
      // list["isSelected"] = true;
      list["label"] = data[s].department_name; 
      list["value"] =  data[s].idClient_department;
      //list["isSelected"] = true;
      mainList.push(list);
    //  idClienteArr.push(data[s].idCliente);
      }
      console.log("departmentList refine....",mainList);
      this.departmentList=mainList;
     });
	}


  onChangeEmp(idEmpleado,event) {
    console.log('emp :' + idEmpleado);
    console.log('event :' + event);
    console.log(event.value);
    let idClient_department=event.value;


    this.httpClient.put(this.gds.urlBaseDL + 'department/empleado/' + idEmpleado, {id:idEmpleado,idClient_department:idClient_department}).toPromise().then((p) => {
      if (p) {
      }
      });
}





}
