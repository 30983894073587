<app-header-breadcrumb></app-header-breadcrumb>
<!-- <p class="tituloSeccion">Solicitudes de cambio entrada/salida empleados</p>   -->


<!--<p *ngFor="let sol of solicitudes;index as i">
    <p-card >
        
        <i style="margin-right:1rem" class='fas fa-umbrella-beach'></i><b>{{sol.solicitud}} - ({{sol.periodo}})</b>
        
        <div class="grid r-grid12-6">
            
            <div class="g-w6field">
                    Empleado: {{sol.empleado|uppercase}}
            </div>        
            <div class="g-w3field">
                Días solicitados: {{sol.fechas}} ({{sol.dias}} día/s)
            </div>
            <div class="g-w3field">
                Disfrutados({{sol.ano}}): {{getDisfrutados(sol.idEmpleado,sol.idtipopermiso,sol.ano)}} días
            </div>
            <div *ngIf="sol.doc!=null" class="g-w12field">
                <a href="#" (click)="gds.viewIDDoc(sol.doc)">Documento adjunto</a>
            </div>
            <div class="g-w12field">
                <input [(ngModel)]="sol.motivo" class="voraz" pInputText placeholder="Motivo del rechazo"/>
            </div>
            <div class="g-w12field">
                <div class="f-box f-jcsb" style="margin-bottom: -1rem;">
                    <div class="f-w1"><p-button label="Aceptar" (click)="aceptar(i)"></p-button></div>
                    <div class="f-w1"><p-button label="Rechazar" (click)="rechazar(i)"></p-button></div>
                </div>           
            </div>
        </div>
    </p-card>-->
<div class="grid r-grid r-grid12-6" style="width:'100%';">

    <div class="g-w12field">
        <p-table [value]="updatedData" dataKey="label">
            <!--<ng-template pTemplate="header">
                    <tr>
                        <th style="width:60px"></th>
                        <th align="left">Permisos y licencias consolidadas de {{selectedEmpleado['value']}} {{selectedEmpleado['label']}}, en {{ejercicio}}:</th>
                    </tr>
                </ng-template>-->

            <ng-template pTemplate="body" let-emp let-expanded="expanded">
                <tr>
                    <td style="width:60px"><button type="button" pButton pRipple [pRowToggler]="emp"
                            class="ui-button-text ui-button-rounded ui-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button></td>
                    <td style="text-align:left;padding-left:1rem">{{emp.name}} ({{emp.count}}count)</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-emp>
                <tr>
                    <td colspan="2">
                        <div class="p-p-3">
                            <p-table [value]="emp.value">
                                <ng-template pTemplate="header">
                <tr>
                    <th>Nueva entrada</th>
                    <th>Seleccionar</th>
                    <th>Entrada descripción:</th>
                    <th>Nueva salida</th>
                    <th>Salida descripción:</th>
                    <th>Seleccionar</th>
                    <th>Acción</th>
                    <!--<th>Estado</th>-->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-fic>
                <tr>
                    <!--<td>{{showfechas(fic.fechas)}}</td>-->
                    <td>{{fic.entradaUpdate}}</td>
                    <td style="width: 3rem">
                        <input *ngIf="fic.entradaUpdate" class="check-box" type="checkbox"
                            (click)="updateTime($event,fic,'entrada')">
                    </td>
                    <td (click)="showDescription(fic.entradaUpdateDescription)"><i class="fas fa-eye"></i></td>
                    <td>{{fic.salidaUpdate}}</td>
                    <td  (click)="showDescription(fic.salidaUpdateDescription)"><i class="fas fa-eye"></i></td>

                    <td style="width: 3rem">
                        <input *ngIf="fic.salidaUpdate" class="check-box" type="checkbox"
                            (click)="updateTime($event,fic,'salida')">
                    </td>

                    <td>
                        <p-button [disabled]="!fic.entradaSwitch&& !fic.salidaSwitch" label="Aceptar"
                            (click)="accept()"></p-button>&nbsp;<p-button label="Rechazar"
                            [disabled]="!fic.entradaSwitch&& !fic.salidaSwitch" (click)="reject()"></p-button>
                    </td>
                    <!--<td>{{fic.estado}}</td>-->
                </tr>
            </ng-template>
        </p-table>
    </div>
    </td>
    </tr>
    </ng-template>
    </p-table>
</div>

</div>


<p-dialog header="Detalles" [positionTop]="100" [contentStyle]="{'max-height':'80vh'}" [modal]="true"
    [blockScroll]="false" [(visible)]="displayDescription" [style]="{'width':'60%'}">
    <div>
                <p>{{description}}</p>
               
    </div>
</p-dialog>