<!-- <div class="abc" id="content">
  <h1>Hello World!</h1>
  <button (click)="Screen()">make pdf</button>
</div> -->



<div *ngIf="pagetype=='detail'" id="detail" >
    <div    class="p-grid p-justify-start" *ngFor="let itememp of allclientprojectemployee">
        <div class="p-col-12 p-md-12 p-lg-12">
        
        <fieldset>
            <div class="p-grid">
                <div class="p-col-12">
        <legend><strong> Trabajador: </strong> {{itememp.employeeName}} </legend> 
        <table class="clientprojectdetail-table" >
            <thead>
              <th>Fecha</th>
              <th>Hora/s Minuto/s Second/s</th>
              <th>Proyecto</th>
              <th>Descripcion</th>
            </thead>
            <tbody>
              <tr *ngFor="let itemempsub of itememp.mainDetail ">
                <td> {{itemempsub.dateTime}}</td>
                <td> {{itemempsub.hour}}h {{itemempsub.minute}}min {{itemempsub.second}}sec</td>
                <td> {{itemempsub.projectName}}</td>
                <td> {{itemempsub.work_desc}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
        </fieldset>

    </div>
</div>
</div>




















       <div class="g-w3field r-grid12-6"> 
            <div class="right p-text" id="content">
              <p-button (click)="Screen()"  label="Descargar informe en PDF"></p-button>
              &nbsp; &nbsp;
              <p-button (click)="exportAsExcel()"  label="Exportar como Excel"></p-button>
            </div>
          </div>

          <!-- <div class="g-w4field">
            <div class="f-box f-jcsb">
                <div class="f-w1" style="margin-top:10px"><p-button  (onClick)="exportAsExcel()">Exportar como Excel</p-button></div>
            </div>
        </div> -->



<div *ngIf="pagetype=='projectdetail'"  id="projectdetail" #excelTable>

   


  <div *ngFor="let allclientproject of allclientprojectemployee; let i = index;">

    <div *ngIf="i=='0'">
  <div class="grid r-grid12-6">

    <div class="g-w3field">
      <strong>Proyecto</strong> : {{allclientproject.project_name}}
    </div> 

    <div class="g-w3field">
      <strong>Fecha inicio</strong> : {{allclientproject.startDate}}
    </div> 

    <div class="g-w3field">
      <strong>Fecha fin</strong> : {{allclientproject.endDate}}
    </div> 
             
   

  </div>



  <div class="grid r-grid12-6">

     
             
    <div class="g-w3field">
      <strong>Total horas</strong> : {{allclientproject.total_hour}}
    </div>        
    <div class="g-w3field">
      <strong>Tiempo trabajado</strong> : {{allclientproject.worked_hour}}
    </div>
    <div class="g-w3field">
      <strong>Tiempo restante</strong> : {{allclientproject.remaining_hour}}
    </div>

    
  </div>

 </div>
</div>





<div   class="p-grid p-justify-start" *ngFor="let itememp of allclientprojectemployee">


  
  
  <div class="p-col-12 p-md-12 p-lg-12">



  
  <fieldset>
      <div class="p-grid">
          <div class="p-col-12">
  <legend><strong> Trabajador: </strong> {{itememp.employeeName}} </legend>
  <table class="clientprojectdetail-table" >
      <thead>
        <th>Fecha</th>
        <th>Horas trabajadas</th>
        <!-- <th>Hora/s Minuto/s Second/s</th> -->
        <!-- <th>Proyecto</th> --> 
        <th>Descripcion</th>
      </thead>
      <tbody>
        <tr *ngFor="let itemempsub of itememp.mainDetail ">
          <td> {{itemempsub.dateTime}}</td>
          <td> {{itemempsub.hour}}h {{itemempsub.minute}}min {{itemempsub.second}}sec</td>
          <!-- <td> {{itemempsub.hour}}h {{itemempsub.minute}}min {{itemempsub.second}}sec</td>
          <td> {{itemempsub.projectName}}</td> -->
          <td> {{itemempsub.work_desc}}</td>
        </tr>

        <tr >
          <td colspan="3"> <b>Horas totales trabajadas</b> : {{itememp.mainTotalHour}}</td>
        </tr>

      </tbody>
    </table>
  </div>
  </div>
  </fieldset>

</div>
</div>
</div>

































<p-toast position='center'></p-toast>

<!-- <p-button (click)="Screen()" icon="pi pi-info-circle" label="Show"></p-button> -->