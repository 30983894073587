
<div class="complaints-header">
    <div class="center">
    </div>
    <div class="right">
        <div style=" display:flex;  flex-direction: row;">
            <p-dropdown
                [options]="yearArray"
                placeholder="Seleccionar año"
                optionLabel="year"
                [showClear]="true"
                [(ngModel)]="selectedYear"
                [style]="{'font-size':'10px'}"
                #dropDownThing
            >
            </p-dropdown>
            <button class="button" (click)="searchBasedOnYear()" style="margin-left: 4px; cursor: pointer;">Buscar</button>
        </div>
    </div>
</div>
<div class="example-column">
    <p-table [value]="clientdepartments" selectionMode="single" [(selection)]="selectedClientdepartment"
        [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
        currentPageReportTemplate="Mostrando {first} de {totalPages}" (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)">
        <ng-template pTemplate="header">
            <tr>
                <th>Empresa</th>
                <th>Nombre del departamento</th>
                <th>Email del departamento</th>
               
                <th width="10%">Acción</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-clientdepartment>
            <tr [pSelectableRow]="clientdepartment">
                
                <td>{{clientdepartment.clientName}} </td>
                <td>{{clientdepartment.department_name}}</td>
                <td>{{clientdepartment.department_email}}</td>
                
                <td>
                    <button title='Ver detalle' pButton pRipple type="button" icon="fas fa-eye" class="p-button-outlined"
                    (click)="selectClientdepartment(clientdepartment.idClient_department)"></button>
                    &nbsp;
                    <button title='Delete' pButton pRipple type="button" icon="fas fa-close" class="p-button-outlined"
                    (click)="deleteClientdepartment(clientdepartment.idClient_department,clientdepartment.department_name)">X</button>
                </td>
            </tr>
        </ng-template>
    </p-table>    
</div>

<div class="grid r-grid r-grid12-6" style="width:'100%';">
    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w1"><p-button label="Nuevo departamento" (click)="nueva()" icon="fas fa-paper-plane" iconPos="left"></p-button></div>
            <br>
            <br>
            <br>
            <br>
            <br>
        </div>
    </div>
</div>
