<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Saldos horarios</p>
    <div class="grid filtro r-grid r-grid12-12-6">
        <div class="g-w4field">
            <small>Ejercicio:</small>
            <p-dropdown [autoDisplayFirst]="true" [showClear]="false" [options]="gds.ejercicios" [(ngModel)]="ejercicio" (onChange)="loadEmpleados();"></p-dropdown>
        </div>
        <div class="g-w4field">
            <small>Mes:</small>
            <p-dropdown  [autoDisplayFirst]="true" [showClear]="false" [options]="gds.meses" [(ngModel)]="mes" (onChange)="loadEmpleados();"></p-dropdown>
        </div>
        <!-- <div class="g-w4field">
            <div class="f-box f-jcsb">
                <div class="f-w1" style="margin-top:10px"><p-button  (onClick)="loadPermisos()">Informe Asistencia Global</p-button></div>
            </div>
        </div> -->
        <div class="g-w4field">
            <div class="f-box f-jcsb">
                <div class="f-w1" style="margin-top:10px"><p-button [disabled]="selectedEmpleados.length<1" (onClick)="informeHorario()">Obtener informe horario</p-button></div>
            </div>
        </div>
   
    
<div class="g-w12field">
<p-table [value]="empleados" [(selection)]="selectedEmpleados" (click)="click_tabla($event)">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem">
                <p-tableHeaderCheckbox #check (click)="empleadoSelectionSelectAll(empleados,check)"></p-tableHeaderCheckbox>
            </th>
            <th>Empleado</th> 
            <th>Saldo Horario (Horas:minutos)</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-emp>
    
        <tr>
            <td><p-tableCheckbox #singlecheck [value]="emp" (click)="empleadoSelection(emp,singlecheck)"></p-tableCheckbox></td>
            <td [attr.data-id]="emp.idEmpleado">{{emp.empleado}} <i [attr.data-id]="emp.idEmpleado" class="fas fa-eye"></i>
            </td>
            <td>{{emp.saldo}}</td>
        </tr>
    </ng-template>
</p-table>
</div>
</div>

<p-toast position='center'></p-toast>