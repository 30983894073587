import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { Respuesta } from '../../respuesta';
import {formatDate} from '@angular/common';
import {MessageService} from 'primeng/api';
import {Utilidades} from '../../libs/utilidades';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { Router } from '@angular/router';
declare let jsPDF;

export interface Empleado{
  value:number;
  empleado:string;
}

@Component({
  selector: 'app-consultapermisos',
  templateUrl: './consultapermisos.component.html',
  styleUrls: ['./consultapermisos.component.scss']
})

export class ConsultapermisosComponent implements OnInit {

  disfrutados:any[]=[];

  public selectedEmpleado:Empleado=null;
  public empleados:Empleado[]=[];
  
  //empleado:number=0;
  public ejercicio:number=this.gds.ejercicio_actual;
// --------------------horario component variables------------------------
ejercicioo: number;
mes: string = "01";
mesemp: string = "01";
empleadoss: any = [];
public selectedEmpleados: any = []; 
ClentName: any;
clientId: any;
month: string;
monthemp: string;
selectedEmployessForPdfDownload = [];
selectedEmployessForPdfDownloadId = [];
selectedEmployessForPdfDownloadEmp = [];
// --------------------horario component variables------------------------

  constructor(private messageService: MessageService,private httpClient: HttpClient,public gds:GlobaldataService,private router: Router) {
    this.gds.clientDetailsObservable.subscribe((data: any) => {
      this.ClentName = data.cliente, this.clientId = data.idCliente; console.log("client datatatat", data)
    
    })
   } 

  ngOnInit(): void {
    this.filtrarEj();
    this.ejercicioo = this.gds.ejercicio_actual;
    this.mes = this.gds.mes_actual;
    console.log("mes...", this.mes)
    console.log("mes...", this.gds.meses)

    this.loadEmpleados();
  }

  loadPermisos()
  {
    if(this.selectedEmpleado==null) {this.disfrutados=[];return;}
    let url2='solicitudes?'
    +'&fields=idtipopermiso,solicitud,fechainicio,fechafin,horainicio,horafin,fechas,estado,doc'
    +'&where=idEmpleado='+this.selectedEmpleado.value+" and idTipoPermiso!=0  and estado!='SOLICITUD'and year(fechainicio)='"+this.ejercicio+"'"
    +'&order=solicitud,fechainicio DESC'
    +'&tree=solicitud->permisos=>fechainicio,fechafin,horainicio,horafin,fechas,estado,doc';


    console.log("url............permiso",url2)
    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
    console.log("url............ r.resp", r.resp)

      for(let p of r.resp)
      {
          let dias=0;
          for(let d of p.permisos)
          {
            let cd=d.fechas.split(",").length;
            d['dias']=cd; 
            if (cd>1)
              d['periodo']=formatDate(d.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(d.fechafin,'dd/MM/yyyy','en-US');
            else
              d['periodo']=formatDate(d.fechainicio,'dd/MM/yyyy','en-US');

            if(d.horainicio!=null && d.horafin!=null)
            {
              d['periodo']+=" "+d.horainicio.substr(0,5)+"-"+d.horafin.substr(0,5);
            }
            //if(d.estado!="RECHAZADA") dias+=cd;
            if(d.estado!="RECHAZADA" && d.estado!="CANCELADO")
            {
              dias+=cd;  
            }
            
           // d['document']=d.fechas;

          }
          p['dias']=dias; 
          
      }
      console.log("ccccccccccccc");
      console.log(r.resp);
      this.disfrutados=r.resp;
    });
  }

  public filtrarEj()
  {

    //alert(this.gds.id_cliente);
	  let old_emp=this.selectedEmpleado;
		let url="fields=E.idempleado as value,T.empleado,S.idsolicitud";
		url=url+"&joins=trabajador,solicitudes(E.idempleado=>S.idempleado)";
		// url=url+"&where=E.idcliente="+this.gds.id_cliente+" and year(S.fechainicio)='"+this.ejercicio+"'";
    url=url+"&where=E.baja='0' and E.idcliente="+this.gds.id_cliente+" and year(S.fechainicio)='"+this.ejercicio+"'";
		url=url+"&groupby=E.idempleado,T.empleado"
		url=url+"&order=T.empleado";
    console.log("url.................",url);
		this.httpClient.get<Respuesta>(this.gds.urlBaseDL+"empleado?"+url).subscribe((res)=>{
      console.log("data.......",res.resp);
			this.empleados=res.resp;      
      if(old_emp!=null)
      {
        let enc=this.empleados.find(x=>x.value==old_emp.value);
        if (enc!=undefined) this.selectedEmpleado=enc; else this.selectedEmpleado=null;
      }
      if(this.selectedEmpleado==null && this.empleados.length>0) this.selectedEmpleado=this.empleados[0]; 
			this.loadPermisos();
		});
  }

  showfechas(fecha)
  {
    return Utilidades.replaceAll(fecha,",",", ");
  }
// ---------------------------------------horario code--------------------------


showHorarioDiario(idEmpleado) {
this.router.navigate(['main/hdiario', idEmpleado, this.ejercicioo, this.mes]);
}
public click_tabla(e) {
  if ('data-id' in e.target.attributes) this.showHorarioDiario(e.target.attributes["data-id"].value);
}

public loadEmpleados() {
  // let url2 = 'empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
  //   + "&fields=E.idEmpleado,T.empleado,time_format(sec_to_time(sum(timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo"
  //   + '&where=E.baja in(0,1) and (H.tipo="" or H.tipo="visita") and  E.idCliente=' + this.gds.id_cliente + " and year(H.inicio)=" + this.ejercicioo + " and month(H.inicio)=" + this.mes
  //   + '&groupby=T.empleado'
  //   + '&order=T.empleado';


  let url2 = 'empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
    + "&fields=E.idEmpleado,T.empleado,time_format(sec_to_time(sum(timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo"
    + '&where=E.baja in(0) and (H.tipo="" or H.tipo="visita") and  E.idCliente=' + this.gds.id_cliente + " and year(H.inicio)=" + this.ejercicioo + " and month(H.inicio)=" + this.mes
    + '&groupby=T.empleado'
    + '&order=T.empleado';

  console.log(this.gds.urlBaseDL + url2); 
  console.log("called in load employeee")
  return this.httpClient.get(this.gds.urlBaseDL + url2).toPromise().then((r) => {
    this.empleadoss = r['resp'];
    console.log(this.empleadoss);
  });
}

informeHorario() {
  //if(!this.selectedEmpleados.length) return;
  let ids = this.selectedEmpleados.map((e) => { return e.idEmpleado });
  this.messageService.add({ severity: 'info', summary: 'Informe horario', detail: 'En breve recibirá el informe solicitado por email!' });
  return this.httpClient.post(this.gds.urlBaseBL + "reportHorario", { ids: ids, mes: this.mes, ej: this.ejercicioo, idCliente: this.gds.id_cliente }).toPromise().then((r) => {
    console.log(r);
  });
}

loadPermisossEmp() {
  let date = this.ejercicio


let d = new Date();
let mon = d.getMonth()+1;
let textmon = mon.toString();
let mesemp = textmon.padStart(2,'0');
this.mesemp=mesemp;

console.log("ooooooooooooooooooo",this.selectedEmpleado);
console.log(this.selectedEmpleado.empleado);
console.log(this.selectedEmpleado.value);
if (this.selectedEmployessForPdfDownloadEmp.length > 0) {
  this.selectedEmployessForPdfDownloadEmp.pop();
}


this.selectedEmployessForPdfDownloadEmp.push(this.selectedEmpleado.empleado);



  console.log("Date: "+this.ejercicio+" Client:  "+this.clientId+" monthemp:  "+this.mesemp +" Client:  "+this.clientId  );

  console.log("sel emp gg ",this.selectedEmployessForPdfDownloadEmp);
  console.log("sel emp ",this.selectedEmployessForPdfDownload);

  this.ejercicio
  let data = {
    idCliente: this.clientId,
    fechainicio: this.ejercicio,
    month: this.mesemp,
    empleado: this.selectedEmpleado.value
  }
  
  const monthemp = [
    {
      "label": "Enero",
      "value": "01"
    },
    {
      "label": "Febrero",
      "value": "02"
    },
    {
      "label": "Marzo",
      "value": "03"
    },
    {
      "label": "Abril",
      "value": "04"
    },
    {
      "label": "Mayo",
      "value": "05"
    },
    {
      "label": "Junio",
      "value": "06"
    },
    {
      "label": "Julio",
      "value": "07"
    },
    {
      "label": "Agosto",
      "value": "08"
    },
    {
      "label": "Septiembre",
      "value": "09"
    },
    {
      "label": "Octubre",
      "value": "10"
    },
    {
      "label": "Noviembre", 
      "value": "11"
    },
    {
      "label": "Diciembre",
      "value": "12"
    }
  ].filter((data) => data.value == this.mesemp).map(data => data.label).toString();
  this.monthemp = monthemp
  console.log("month", monthemp)
  console.log("in report call")
  this.gds.downloadLeaveReportEmp(data).subscribe((res: any) => {
    console.log(res)
   res= res.map(d=> {return {
      "employeeName": d.employeeName, 
      // "totalLeave": "totalLeave",
      "Asistencia_al_médico":d['Asistencia al médico'] ? d['Asistencia al médico']: 0,
      "Vacaciones":d['Vacaciones']?d['Vacaciones']: 0,
      "Enfermedad": d['Enfermedad']?d['Enfermedad']:0,
      "Libre_disposición":d['Libre disposición']?d['Libre disposición']: 0,
      "Nacimiento_de_hijo":d['Nacimiento de hijo']?d['Nacimiento de hijo']: 0,
      "Matrimonio":d['Matrimonio']?d['Matrimonio']:0,
      "Fallecimiento_familiar": d['Fallecimiento familiar']?d['Fallecimiento familiar']:0,
      "Realización_de_examen_curso":d['Realización de examen, curso']?d['Realización de examen, curso']: 0,
      "Excedencia_por_conciliación":d['Excedencia por conciliación']?d['Excedencia por conciliación']: 0,
      "Excedencia_voluntaria":d['Excedencia voluntaria']?d['Excedencia voluntaria']: 0,
      "Baja_de_la_empresa":d['Baja de la empresa']?d['Baja de la empresa']:0,
      "Cambio_de_jornada":d['Cambio de jornada']?d['Cambio de jornada']:0
    }})
    console.log("resss...",res)
    let res1=[...res]
    console.log("this.selectedEmployessForPdfDownload", this.selectedEmployessForPdfDownloadEmp)
    if (this.selectedEmployessForPdfDownloadEmp.length > 0 && res.length>this.selectedEmployessForPdfDownloadEmp.length) {
      res = res.map(data => {
        if (this.selectedEmployessForPdfDownloadEmp.includes(data.employeeName)) {
          console.log("in if")
         console.log("data man1",data);
         console.log("data['Asistencia al médico']",data['Asistencia al médico']?data['Asistencia al médico']: 0);
          return data
        } else {
          console.log("in else")
          return this.selectedEmployessForPdfDownloadEmp.map((data,i) => {
            let c=i+1
            console.log("c",c)
              return {
                "employeeName": data,
                // "totalLeave": "totalLeave",
                "Asistencia_al_médico": 0,
                "Vacaciones": 0,
                "Enfermedad": 0,
                "Libre_disposición": 0,
                "Nacimiento_de_hijo": 0,
                "Matrimonio": 0,
                "Fallecimiento_familiar": 0,
                "Realización_de_examen_curso": 0,
                "Excedencia_por_conciliación": 0,
                "Excedencia_voluntaria": 0,
                "Baja_de_la_empresa": 0,
                "Cambio_de_jornada": 0
              }
          })
        }
      }).flat();
    }else if(this.selectedEmployessForPdfDownloadEmp.length >res.length){
      this.selectedEmployessForPdfDownloadEmp.forEach(data=>{
        let obj={
          "employeeName": data,
          // "totalLeave": "totalLeave",
          "Asistencia_al_médico": 0,
          "Vacaciones": 0,
          "Enfermedad": 0,
          "Nacimiento_de_hijo": 0,
          "Libre_disposición": 0,
          "Matrimonio": 0,
          "Fallecimiento_familiar": 0,
          "Realización_de_examen_curso": 0,
          "Excedencia_por_conciliación": 0,
          "Excedencia_voluntaria": 0,
          "Baja_de_la_empresa": 0,
          "Cambio_de_jornada": 0
        }
           res.push(obj)
      })
    }
    
    console.log("cccc", res)
    console.log("cccc1", res1)
    let k;
    if(res.length>this.selectedEmployessForPdfDownloadEmp.length){
     res=res.filter((a, i) => res.findIndex((s) => a.employeeName === s.employeeName) === i) 
      console.log("converted", res)
    }
    else{
    res=res.filter((a, i) =>{
      
      let p=  res1.filter((s) =>JSON.stringify(a).localeCompare(JSON.stringify(s)) ==0)
      if(p.length>0){
       return p
      }
      console.log("pppp",p)
       }  )
  }

   
//     {
// console.log("JSON.stringify(a)",JSON.stringify(a))
// console.log("JSON.stringify(s)",JSON.stringify(s))
// if(JSON.stringify(a).localeCompare(JSON.stringify(s)) ==0 ){
//   console.log("ss",s)
// return s

// }
    
    // }
  
      
    console.log("cccc", res)

    let doc = new jsPDF('l', 'pt', 'a4');


    console.log("resssssssssssssssssss",res);
    //console.log("JSON.stringify(resssssssssssssssssss)",JSON.stringify(res));

    let arrlist=[];
    let Asistencia_al_médico_tot=0;
    let Vacaciones_tot=0;
    let Enfermedad_tot=0;
    let Libre_disposición_tot=0;
    let Matrimonio_tot=0;
    let Nacimiento_de_hijo_tot=0;
    let Fallecimiento_familiar_tot=0;
    let Realización_de_examen_curso_tot=0;
    let Excedencia_por_conciliación_tot=0;
    let Excedencia_voluntaria_tot=0;
    let Baja_de_la_empresa_tot=0;
    let Cambio_de_jornada_tot=0;


    let grand_tot=0;

    for (let y=0;y<res.length;y++)
    {
      let list = {};
      //let employeeName=res[y].employeeName;
      list["employeeName"] = res[y].employeeName;
      list["Asistencia_al_médico"] = res[y].Asistencia_al_médico;
      list["Vacaciones"] = res[y].Vacaciones;
      list["Enfermedad"] = res[y].Enfermedad;
      list["Libre_disposición"] = res[y].Libre_disposición;
      list["Matrimonio"] = res[y].Matrimonio;
      list["Nacimiento_de_hijo"] = res[y].Nacimiento_de_hijo;
      list["Fallecimiento_familiar"] = res[y].Fallecimiento_familiar;
      list["Realización_de_examen_curso"] = res[y].Realización_de_examen_curso
      ;
      list["Excedencia_por_conciliación"] = res[y].Excedencia_por_conciliación;
      list["Excedencia_voluntaria"] = res[y].Excedencia_voluntaria;
      list["Baja_de_la_empresa"] = res[y].Baja_de_la_empresa;
      list["Cambio_de_jornada"] = res[y].Cambio_de_jornada;

      
      Asistencia_al_médico_tot=Asistencia_al_médico_tot+parseInt(res[y].Asistencia_al_médico);

      Vacaciones_tot=Vacaciones_tot+parseInt(res[y].Vacaciones);

      Enfermedad_tot=Enfermedad_tot+parseInt(res[y].Enfermedad);

      Libre_disposición_tot=Libre_disposición_tot+parseInt(res[y].Libre_disposición);

      Matrimonio_tot=Matrimonio_tot+parseInt(res[y].Matrimonio);

      Nacimiento_de_hijo_tot=Nacimiento_de_hijo_tot+parseInt(res[y].Nacimiento_de_hijo);

      Fallecimiento_familiar_tot=Fallecimiento_familiar_tot+parseInt(res[y].Fallecimiento_familiar);

      Realización_de_examen_curso_tot=Realización_de_examen_curso_tot+parseInt(res[y].Realización_de_examen_curso);

      Excedencia_por_conciliación_tot=Excedencia_por_conciliación_tot+parseInt(res[y].Excedencia_por_conciliación);

      Excedencia_voluntaria_tot=Excedencia_voluntaria_tot+parseInt(res[y].Excedencia_voluntaria);

      Baja_de_la_empresa_tot=Baja_de_la_empresa_tot+parseInt(res[y].Baja_de_la_empresa);

      Cambio_de_jornada_tot=Cambio_de_jornada_tot+parseInt(res[y].Cambio_de_jornada);

      let mainTotal=parseInt(res[y].Asistencia_al_médico)+parseInt(res[y].Vacaciones)+parseInt(res[y].Enfermedad)+parseInt(res[y].Libre_disposición)+parseInt(res[y].Matrimonio)+parseInt(res[y].Nacimiento_de_hijo)+parseInt(res[y].Fallecimiento_familiar)+parseInt(res[y].Realización_de_examen_curso)+parseInt(res[y].Excedencia_por_conciliación)+parseInt(res[y].Excedencia_voluntaria)+parseInt(res[y].Baja_de_la_empresa)+parseInt(res[y].Cambio_de_jornada);


      grand_tot=grand_tot+mainTotal;

     
      
     
      
      arrlist.push(list);

    }



    let listTot = {};
      //let employeeName=res[y].employeeName;
      listTot["employeeName"] = "Total";
      listTot["Asistencia_al_médico"] = Asistencia_al_médico_tot;
      listTot["Vacaciones"] = Vacaciones_tot;
      listTot["Enfermedad"] = Enfermedad_tot;
      listTot["Libre_disposición"] = Libre_disposición_tot;
      listTot["Matrimonio"] = Matrimonio_tot;
      listTot["Nacimiento_de_hijo"] = Nacimiento_de_hijo_tot;
      listTot["Fallecimiento_familiar"] = Fallecimiento_familiar_tot;
      listTot["Realización_de_examen_curso"] = Realización_de_examen_curso_tot;
      ;
      listTot["Excedencia_por_conciliación"] = Excedencia_por_conciliación_tot;
      listTot["Excedencia_voluntaria"] = Excedencia_voluntaria_tot;
      listTot["Baja_de_la_empresa"] = Baja_de_la_empresa_tot;
      listTot["Cambio_de_jornada"] =Cambio_de_jornada_tot;

    

     arrlist.push(listTot);

      let listTotAll = {};
      //let employeeName=res[y].employeeName;
      listTotAll["employeeName"] = "Computo total permisos";
      listTotAll["Asistencia_al_médico"] = grand_tot;
      listTotAll["Vacaciones"] = "";
      listTotAll["Enfermedad"] = "";
      listTotAll["Libre_disposición"] = "";
      listTotAll["Matrimonio"] = "";
      listTotAll["Nacimiento_de_hijo"] = "";
      listTotAll["Fallecimiento_familiar"] = "";
      listTotAll["Realización_de_examen_curso"] = "";
      ;
      listTotAll["Excedencia_por_conciliación"] = "";
      listTotAll["Excedencia_voluntaria"] = "";
      listTotAll["Baja_de_la_empresa"] = "";
      listTotAll["Cambio_de_jornada"] ="";
     
      arrlist.push(listTotAll);


    console.log("arrrrrrrrrrrrrrrrrrrrr",arrlist);

    let columns = [
      { title: "Empleado", dataKey: "employeeName" },
      // { title: "totalLeave", dataKey: "totalLeave" },
      { title: "Asistencia al médico", dataKey: "Asistencia_al_médico" },
      { title: "Vacaciones", dataKey: "Vacaciones" },
      { title: "Enfermedad", dataKey: "Enfermedad" },
      { title: "Libre disposición", dataKey: "Libre_disposición" },
      { title: "Matrimonio", dataKey: "Matrimonio" },
      { title: "Nacimiento de hijon", dataKey: "Nacimiento_de_hijo" },
      { title: "Fallecimiento familiar", dataKey: "Fallecimiento_familiar" },
      { title: "Realización de examen, curso", dataKey: "Realización_de_examen_curso" },
      { title: "Excedencia por conciliación", dataKey: "Excedencia_por_conciliación" },
      { title: "Excedencia voluntaria", dataKey: "Excedencia_voluntaria" },
      { title: "Baja de la empresa", dataKey: "Baja_de_la_empresa" },
      { title: "Cambio de jornada", dataKey: "Cambio_de_jornada" },
 ];

 let columnsArr = [];

 let columnsemployeeNameArr = {};
 columnsemployeeNameArr["title"] = "Empleado";
 columnsemployeeNameArr["dataKey"] = "employeeName";
 columnsArr.push(columnsemployeeNameArr);

 if(Asistencia_al_médico_tot!=0)
 {
  let columnsAsistencia_al_médicoArr = {};
  columnsAsistencia_al_médicoArr["title"] = "Asistencia al médico";
  columnsAsistencia_al_médicoArr["dataKey"] = "Asistencia_al_médico";
  columnsArr.push(columnsAsistencia_al_médicoArr);
 }



 if(Vacaciones_tot!=0)
 {
  let columnsVacacionesArr = {};
  columnsVacacionesArr["title"] = "Vacaciones";
  columnsVacacionesArr["dataKey"] = "Vacaciones";
  columnsArr.push(columnsVacacionesArr); 
 }

 if(Enfermedad_tot!=0)
 {
  let columnsEnfermedadArr = {};
  columnsEnfermedadArr["title"] = "Enfermedad";
  columnsEnfermedadArr["dataKey"] = "Enfermedad";
  columnsArr.push(columnsEnfermedadArr); 
 }

 if(Libre_disposición_tot!=0)
 {
  let columnsLibre_disposiciónArr = {};
  columnsLibre_disposiciónArr["title"] = "Libre disposición";
  columnsLibre_disposiciónArr["dataKey"] = "Libre_disposición";
  columnsArr.push(columnsLibre_disposiciónArr);
 }

 if(Matrimonio_tot!=0)
 {
  let columnsMatrimonioArr = {};
  columnsMatrimonioArr["title"] = "Matrimonio";
  columnsMatrimonioArr["dataKey"] = "Matrimonio";
  columnsArr.push(columnsMatrimonioArr); 
 }

 if(Nacimiento_de_hijo_tot!=0)
 {
  let columnsNacimiento_de_hijoArr = {};
  columnsNacimiento_de_hijoArr["title"] = "Nacimiento de hijon";
  columnsNacimiento_de_hijoArr["dataKey"] = "Nacimiento_de_hijo";
  columnsArr.push(columnsNacimiento_de_hijoArr); 
 }

 if(Fallecimiento_familiar_tot!=0)
 {
  let columnsFallecimiento_familiarArr = {};
  columnsFallecimiento_familiarArr["title"] = "Fallecimiento familiar";
  columnsFallecimiento_familiarArr["dataKey"] = "Fallecimiento_familiar";
  columnsArr.push(columnsFallecimiento_familiarArr);
 }

 if(Realización_de_examen_curso_tot!=0)
 {
  let columnsRealización_de_examen_cursoArr = {};
  columnsRealización_de_examen_cursoArr["title"] = "Realización de examen, curso";
  columnsRealización_de_examen_cursoArr["dataKey"] = "Realización_de_examen_curso";
  columnsArr.push(columnsRealización_de_examen_cursoArr);
 }

 if(Excedencia_por_conciliación_tot!=0)
 {
  let columnsExcedencia_por_conciliaciónArr = {};
  columnsExcedencia_por_conciliaciónArr["title"] = "Excedencia por conciliación";
  columnsExcedencia_por_conciliaciónArr["dataKey"] = "Excedencia_por_conciliación";
  columnsArr.push(columnsExcedencia_por_conciliaciónArr);
 }

 if(Excedencia_voluntaria_tot!=0)
 {
  let columnsExcedencia_voluntariaArr = {};
  columnsExcedencia_voluntariaArr["title"] = "Excedencia voluntaria";
  columnsExcedencia_voluntariaArr["dataKey"] = "Excedencia_voluntaria";
  columnsArr.push(columnsExcedencia_voluntariaArr);
 }

 if(Baja_de_la_empresa_tot!=0)
 {
  let columnsBaja_de_la_empresaArr = {};
  columnsBaja_de_la_empresaArr["title"] = "Baja de la empresa";
  columnsBaja_de_la_empresaArr["dataKey"] = "Baja_de_la_empresa";
  columnsArr.push(columnsBaja_de_la_empresaArr);
 }

 if(Cambio_de_jornada_tot!=0)
 {
  let columnsCambio_de_jornadaArr = {};
  columnsCambio_de_jornadaArr["title"] = "Cambio de jornada";
  columnsCambio_de_jornadaArr["dataKey"] = "Cambio_de_jornada";
  columnsArr.push(columnsCambio_de_jornadaArr);
 }












    let clintName = this.ClentName;
    let width = doc.internal.pageSize.getWidth()
    doc.autoTable(columnsArr, arrlist, {
      margin: { top: 100 },
      addPageContent: function (data) {
        doc.setFontSize(9);

        // doc.text(`INFORME DE ASISTENCIA SOLICITADO Y GENERADO HASTA ${monthemp.toUpperCase()}/${date}`, width / 2, 20, { align: 'center' });
        doc.text(`INFORME DE ASISTENCIA SOLICITADO Y GENERADO HASTA ${date}`, width / 2, 20, { align: 'center' });
        doc.text(`EMPRESA:       ${clintName}`, 40, 60);
        //doc.text(`INFORME GENERADO EL: ${new Date().toLocaleDateString()}`, 40, 80);
        //doc.text(`INFORME GENERADO EL: ${new Date().getDate()}`, 40, 80);
        //doc.text(`INFORME GENERADO EL: ${new Date().toLocaleString()}`, 40, 80);
        doc.text(`INFORME GENERADO EL: ${new Date().toLocaleString('es-ES', {hour12: false})}`, 40, 80);
      }
    });

    doc.save(`${date}-${this.selectedEmpleado.empleado.toUpperCase()}.pdf`);
  })
}
loadPermisoss() {
  let date = this.ejercicioo 


  console.log("fokoooooooooooooooo",this.selectedEmployessForPdfDownloadId);

  //alert(this.selectedEmployessForPdfDownloadId.toString());
//alert(this.selectedEmployessForPdfDownload.toString());
  this.ejercicioo
  let data = {
    idCliente: this.clientId,
    fechainicio: this.ejercicioo,
    month: this.mes,
    empleado :this.selectedEmployessForPdfDownloadId.toString()
  }
  const month = [
    {
      "label": "Enero",
      "value": "01"
    },
    {
      "label": "Febrero",
      "value": "02"
    },
    {
      "label": "Marzo",
      "value": "03"
    },
    {
      "label": "Abril",
      "value": "04"
    },
    {
      "label": "Mayo",
      "value": "05"
    },
    {
      "label": "Junio",
      "value": "06"
    },
    {
      "label": "Julio",
      "value": "07"
    },
    {
      "label": "Agosto",
      "value": "08"
    },
    {
      "label": "Septiembre",
      "value": "09"
    },
    {
      "label": "Octubre",
      "value": "10"
    },
    {
      "label": "Noviembre",
      "value": "11"
    },
    {
      "label": "Diciembre",
      "value": "12"
    }
  ].filter((data) => data.value == this.mes).map(data => data.label).toString();
  this.month = month
  console.log("month", month)
  console.log("in report call")


  this.gds.downloadLeaveReport(data).subscribe((res: any) => {
    console.log(res)
   res= res.map(d=> {return {
      "employeeName": d.employeeName,
      // "totalLeave": "totalLeave",
      "Asistencia_al_médico":d['Asistencia al médico'] ? d['Asistencia al médico']: 0,
      "Vacaciones":d['Vacaciones']?d['Vacaciones']: 0,
      "Enfermedad": d['Enfermedad']?d['Enfermedad']:0,
      "Libre_disposición":d['Libre disposición']?d['Libre disposición']: 0,
      "Nacimiento_de_hijo":d['Nacimiento de hijo']?d['Nacimiento de hijo']: 0,
      "Matrimonio":d['Matrimonio']?d['Matrimonio']:0,
      "Fallecimiento_familiar": d['Fallecimiento familiar']?d['Fallecimiento familiar']:0,
      "Realización_de_examen_curso":d['Realización de examen, curso']?d['Realización de examen, curso']: 0,
      "Excedencia_por_conciliación":d['Excedencia por conciliación']?d['Excedencia por conciliación']: 0,
      "Excedencia_voluntaria":d['Excedencia voluntaria']?d['Excedencia voluntaria']: 0,
      "Baja_de_la_empresa":d['Baja de la empresa']?d['Baja de la empresa']:0,
      "Cambio_de_jornada":d['Cambio de jornada']?d['Cambio de jornada']:0
    }})
    console.log("resss...",res)
    let res1=[...res]

    console.log("resss11...",res1)
    console.log("this.selectedEmployessForPdfDownload", this.selectedEmployessForPdfDownload)
    if (this.selectedEmployessForPdfDownload.length > 0 && res.length>this.selectedEmployessForPdfDownload.length) {

      console.log("aaa");
      res = res.map(data => {

        //alert(data.employeeName);
        console.log("sssssssssssss...",this.selectedEmployessForPdfDownload)
        console.log("ddddddddddd...",data.employeeName)
        if (this.selectedEmployessForPdfDownload.includes(data.employeeName)) {
          console.log("bbb");
          console.log("in if")
         console.log("data man2",data);
         console.log("data['Asistencia al médico']",data['Asistencia al médico']?data['Asistencia al médico']: 0);
          return data
        } else {

          console.log("ccc");
          console.log("in else")
          return this.selectedEmployessForPdfDownload.map((data,i) => {
            let c=i+1
            console.log("c",c)
              return {
                "employeeName": data,
                // "totalLeave": "totalLeave",
                "Asistencia_al_médico": 0,
                "Vacaciones": 0,
                "Enfermedad": 0,
                "Libre_disposición": 0,
                "Nacimiento_de_hijo": 0,
                "Matrimonio": 0,
                "Fallecimiento_familiar": 0,
                "Realización_de_examen_curso": 0,
                "Excedencia_por_conciliación": 0,
                "Excedencia_voluntaria": 0,
                "Baja_de_la_empresa": 0,
                "Cambio_de_jornada": 0
              }
          })
        }
      }).flat();
    }else if(this.selectedEmployessForPdfDownload.length >res.length){

      console.log("ddd");
      this.selectedEmployessForPdfDownload.forEach(data=>{
        let obj={
          "employeeName": data,
          // "totalLeave": "totalLeave",
          "Asistencia_al_médico": 0,
          "Vacaciones": 0,
          "Enfermedad": 0,
          "Nacimiento_de_hijo": 0,
          "Libre_disposición": 0,
          "Matrimonio": 0,
          "Fallecimiento_familiar": 0,
          "Realización_de_examen_curso": 0,
          "Excedencia_por_conciliación": 0,
          "Excedencia_voluntaria": 0,
          "Baja_de_la_empresa": 0,
          "Cambio_de_jornada": 0
        }
           res.push(obj)
      })
    }
    
    console.log("cccc", res)
    console.log("cccc1", res1)
    console.log("sssssssssssss...",this.selectedEmployessForPdfDownload)
    console.log("sssssssssssss...length",this.selectedEmployessForPdfDownload.length)

//     var arr1 = [1,2,3,4],
//     arr2 = [2,4],
//     res = arr1.filter(item => !arr2.includes(item));
// console.log(res);


if(this.selectedEmployessForPdfDownload.length!= 0){
var arr1 = res1,
arr2 = this.selectedEmployessForPdfDownload,
resp = arr1.filter(item => arr2.includes(item.employeeName));
console.log("mmmmmmmmmmmmmmmmm",resp);
res=resp;
}

   
    let k;
    if(res.length>this.selectedEmployessForPdfDownload.length){
      //alert("eee");
     res=res.filter((a, i) => res.findIndex((s) => a.employeeName === s.employeeName) === i) 
      console.log("converted", res)
    }
    else{
    res=res.filter((a, i) =>{
      //alert("fff");
      let p=  res1.filter((s) =>JSON.stringify(a).localeCompare(JSON.stringify(s)) ==0)
      if(p.length>0){
       return p
      }
      console.log("pppp",p)
       }  )
  }

   
//     {
// console.log("JSON.stringify(a)",JSON.stringify(a))
// console.log("JSON.stringify(s)",JSON.stringify(s))
// if(JSON.stringify(a).localeCompare(JSON.stringify(s)) ==0 ){
//   console.log("ss",s)
// return s

// }
    
    // }
  
      
    console.log("cccc", res)

    let doc = new jsPDF('l', 'pt', 'a4');


    console.log("resssssssssssssssssss",res);
    //console.log("JSON.stringify(resssssssssssssssssss)",JSON.stringify(res));

    let arrlist=[];
    let Asistencia_al_médico_tot=0;
    let Vacaciones_tot=0;
    let Enfermedad_tot=0;
    let Libre_disposición_tot=0;
    let Matrimonio_tot=0;
    let Nacimiento_de_hijo_tot=0;
    let Fallecimiento_familiar_tot=0;
    let Realización_de_examen_curso_tot=0;
    let Excedencia_por_conciliación_tot=0;
    let Excedencia_voluntaria_tot=0;
    let Baja_de_la_empresa_tot=0;
    let Cambio_de_jornada_tot=0;


    let grand_tot=0;

    for (let y=0;y<res.length;y++)
    {
      let list = {};
      //let employeeName=res[y].employeeName;
      list["employeeName"] = res[y].employeeName;
      list["Asistencia_al_médico"] = res[y].Asistencia_al_médico;
      list["Vacaciones"] = res[y].Vacaciones;
      list["Enfermedad"] = res[y].Enfermedad;
      list["Libre_disposición"] = res[y].Libre_disposición;
      list["Matrimonio"] = res[y].Matrimonio;
      list["Nacimiento_de_hijo"] = res[y].Nacimiento_de_hijo;
      list["Fallecimiento_familiar"] = res[y].Fallecimiento_familiar;
      list["Realización_de_examen_curso"] = res[y].Realización_de_examen_curso
      ;
      list["Excedencia_por_conciliación"] = res[y].Excedencia_por_conciliación;
      list["Excedencia_voluntaria"] = res[y].Excedencia_voluntaria;
      list["Baja_de_la_empresa"] = res[y].Baja_de_la_empresa;
      list["Cambio_de_jornada"] = res[y].Cambio_de_jornada;

      
      Asistencia_al_médico_tot=Asistencia_al_médico_tot+parseInt(res[y].Asistencia_al_médico);

      Vacaciones_tot=Vacaciones_tot+parseInt(res[y].Vacaciones);

      Enfermedad_tot=Enfermedad_tot+parseInt(res[y].Enfermedad);

      Libre_disposición_tot=Libre_disposición_tot+parseInt(res[y].Libre_disposición);

      Matrimonio_tot=Matrimonio_tot+parseInt(res[y].Matrimonio);

      Nacimiento_de_hijo_tot=Nacimiento_de_hijo_tot+parseInt(res[y].Nacimiento_de_hijo);

      Fallecimiento_familiar_tot=Fallecimiento_familiar_tot+parseInt(res[y].Fallecimiento_familiar);

      Realización_de_examen_curso_tot=Realización_de_examen_curso_tot+parseInt(res[y].Realización_de_examen_curso);

      Excedencia_por_conciliación_tot=Excedencia_por_conciliación_tot+parseInt(res[y].Excedencia_por_conciliación);

      Excedencia_voluntaria_tot=Excedencia_voluntaria_tot+parseInt(res[y].Excedencia_voluntaria);

      Baja_de_la_empresa_tot=Baja_de_la_empresa_tot+parseInt(res[y].Baja_de_la_empresa);

      Cambio_de_jornada_tot=Cambio_de_jornada_tot+parseInt(res[y].Cambio_de_jornada);

      let mainTotal=parseInt(res[y].Asistencia_al_médico)+parseInt(res[y].Vacaciones)+parseInt(res[y].Enfermedad)+parseInt(res[y].Libre_disposición)+parseInt(res[y].Matrimonio)+parseInt(res[y].Nacimiento_de_hijo)+parseInt(res[y].Fallecimiento_familiar)+parseInt(res[y].Realización_de_examen_curso)+parseInt(res[y].Excedencia_por_conciliación)+parseInt(res[y].Excedencia_voluntaria)+parseInt(res[y].Baja_de_la_empresa)+parseInt(res[y].Cambio_de_jornada);


      grand_tot=grand_tot+mainTotal;

     
      
     
      
      arrlist.push(list);

    }



    let listTot = {};
      //let employeeName=res[y].employeeName;
      listTot["employeeName"] = "Total";
      listTot["Asistencia_al_médico"] = Asistencia_al_médico_tot;
      listTot["Vacaciones"] = Vacaciones_tot;
      listTot["Enfermedad"] = Enfermedad_tot;
      listTot["Libre_disposición"] = Libre_disposición_tot;
      listTot["Matrimonio"] = Matrimonio_tot;
      listTot["Nacimiento_de_hijo"] = Nacimiento_de_hijo_tot;
      listTot["Fallecimiento_familiar"] = Fallecimiento_familiar_tot;
      listTot["Realización_de_examen_curso"] = Realización_de_examen_curso_tot;
      ;
      listTot["Excedencia_por_conciliación"] = Excedencia_por_conciliación_tot;
      listTot["Excedencia_voluntaria"] = Excedencia_voluntaria_tot;
      listTot["Baja_de_la_empresa"] = Baja_de_la_empresa_tot;
      listTot["Cambio_de_jornada"] =Cambio_de_jornada_tot;

    

     arrlist.push(listTot);

      let listTotAll = {};
      //let employeeName=res[y].employeeName;
      listTotAll["employeeName"] = "Computo total permisos";
      listTotAll["Asistencia_al_médico"] = grand_tot;
      listTotAll["Vacaciones"] = "";
      listTotAll["Enfermedad"] = "";
      listTotAll["Libre_disposición"] = "";
      listTotAll["Matrimonio"] = "";
      listTotAll["Nacimiento_de_hijo"] = "";
      listTotAll["Fallecimiento_familiar"] = "";
      listTotAll["Realización_de_examen_curso"] = "";
      ;
      listTotAll["Excedencia_por_conciliación"] = "";
      listTotAll["Excedencia_voluntaria"] = "";
      listTotAll["Baja_de_la_empresa"] = "";
      listTotAll["Cambio_de_jornada"] ="";
     
      arrlist.push(listTotAll);


    console.log("arrrrrrrrrrrrrrrrrrrrr",arrlist);

    let columns = [
      { title: "Empleado", dataKey: "employeeName" },
      // { title: "totalLeave", dataKey: "totalLeave" },
      { title: "Asistencia al médico", dataKey: "Asistencia_al_médico" },
      { title: "Vacaciones", dataKey: "Vacaciones" },
      { title: "Enfermedad", dataKey: "Enfermedad" },
      { title: "Libre disposición", dataKey: "Libre_disposición" },
      { title: "Matrimonio", dataKey: "Matrimonio" },
      { title: "Nacimiento de hijon", dataKey: "Nacimiento_de_hijo" },
      { title: "Fallecimiento familiar", dataKey: "Fallecimiento_familiar" },
      { title: "Realización de examen, curso", dataKey: "Realización_de_examen_curso" },
      { title: "Excedencia por conciliación", dataKey: "Excedencia_por_conciliación" },
      { title: "Excedencia voluntaria", dataKey: "Excedencia_voluntaria" },
      { title: "Baja de la empresa", dataKey: "Baja_de_la_empresa" },
      { title: "Cambio de jornada", dataKey: "Cambio_de_jornada" },
 ];

 let columnsArr = [];

 let columnsemployeeNameArr = {};
 columnsemployeeNameArr["title"] = "Empleado";
 columnsemployeeNameArr["dataKey"] = "employeeName";
 columnsArr.push(columnsemployeeNameArr);

 //if(Asistencia_al_médico_tot!=0)
 if(1==1)
 {
  let columnsAsistencia_al_médicoArr = {};
  columnsAsistencia_al_médicoArr["title"] = "Asistencia al médico";
  columnsAsistencia_al_médicoArr["dataKey"] = "Asistencia_al_médico";
  columnsArr.push(columnsAsistencia_al_médicoArr);
 }



 //if(Vacaciones_tot!=0)
 if(1==1)
 {
  let columnsVacacionesArr = {};
  columnsVacacionesArr["title"] = "Vacaciones";
  columnsVacacionesArr["dataKey"] = "Vacaciones";
  columnsArr.push(columnsVacacionesArr); 
 }

 //if(Enfermedad_tot!=0)
 if(1==1)
 {
  let columnsEnfermedadArr = {};
  columnsEnfermedadArr["title"] = "Enfermedad";
  columnsEnfermedadArr["dataKey"] = "Enfermedad";
  columnsArr.push(columnsEnfermedadArr); 
 }

 //if(Libre_disposición_tot!=0)
 if(1==1)
 {
  let columnsLibre_disposiciónArr = {};
  columnsLibre_disposiciónArr["title"] = "Libre disposición";
  columnsLibre_disposiciónArr["dataKey"] = "Libre_disposición";
  columnsArr.push(columnsLibre_disposiciónArr);
 }

 //if(Matrimonio_tot!=0)
 if(1==1)
 {
  let columnsMatrimonioArr = {};
  columnsMatrimonioArr["title"] = "Matrimonio";
  columnsMatrimonioArr["dataKey"] = "Matrimonio";
  columnsArr.push(columnsMatrimonioArr); 
 }

 //if(Nacimiento_de_hijo_tot!=0)
 if(1==1)
 {
  let columnsNacimiento_de_hijoArr = {};
  columnsNacimiento_de_hijoArr["title"] = "Nacimiento de hijon";
  columnsNacimiento_de_hijoArr["dataKey"] = "Nacimiento_de_hijo";
  columnsArr.push(columnsNacimiento_de_hijoArr); 
 }

 //if(Fallecimiento_familiar_tot!=0)
 if(1==1)
 {
  let columnsFallecimiento_familiarArr = {};
  columnsFallecimiento_familiarArr["title"] = "Fallecimiento familiar";
  columnsFallecimiento_familiarArr["dataKey"] = "Fallecimiento_familiar";
  columnsArr.push(columnsFallecimiento_familiarArr);
 }

 if(Realización_de_examen_curso_tot!=0)
 {
  let columnsRealización_de_examen_cursoArr = {};
  columnsRealización_de_examen_cursoArr["title"] = "Realización de examen, curso";
  columnsRealización_de_examen_cursoArr["dataKey"] = "Realización_de_examen_curso";
  columnsArr.push(columnsRealización_de_examen_cursoArr);
 }

 if(Excedencia_por_conciliación_tot!=0)
 {
  let columnsExcedencia_por_conciliaciónArr = {};
  columnsExcedencia_por_conciliaciónArr["title"] = "Excedencia por conciliación";
  columnsExcedencia_por_conciliaciónArr["dataKey"] = "Excedencia_por_conciliación";
  columnsArr.push(columnsExcedencia_por_conciliaciónArr);
 }

 if(Excedencia_voluntaria_tot!=0)
 {
  let columnsExcedencia_voluntariaArr = {};
  columnsExcedencia_voluntariaArr["title"] = "Excedencia voluntaria";
  columnsExcedencia_voluntariaArr["dataKey"] = "Excedencia_voluntaria";
  columnsArr.push(columnsExcedencia_voluntariaArr);
 }

 if(Baja_de_la_empresa_tot!=0)
 {
  let columnsBaja_de_la_empresaArr = {};
  columnsBaja_de_la_empresaArr["title"] = "Baja de la empresa";
  columnsBaja_de_la_empresaArr["dataKey"] = "Baja_de_la_empresa";
  columnsArr.push(columnsBaja_de_la_empresaArr);
 }

 if(Cambio_de_jornada_tot!=0)
 {
  let columnsCambio_de_jornadaArr = {};
  columnsCambio_de_jornadaArr["title"] = "Cambio de jornada";
  columnsCambio_de_jornadaArr["dataKey"] = "Cambio_de_jornada";
  columnsArr.push(columnsCambio_de_jornadaArr);
 }












    let clintName = this.ClentName;
    let width = doc.internal.pageSize.getWidth()
    doc.autoTable(columnsArr, arrlist, {
      margin: { top: 100 },
      addPageContent: function (data) {
        doc.setFontSize(9);

        doc.text(`INFORME DE ASISTENCIA SOLICITADO Y GENERADO HASTA ${month.toUpperCase()}/${date}`, width / 2, 20, { align: 'center' });
        doc.text(`EMPRESA:       ${clintName}`, 40, 60);
        //doc.text(`INFORME GENERADO EL: ${new Date().toLocaleDateString()}`, 40, 80);
        //doc.text(`INFORME GENERADO EL: ${new Date().getDate()}`, 40, 80);
        //doc.text(`INFORME GENERADO EL: ${new Date().toLocaleString()}`, 40, 80);
        doc.text(`INFORME GENERADO EL: ${new Date().toLocaleString('es-ES', {hour12: false})}`, 40, 80);
      }
    });

    doc.save(`${date}-${month.toUpperCase()}.pdf`);
  })


}
empleadoSelection(emp,event) {
  console.log("event...",event.checked)

  if (this.selectedEmployessForPdfDownload.includes(emp.empleado)) {
    this.selectedEmployessForPdfDownload = this.selectedEmployessForPdfDownload.filter(data => data != emp.empleado);
    this.selectedEmployessForPdfDownloadId = this.selectedEmployessForPdfDownloadId.filter(data => data != emp.idEmpleado);
  } else {
    this.selectedEmployessForPdfDownload.push(emp.empleado);
    this.selectedEmployessForPdfDownloadId.push(emp.idEmpleado);
  }
  console.log("this.empleado", emp)
  console.log("this.selectedEmployessForPdfDownload", this.selectedEmployessForPdfDownload)
}
empleadoSelectionSelectAll(empleadoss,event) {
  console.log("event...all",event.checked)

  console.log("empleadoss", empleadoss)
  if(event.checked==true){

    this.selectedEmployessForPdfDownload = empleadoss.map(data => data.empleado);
    this.selectedEmployessForPdfDownloadId = empleadoss.map(data => data.idEmpleado);
  }else{
    this.selectedEmployessForPdfDownload=[];
    this.selectedEmployessForPdfDownloadId=[];
  }
}




}
