<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Report</p>

<br/>
	
<p-toast position='center'></p-toast>





            
    <div class="p-grid p-justify-start">

    <!-- <div class="p-col-12 p-md-12 p-lg-12">
    <fieldset class="fieldset-custom">
        <div class="p-grid">
            <div class="p-col-12">
    <legend>Filtro de impuestos y nóminas</legend>
        <label>Ejercicio</label>
        <p-dropdown styleClass="voraz" id="float-input" [autoDisplayFirst]="false" [showClear]="false" [options]="gds.ejercicios" [(ngModel)]="ejercicio2"></p-dropdown>
    </div></div>
    </fieldset>
    </div>
                
    <div class="p-col-12 p-md-12 p-lg-12">
    <div class="centrado"><button pButton type="button" label="Obtener cuadro resumen {{ejercicio2}}" class="ui-button-raised" (click)="filtrarImpuestos();"></button></div>
    </div> -->
   
    <!-- <pre *ngIf="!(allsaldos | async)">loading takeaways...</pre>  -->

  
    <!-- <pre *ngIf="loading">Loading</pre> -->

    <div class="p-col-12 p-md-12 p-lg-12">
    <table id='tablaModelos' border="1" class='tablaCuadro' (click)="gds.viewDoc($event)">
    <thead><td>{{monthname}} {{ejercicio}}</td><td colspan="32"></td></thead>

    <thead><td>DIAS</td><td>01</td><td>02</td><td>03</td><td>04</td><td>05</td><td>06</td><td>07</td><td>08</td><td>09</td><td>10</td><td>11</td><td>12</td><td>13</td><td>14</td><td>15</td><td>16</td><td>17</td><td>18</td><td>19</td><td>20</td><td>21</td><td>22</td><td>23</td><td>24</td><td>25</td><td>26</td><td>27</td><td>28</td><td>29</td><td>30</td><td>31</td><td>Total</td></thead>

    <!-- [ngClass]="{'mod-mensual': fila[0]==0,'mod-trimestral': fila[0]==1,'mod-acuenta': fila[0]==2,'mod-anual': fila[0]==3}" *ngIf="allsaldos$ | async as allsaldos; else loading;"  -->

    <tbody  *ngIf="!loading">
    <tr *ngFor="let fila of allsaldos" [ngClass]="fila.styleClass">
        <td>
            <!-- {{fila.idEmpleado}} -->
            {{fila.empleado}}
        </td>
        <td *ngFor="let i of rango_modelos">
            <span [innerHTML]="htmlStr[i]"></span>
            <!-- {{i}}<br> -->
            {{fila[i]}}
        <!-- <a *ngIf="isNumber(fila[i])" href="#" [attr.data-id]='fila[i]'><i class="fa fa-file"></i></a>
        <i *ngIf="!isNumber(fila[i])">{{fila[i]}}</i> -->
        </td>
        <td>{{fila.total}}</td>
    </tr>
    <tr><td>Total</td><td colspan="31"></td><td >{{subtotal}}</td> </tr>
   
  </tbody>
 

    <tbody  *ngIf="loading">
    <tr>
    <td colspan="33" >Loading..
        <div id="loader">
            <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="loading"></div>
        </div>

    </td>
    </tr>
    </tbody>

   

    </table>
    </div>

    <!-- <ng-template #loading>
        Loading
     </ng-template> -->

 

    <div class="p-col-12 p-md-12 p-lg-8">
    <BR/>
    <table><TR><TD>Empresa/s :</TD>

        <TD *ngFor="let k of allClientsStyle" [ngClass]="k.styleClass" style='background:"k.styleClass"'>{{k.Cliente}}</TD>

        <!-- <TD style='background:#B8CCE4'>Mensual</TD>
        <TD style='background:#C4D79B'>Trimestral</TD>
        <TD style='background:#FFAEC9'>A cuenta</TD>
        <TD style='background:#FABF8F'>Anual</TD> -->
    
    </TR></table>
    </div>
    
    </div>
