import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainRoutingModule } from './main/main-routing.module';

import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';


registerLocaleData(localeEs, 'es');


import { CommonModule } from '@angular/common';
//import {TabMenuModule} from 'primeng/tabmenu';
//import {TabViewModule} from 'primeng/tabview';
//import {CodeHighlighterModule} from 'primeng/codehighlighter';
//import {StepsModule} from 'primeng/steps';
//import {MenubarModule} from 'primeng/menubar';
//import {CardModule} from 'primeng/card';
//import {TreeModule} from 'primeng/tree';
//import {TreeNode} from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
//import {LoginComponent} from './pages/login/login.component';
//import { MainComponent } from './pages/main/main.component';
import { APP_BASE_HREF } from '@angular/common';
import { MainModule } from './main/main.module';
//import { RecientesComponent } from './main/recientes/recientes.component';
import { DialogModule } from 'primeng/dialog';
//import { LoginComponent } from './pages/login/login.component';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { Login2Component } from './login2/login2.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartModule } from 'primeng/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Table, TableModule } from 'primeng/table';


//import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RespuestaInterceptor } from './interceptores/respuesta.interceptor';
import { SolicitudInterceptor } from './interceptores/solicitud.interceptor';
import { GlobaldataService } from './servicios/globaldata.service';
import { MessageService } from 'primeng/api';
import { FormsModule, } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { AppCommonModule } from 'src/app/app.common.module';

import { NgxCaptchaModule } from 'ngx-captcha';
import { ReactiveFormsModule } from '@angular/forms';
//import {TimelineModule} from 'primeng/timeline';

import {DynamicDialogModule,DialogService,DynamicDialogRef,DynamicDialogConfig} from 'primeng/dynamicdialog';












import { ProgressBarModule } from 'primeng/progressbar';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import { TreeModule } from 'primeng/tree';
import { FieldsetModule } from 'primeng/fieldset';

import {ScrollPanelModule} from 'primeng/scrollpanel';

import {InputNumberModule} from 'primeng/inputnumber';
import {MessagesModule} from 'primeng/messages';





@NgModule({
  declarations: [
    AppComponent,
    Login2Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MainRoutingModule,
    TableModule,
    CommonModule,
    AppCommonModule,
    //TabViewModule,
    //StepsModule,

    //MenubarModule,

    //CardModule,
    ChartModule,
    FileUploadModule,
    HttpClientModule,
    ButtonModule,
    InputTextareaModule,
    InputTextModule,
    //CodeHighlighterModule,
    DialogModule, ConfirmDialogModule, BrowserAnimationsModule,
    MainModule, FormsModule, ToastModule,
    /// Code for REcaptcha
    ReactiveFormsModule,
    NgxCaptchaModule,

    CalendarModule,
    DropdownModule,
    MultiSelectModule
     /// Code for REcaptcha Ends
  ],
  //providers: [HttpClient,{provide: APP_BASE_HREF, useValue : '/clientes/' },
  providers: [HttpClient,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RespuestaInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SolicitudInterceptor,
      multi: true
    }, { provide: LOCALE_ID, useValue: 'es' },
    { provide: APP_BASE_HREF, useValue: '/clientes/' }, GlobaldataService, MessageService,DialogService],
  bootstrap: [AppComponent]
})
export class AppModule { }
