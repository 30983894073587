import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import {CBLService} from 'src/app/servicios/cbl.service'
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Respuesta } from 'src/app/libs/respuesta';
import { formatDate } from '@angular/common';
import { Misdocumentos, Documento } from 'src/app/libs/dataObjects'
import { MessageService, SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { GlobaldataService } from 'src/app/servicios/globaldata.service';


@Component({
  selector: 'app-confirmdocument',
  templateUrl: './confirmdocument.component.html',
  styleUrls: ['./confirmdocument.component.scss'],
})
export class ConfirmdocumentComponent implements OnInit {

  dateConfig: any;
  confirmdocument: Misdocumentos = new Misdocumentos();
  uploadedFiles: any[] = [];
  readonly: boolean = false;
  remarkBody = {
    comment: null,
    file1: null,
    file2: null,
    file3: null
  };
  _pdf: SafeUrl;
  privacy:any[]=[];
  type:any[]=[];
  status:any[]=[];
  cities:any[]=[];
  selectedCity:any;

  languages: SelectItem[] = [];
 selectedLanguage: any;
 applicant: any = {};
   countries: any[];
   dataLoaded = false;

   public allcomplaintcommunication: any = []; 

  @ViewChild('envioDoc') envioDoc: FileUpload;
  @ViewChild('fileUpload') fileUpload: any;
  @ViewChild('fileUpload2') fileUpload2: any;
  @ViewChild('fileUpload3') fileUpload3: any;


  @ViewChild('attachDocument') attachDocument: FileUpload;
  history: Respuesta;
  mes: string;
  selectedTeam: string;
  complaintStatus: string;
  confirmevent: any;
  confirmtype: any;

  constructor(public cbl: CBLService, private messageService: MessageService,
    private httpClient: HttpClient, public dialogService: DialogService, public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,public sanitizer: DomSanitizer,public gds:GlobaldataService) {

    let id = this.config.data.id;
    console.log(id);

    let confirmevent = this.config.data.confirmevent;
    let confirmtype = this.config.data.confirmtype;

    this.confirmevent = confirmevent;
    this.confirmtype = confirmtype;
    if (id) {
      //id=18;

      //alert(id);
      this.loadComplaint(id); 
      //this.getIncendias(id);
      this.readonly = true;
    }
    else {
      this.confirmdocument = new Misdocumentos();
     // this.complaint.fechainicio = new Date();
      this.readonly = false;
      
    }

    this.dateConfig = this.cbl.dateConfig;




  }

  ngOnInit(): void {

    this.mes = "Confidential";
    //this.selectedCity="Rome";
    this.selectedCity = {name:'Rome'};
    console.log("rrrrrrrrrrrrrrr",this.mes);




    this.countries = [
      {label:'Select Country', value:null}
  ];
  
  //setTimeout(() => {
  let result = [
  {id: 1, name: 'Pakistan'},
  {id: 2, name: 'India'},
  {id: 3, name: 'Sri Lanka'},
  ];
  
  result.forEach(function(country) {
  this.countries.push({label: country.name, value: country.name});
  }, this);
  
  this.dataLoaded = true;
  
  // }, 1000);
  
  
  
  //this.applicant = {country: 'India'};
  


  // this.privacy = [
  //   { label: 'Anonymous', value: 'Anonymous' },
  //   { label: 'Confidential', value: 'Confidential' }, 
   
  // ];

  this.privacy = [
    { label: 'ANONIMO', value: 'ANONIMO' },
    { label: 'CONFIDENCIAL', value: 'CONFIDENCIAL' },
   
  ];



  // this.type = [
  //   { label: 'Harassment', value: 'Harassment' },
  //   { label: 'Misbehavior', value: 'Misbehavior' },
  //   { label: 'Misappropriation of resources', value: 'Misappropriation of resources' },
  //   { label: 'Safety and occupational risks', value: 'Safety and occupational risks' },
  //   { label: 'Others', value: 'Others' },
   
  // ];

  this.type = [
    { label: 'Acoso', value: 'Acoso' },
    { label: 'Mala conducta', value: 'Mala conducta' },
    { label: 'Apropiación indebida de recursos', value: 'Apropiación indebida de recursos' },
    { label: 'Seguridad y riesgos laborales', value: 'Seguridad y riesgos laborales' },
    { label: 'Otros', value: 'Otros' },
   
  ];


  // this.status = [
  //   { label: 'SENT', value: 'SENT' },
  //   { label: 'READ', value: 'READ' },
  //   { label: 'ANALYSIS', value: 'ANALYSIS' },
  //   { label: 'INVESTIGATION', value: 'INVESTIGATION' },
  //   { label: 'COMMUNICATION TO THE COMPLAINT', value: 'COMMUNICATION TO THE COMPLAINT' },
  //   { label: 'RESOLVED', value: 'RESOLVED' },
  // ];

  this.status = [
    { label: 'ENVIADO', value: 'ENVIADO' },
    { label: 'LEIDO', value: 'LEIDO' },
    { label: 'ANALISIS', value: 'ANALISIS' },
    { label: 'INVESTIGACIÓN', value: 'INVESTIGACIÓN' },
    { label: 'COMUNICACIÓN AL DENUNCIANTE', value: 'COMUNICACIÓN AL DENUNCIANTE' },
    { label: 'RESUELTA', value: 'RESUELTA' },
  ];

  //this.confirmdocument.complaintStatus='ENVIADO';



this.applicant = {country: 'India'};

this.showComplaintCommunication();
    

  }
  async getEmployeeName(id){
    let url2='empleado?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=E.idEmpleado as idEmpleado,T.empleado as empleado'
    +'&where=E.idEmpleado='+id+' '
    +'&order=E.idEmpleado DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['empleado'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }

  async getClientName(id){
    let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idCliente,Cliente'
    +'&where=idCliente='+id+' '
    +'&order=idCliente DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['Cliente'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }





async getPersenInChargeName(id){
    let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idCliente,Cliente,encargadoDenuncias'
    +'&where=idCliente='+id+' '
    +'&order=idCliente DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['encargadoDenuncias'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }











  showComplaintCommunication()
  {
    
		// let url="fields=E.idempleado as value,T.empleado,S.idsolicitud";
		// url=url+"&joins=trabajador,solicitudes(E.idempleado=>S.idempleado)";
		// // url=url+"&where=E.idcliente="+this.gds.id_cliente+" and year(S.fechainicio)='"+this.ejercicio+"'";
    
		// url=url+"&groupby=E.idempleado,T.empleado"
		// url=url+"&order=T.empleado";

    let url2='complaintcommunication?'
    +'&fields=idComplaintCommunication,idComplaint,complaintCommunication,communicationType,communicationByEmployeetId,communicationByClientId,communicationDate';
    url2+='&where=idComplaint="'+this.config.data.id+'"  ';
   
    url2+='&order=idComplaintCommunication ASC';
    console.log("url.....fff............",url2);
		// this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).subscribe((res)=>{
    //   console.log("data.......",res.resp);
		// 	this.allcomplaintcommunication=res.resp;      
      
		// });


    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then(async (r)=>{
      //console.log(r);
      console.log("vvvvvvvvvv",r.resp);
      for(let p of r.resp)
      {
        //let dias=0;
        // for(let d of p.permisos)
        // {
          // let cd=p.fechas.split(",").length;
          // p['dias']=cd; 
          // if (cd>1)
          //   p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(p.fechafin,'dd/MM/yyyy','en-US');
          // else
          //   p['periodo']=p.fechainicio;

          // if(p.horainicio!=null && p.horafin!=null)
          // {
          //   p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
          // }
          // if(p.estado!="RECHAZADA") dias+=cd;

         // p['periodo']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(p.complaintDate,'dd/MM/yyyy','en-US');

         // p['periodo']=p.complaintDate;
        // }
        // let respEmp=this.getEmployeeName(p.idEmpleado);
        // console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
        //  p['dias']=dias;
        //  p['employeeName']=await this.getEmployeeName(p.idEmpleado);

       // let respEmp=this.getEmployeeName(p.idEmpleado);
        //console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
        // p['dias']=dias;
         if(p.communicationType=='Employee')
         {
          p['userName']=await this.getEmployeeName(p.communicationByEmployeetId);
         }
         else{
          p['userName']=await this.getClientName(p.communicationByClientId);
         }
         p['communicationDate']=formatDate(p.communicationDate,'dd/MM/yyyy HH:mm','en-US');
        //  p['complaintNodays']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(new Date(),'dd/MM/yyyy','en-US');

        
        
         
      }
      console.log(r.resp);
     // this.complaints=r.resp;
      this.allcomplaintcommunication=r.resp;
      
    });
  }


  async checkAppSentCode(idDocumento,whatsAppSentCodeType) {
   

    var respuesta;

    await this.httpClient.get<Respuesta>(this.cbl.urlBaseDL + 'confirm_document/checkWhatsAppSentCodeType/' + idDocumento+'/' + whatsAppSentCodeType).toPromise().then((r)=>{

      console.log("xxxxxxxxxxxxxxxxxxxx.....",r[0])
      let resp=r[0].whatsAppSentCode;
      console.log("dddddddddddddddddd.....",r[0].whatsAppSentCode)
      
     

      if(resp==whatsAppSentCodeType)
      {
        respuesta=true;
      }
      else{
        respuesta=false;
      }
      
      
      //this.nominasTemp = r['resp'];
     // console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
      
    }); 
    return respuesta;




  }


  async solicitar() {

    //alert("ppppppp"+this.confirmdocument.whatsAppSentCodeType);

    if (this.confirmdocument.whatsAppSentCodeType == undefined || this.confirmdocument.whatsAppSentCodeType == null || this.confirmdocument.whatsAppSentCodeType == "") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Need to enter code!' });
      return;
    }

    console.log("xxxxxxmmmmm",this.checkAppSentCode(this.confirmdocument.idDocumento,this.confirmdocument.whatsAppSentCodeType));

    if (await this.checkAppSentCode(this.confirmdocument.idDocumento,this.confirmdocument.whatsAppSentCodeType) === false) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Code is not matching!' });
      return;
    }
    
    let whatsAppSentFlag='1';
    
    this.httpClient.put(this.gds.urlBaseDL + 'confirm_document/updateWhatsAppSentCode/' + this.confirmdocument.idDocumento, {idDocumento:this.confirmdocument.idDocumento,whatsAppSentFlag:whatsAppSentFlag}).toPromise().then((p) => {
      if (p) {

        this.ref.close(true);

        this.gds.viewTreeDoc(this.confirmevent);
      }
      });
    
    //this.confirmdocument.whatsAppSentFlag = this.confirmdocument.whatsAppSentFlag;

    // this.cbl.insertComplaint(this.confirmdocument).then()
    // {
    //   // this.cbl.emailToCliente(this.cbl.id_cliente, "Ha recibido una nueva solicitud en el Portal del Cliente", `Estimado ${this.cbl.name_cliente}:\n\nUn empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de incidencia << ${this.complaint.solicitud} >>.\n\nSaludos`);
    //    this.ref.close(true);
    // }


  }


  
  async solicitarRec() {

    //alert("ppppppp"+this.confirmdocument.whatsAppSentCodeType);

    if (this.confirmdocument.whatsAppSentCodeType == undefined || this.confirmdocument.whatsAppSentCodeType == null || this.confirmdocument.whatsAppSentCodeType == "") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Need to enter code!' });
      return;
    }

    console.log("xxxxxxmmmmm",this.checkAppSentCode(this.confirmdocument.idDocumento,this.confirmdocument.whatsAppSentCodeType));

    if (await this.checkAppSentCode(this.confirmdocument.idDocumento,this.confirmdocument.whatsAppSentCodeType) === false) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Code is not matching!' });
      return;
    }
    
    let whatsAppSentFlag='1';
    
    this.httpClient.put(this.gds.urlBaseDL + 'confirm_document/updateWhatsAppSentCode/' + this.confirmdocument.idDocumento, {idDocumento:this.confirmdocument.idDocumento,whatsAppSentFlag:whatsAppSentFlag}).toPromise().then((p) => {
      if (p) {

        this.ref.close(true);

        //this.gds.viewTreeDoc(this.confirmevent);
        this.gds.viewDoc(this.confirmevent);
      }
      });
    
    //this.confirmdocument.whatsAppSentFlag = this.confirmdocument.whatsAppSentFlag;

    // this.cbl.insertComplaint(this.confirmdocument).then()
    // {
    //   // this.cbl.emailToCliente(this.cbl.id_cliente, "Ha recibido una nueva solicitud en el Portal del Cliente", `Estimado ${this.cbl.name_cliente}:\n\nUn empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de incidencia << ${this.complaint.solicitud} >>.\n\nSaludos`);
    //    this.ref.close(true);
    // }


  }


  complaintAttachDocument(confirmdocumentAttachDocument: any) {
    throw new Error('Method not implemented.');
  }

  cerrar() {
    this.ref.destroy();
  }

  sendAgainWhatappCode()
  {
    //alert(this.config.data.id);

    this.httpClient.get<Respuesta>(this.gds.urlBaseDL + 'updaeCodeDocumentos/getDocumentosId/' + this.config.data.id+'/'+this.gds.id_cliente).toPromise().then(async (r) => {
      console.log(r.resp);

      this.httpClient.get(this.gds.urlBaseDL + 'sendWhatzappForDownloadDocumentos/getDocumentosId/' + this.config.data.id+'/'+this.gds.id_cliente).toPromise();
      
    });

    // this.httpClient.get(this.gds.urlBaseDL + 'updaeCodeDocumentos/getDocumentosId/' + this.config.data.id+'/'+this.gds.id_cliente).toPromise();

    // this.httpClient.get(this.gds.urlBaseDL + 'sendWhatzappForDownloadDocumentos/getDocumentosId/' + this.config.data.id+'/'+this.gds.id_cliente).toPromise();
  }

  loadComplaint(id) {
    let url2 = 'documentos?'
    +'&fields=idDocumento,whatsAppSentCode'
    + '&where=idDocumento=' + id;

    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL + url2).toPromise().then(async (r) => {
      console.log(r.resp);
      // r.resp[0].horainicio = r.resp[0].horainicio.substr(0, 5);
      // r.resp[0].horafin = r.resp[0].horafin.substr(0, 5);
      // r.resp[0].fechainicio = formatDate(r.resp[0].fechainicio, 'dd/MM/yyyy', 'en-US');
      r.resp[0].idDocumento = r.resp[0].idDocumento;
      r.resp[0].whatsAppSentCode = r.resp[0].whatsAppSentCode;
      //r.resp[0].complaintHandledClientId = this.gds.id_cliente;
      r.resp[0].whatsAppSentCodeType = '';
      //r.resp[0].complaintHandledClientId = await this.getPersenInChargeName(this.gds.id_cliente);
      //  if(r.resp[0].complaintHandledClientId!=0)
      // {
        
      //   r.resp[0].complaintHandledClientName = await this.getPersenInChargeName(r.resp[0].complaintHandledClientId); 
      // }
      // else{
      //   r.resp[0].complaintHandledClientName = '';
      // }
       this.confirmdocument = new Misdocumentos(r.resp[0]);
      //this.complaint = r.resp[0];
      //this.complaint.fechafin = this.complaint.fechainicio;

      console.log("aaaaaaaaaaaaaaaaaaaaaaa",this.confirmdocument);
    });
  }

  /**functions to cpature comments and upload files start */
  onBasicUpload(inputData, key) {
    for (const file of inputData.currentFiles) {
      const dataset = this.readFile(file, key);
    }
  }

  private readFile(file: File, key) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.remarkBody[key] = JSON.stringify(reader.result);
    };
  }
  saveComment() {



    this.httpClient.put(this.cbl.urlBaseDL + 'complaint/' + this.confirmdocument.idDocumento, {idDocumento:this.confirmdocument.idDocumento,whatsAppSentCode:this.confirmdocument.whatsAppSentCode,whatsAppSentFlag:this.confirmdocument.whatsAppSentFlag}).toPromise().then((r) => {
            if (r) {
       console.log("soppppppppppppppp",r);
           //this.ref.close(true);


          //  if (this.confirmdocument.complaintCommunication == "") { 

          //   // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Complaint nuevo mensaje es obligatorio!' });
          //   // return;
          //   this.ref.close(true);
          // }
          // else{

          //   this.httpClient.post(this.cbl.urlBaseDL + 'complaintcommunication/' + this.confirmdocument.idComplaint, {idComplaint:this.confirmdocument.idComplaint,complaintCommunication:this.confirmdocument.complaintCommunication,communicationType:"Client",communicationByEmployeetId:"0",communicationByClientId:this.confirmdocument.complaintHandledClientId,communicationDate:formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US')}).toPromise().then((p) => {
          //     if (p) { 

          //     this.ref.close(true);

          //     }
          //     });

          // }
          


      
          }
    });


    
  }
 





  /**functions to cpature comments and upload files end */
  getIncendias(id) {
    this.httpClient.post<Respuesta>(this.cbl.urlBaseDL + 'getincidenciaremark/' + id, {}).toPromise().then((r) => {
      this.history = r;
    });
  }

  showpdf(file) {
    let fileArray:any[] = file.split(",");
      const [,type] = fileArray[0].split(';')[0].split('/');
      const linkSource = file;
      const downloadLink = document.createElement("a");
      const fileName = "document."+type;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
  }

  clear() {
    this.fileUpload.clear();
    this.fileUpload2.clear();
    this.fileUpload3.clear();

    this.attachDocument.clear();
    
  }
}
