<p-toast position='center'></p-toast>
<div class="login-panel">
	<div class="p-grid p-nogutter p-justify-center p-align-center" style="height:100%">
		<div class="p-xl-3 p-lg-6 p-md-8 p-sm-10">
			<div class="card card-primary card-outline">
				<div class="card-header ">
					<div class="p-grid p-justify-center p-align-center">
						<div class="p-xl-6 p-lg-6 p-md-8 p-sm-10">
							<img src="assets/logo.png" class="login-logo">
						</div>
					</div>
					<div class="p-grid p-justify-center p-align-center"> 
						<div class="p-text">
							<!-- Versión 28.06.2024  -->
							
							{{this.angularProjectVersion}}
						
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="p-grid p-justify-center p-align-center">
						<div class="login-box-msg">Portal del Cliente</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12">
							<div class="ui-inputgroup">
								<input id="txtUser" type="text" size="30" autocomplete="off"
									placeholder="Nombre de usuario" pInputText class="form-control voraz"
									[(ngModel)]="cliente">
							</div>
						</div>
						<div class="p-col-12">
							<div class="ui-inputgroup"*ngIf="!resetPassword">
								<input id="txtPwd" type="password" size="30" autocomplete="off" placeholder="Contraseña"
									pInputText class="form-control voraz" [(ngModel)]="pwd_cliente">
							</div>
						</div>
						<!--/// Code for REcaptcha -->
						<div class="p-col-12"  *ngIf="!resetPassword">
							<form [formGroup]="aFormGroup">
								<!-- portalasesoria COSOFT  -->

								<ngx-recaptcha2 #captchaElem siteKey="6LcZX6ggAAAAALi27IrPJkcHM4b8x_slEb0IYSnS"
									(success)="handleSuccess($event)" [size]="size" [hl]="lang" [theme]="theme"
									[type]="type" formControlName="recaptcha">

									<!-- portal 347 -->
<!--<ngx-recaptcha2 #captchaElem siteKey="6LcboCUiAAAAAAJxNiapaLdr_SWjM82AiBSHqQyC"
									(success)="handleSuccess($event)" [size]="size" [hl]="lang" [theme]="theme"
									[type]="type" formControlName="recaptcha">-->

									
								</ngx-recaptcha2>
							</form>
						</div>
							<!--/// Code for REcaptcha Ends-->
					</div>

					<div class="p-grid mt10">
						<div class="p-col-7"  *ngIf="!resetPassword">
							<label><input type="checkbox" id="remember" [(ngModel)]="rememberMe"><strong>Recordar
									acceso</strong></label>
						</div>
						<div class="p-col-5"  *ngIf="!resetPassword">
							<button pButton type="button" class="ui-button-raised right"
								(click)="login();">Acceder</button>
						</div>
						<div class="p-col-5"*ngIf="resetPassword">
							<p-button 
								class="ui-button-raised ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only left"
								(click)="reset_recordar()" label="continue"></p-button>
						</div>
					</div>
					<div class="p-grid"  *ngIf="!resetPassword">
						<div class="p-col-12 mt20">
							<p href="" class="text-center left" style="color:blue;" (click)="resetPassword=true">Olvidé mi contraseña</p>
						</div>
					</div>
				</div>
			</div>
			<!--<div class="extra-page-footer">
				<div>Versión 3.21</div>
			</div>		-->
		</div>
	</div>
</div>