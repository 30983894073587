<div class="grid r-grid r-grid12-6 clientdepartment-css" style="width:'100%';">
    
    <div class="g-w12field">
        <input type="hidden" id="idClient_department" [disabled]="readonly" [(ngModel)]="clientdepartment.idClient_department"
         />
        

        </div> 
        

    <div class="g-w12field">
        <label for="complaintPrivacy">Empresa</label>

       
        <p-dropdown placeholder="Seleccione"  id="clientId"  [options]="categoryList" [(ngModel)]="clientdepartment.clientId"  [showClear]="true"  [readonly]="true"></p-dropdown>

         
    </div>

  
   

    

    <div class="g-w12field" >
        <label>Nombre del departamento</label>
        <input pInputText id="department_name" [(ngModel)]="clientdepartment.department_name" styleClass="fecha-hora"   />
    </div>



    <div class="g-w12field" >
        <label>Email del departamento</label> 
        <input pInputText id="department_email" [(ngModel)]="clientdepartment.department_email" styleClass="fecha-hora"    />
    </div>

    <!-- <div class="g-w12field" >
        <label>Contraseña</label>
        <input pInputText id="collab_password" [(ngModel)]="clientcollaborator.collab_password" styleClass="fecha-hora"    />
    </div> -->







    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w2">
                
                <p-button *ngIf="!readonly" label="Guardar" (click)="solicitar()"></p-button>
                <p-button *ngIf="readonly" label="Guardar" (click)="saveClientdepartment()" style="margin-right:10px"></p-button>
                <!-- <p-button *ngIf="readonly" label="Guardar" (click)="saveClientcollaborator()" style="margin-right:10px"></p-button> -->
                
            </div>
        </div> 
    </div>


</div>

<p-toast position='center'></p-toast>