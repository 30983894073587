<div class="grid r-grid r-grid12-6 confirmdocument-css" style="width:'100%';">

    <div class="g-w12field">
        <input type="hidden" id="idDocumento" [disabled]="readonly" [(ngModel)]="confirmdocument.idDocumento"
         />
         <input type="hidden" id="whatsAppSentCode" [disabled]="readonly" [(ngModel)]="confirmdocument.whatsAppSentCode"
         />

        </div> 
        
    
        <div class="g-w12field">

            <b>Confirmar la autoridad del documento </b>  
            Hemos enviado por "whats app" un código de seguridad para poder descargar el documento. Puede ver el nº de "whats app" asociado a esta cuenta haciendo click en el menu lateral "Mis Datos" y luego pulsar en "Datos de contacto". Revise su "whats app" e introduzca el código a continuación:
           
        </div>

    
    <div class="g-w12field" *ngIf="readonly">
        <label for="complaintDesc"><b>Introducir el código: </b></label>
       
         <input pInputText id="whatsAppSentCodeType" [(ngModel)]="confirmdocument.whatsAppSentCodeType" styleClass="fecha-hora"   />
    </div>



    
      

    

    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w2">
                <!-- {{confirmtype}} -->
                <!-- <p-button *ngIf="readonly" label="Confirmar11" (click)="solicitar()" style="margin-right:10px"></p-button> -->
                
                <p-button *ngIf="confirmtype == 'document'" label="Confirmar" (click)="solicitar()" style="margin-right:10px"></p-button>

                <p-button *ngIf="confirmtype == 'recent'" label="Confirmar" (click)="solicitarRec()" style="margin-right:10px"></p-button>


                <p-button *ngIf="readonly" label="Volver a enviar el código" (click)="sendAgainWhatappCode()" style="margin-right:10px"></p-button>
                <p-button *ngIf="readonly" label="Cerrar" (click)="cerrar()"></p-button>
            </div>
        </div>
    </div> 



</div>

<!-- <p-toast position='center'></p-toast> -->