<app-header-breadcrumb></app-header-breadcrumb>
<p-button label="" (onClick)="goback_hora()"><i class="pi pi-backward"></i></p-button>

<p class="tituloSeccion">Fichajes de {{empleado}}</p>
    <div class="grid filtro r-grid r-grid12-12-6">
        
<div class="g-w12field">
<p-table [value]="saldos" dataKey="id">
    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th>Fecha</th> 
            <th>Saldo Horario (Horas:minutos)</th>
            <!-- <th>Localizacion</th> -->
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-emp let-expanded="expanded">
        <tr>
            <td><button type="button" pButton pRipple [pRowToggler]="emp" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button></td>
            <td>{{emp.label}}</td>
            <td>{{emp.saldo}}</td>
            <!-- <td>{{emp.inout['6']}}</td> -->
            
        </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-emp>
        <tr>
            <td colspan="3"><div class="p-p-3">
        <p-table [value]="emp.inout" >
            <ng-template pTemplate="header">
                <tr>
                    <th>Entrada</th>
                    <th>Salida</th>
                    <th>Saldo</th>
                    <th>Tipo</th>
                    <!-- <th>Localizacion</th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-fic>
                <tr>
                    <td>{{fic[1]}}</td>
                    <td>{{fic[2]}}</td>
                    <td>{{fic[3]}}</td>
                    <td>{{fic[4]}}</td>
                    <!-- <td>{{fic[6]}}</td> -->
                </tr>
            </ng-template>
        </p-table></div></td></tr>
    </ng-template>
</p-table>
</div>
</div>