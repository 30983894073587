import { Component, OnInit } from '@angular/core';
import { Input,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { FechaTipadaPipe } from '../../libs/fechaTipada.pipe';
import { Utilidades } from '../../libs/utilidades';
import { UtilService } from '../../servicios/util.service';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {FileUpload} from 'primeng/fileupload';
import { Router,ActivatedRoute  } from "@angular/router";
import { Tarea,TIPO_TAREA, Documento } from '../../dataobjects/clasesbl';
import { Bl } from '../../servicios/bl';

@Component({
  selector: 'app-enviar',
  templateUrl: './enviar.component.html',
  styleUrls: ['./enviar.component.scss']
})
export class EnviarComponent implements OnInit {

  
	@ViewChild('envioDoc') envioDoc:FileUpload;  //ElementRef
	
	constructor(private httpClient: HttpClient,public gds:GlobaldataService,private us:UtilService,private messageService: MessageService,
						private router:Router, private routera: ActivatedRoute,public bl:Bl)
	{
  }

	async ngOnInit() {}
			
	async myUploader(event) {
		/*const formData = new FormData();
		for (let key in event.files)
		{
			console.log("Ficheros:"+key+" ===> " +event.files[key]+" " +event.files[key].name);
			formData.append(event.files[key].name, event.files[key], event.files[key].name);
		}*/		
	
		let tarea:Tarea=new Tarea({
			concepto:""
			,idCreador:0
			,origen:6
			,Tarea:'Envío de documentos por el cliente'
			,tipo:TIPO_TAREA.TIPO_GENERAL
			,idCliente:this.gds.id_cliente
		});
		
		console.log(tarea);
	
		let idTarea=0;
		await this.bl.nueva_tarea(tarea).then((id)=>{
			console.log(id);
			idTarea=id;
		});	
		
		let meta=new Documento({descripcion:'',idCliente:this.gds.id_cliente});
		let docs=[];
		if(this.envioDoc.files.length>0) 
		{
			await this.bl.send_documentos(this.envioDoc.files,meta).then(async results=>
			{
				docs=results;
				console.log("RESUMEN:",idTarea,docs);
				await this.bl.add_docs_to_tarea(idTarea,0,docs);
			});
		}
		
		this.envioDoc.clear();
		this.messageService.add({severity: 'info', summary: 'Envio de ficheros a la gestoría', detail: 'Ficheros recibidos con éxito!'});
				

		/*var idTarea:Number=0;
		this.httpClient.put(this.gds.urlBaseDL+'tareas/extranet',{
			cliente:this.gds.id_cliente
		}).subscribe((res)=>{
			idTarea=res["tarea"];
			console.log(idTarea);
			
			formData.append('tarea',String(idTarea));
			this.us.upload(formData,this.gds.urlBaseDL+'documentos/upload').subscribe((res)=>{
				if(res['type']==HttpEventType.Response)
				{
					console.log(res);
					this.envioDoc.clear();
					this.messageService.add({severity: 'info', summary: 'Envio de ficheros a la gestoría', detail: 'Ficheros recibidos con éxito!'});
				
				}
			});
			
		
		});*/
		
	}


}
