import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {Table} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { Respuesta } from 'src/app/respuesta';
import { formatDate } from '@angular/common';
import { UtilService } from 'src/app/servicios/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Dropdown } from "primeng/dropdown";



import {CBLService} from 'src/app/servicios/cbl.service'
import { ClientdepartmentComponent } from '../clientdepartment/clientdepartment.component';

export interface TiposClientdepartment {
  nombre: string;
  id: string;
}

export class Clientdepartment {
  estado: string;
  fechaInicio: Date;
  fechaFin: Date;
  observaciones: string;
  tipoComplaint: number;
  adjuntos: any[] = [];
  tipo: string;
  motivo: string;
}

@Component({
  selector: 'app-departments',
  templateUrl: './clientdepartments.component.html',
  styleUrls: ['./clientdepartments.component.scss'],
})
export class ClientdepartmentsComponent implements OnInit {
  @ViewChild('dropDownThing')  dropDownThing: Dropdown;
  tiposClientdepartments: TiposClientdepartment[];
  selectedTipoClientdepartment: TiposClientdepartment;
  selectedClientdepartment: Clientdepartment = null;
  tempClientdepartment: Clientdepartment = new Clientdepartment();
  collapsed: boolean = true;
  yearArray:any[] = [];
  selectedYear:any;
  message = "Documentos del año";
  currentSearchYear:any;
  es: any;
  dateFrom: Date;
  dateTo: Date;
  clientdepartmentsTemp =[]
  clientdepartments: Clientdepartment[] = [
    {
      estado: 'Pendiente',
      tipo: 'Asistencia al médico',
      fechaInicio: new Date(),
      fechaFin: null,
      observaciones: 'Se encuentra en estado pendiente en el lugar.',
      tipoComplaint: 1,
      adjuntos: [
        { name: 'Cita previa en clínica estomatológica', size: '30.000' },
      ],
      motivo: '',
    },
    {
      estado: 'Rechazada',
      tipo: 'Olvido de fichaje',
      fechaInicio: new Date(),
      fechaFin: new Date(),
      observaciones: '',
      tipoComplaint: 2,
      adjuntos: [],
      motivo:
        'Falta de documentación acreditativa sobre el motivo de la ausencia',
    },
  ];

  uploadedFiles: any[] = [];

  constructor(public cbl:CBLService,private messageService: MessageService,private httpClient: HttpClient,public dialogService: DialogService,public gds:GlobaldataService) {
    this.tiposClientdepartments = [
      { nombre: 'Asistencia al médico', id: '1' },
      { nombre: 'Olvido de fichaje', id: '2' },
    ];
  }

  ngOnInit(): void {
    this.createYearPicker();
    this.loadClientdepartments();

    this.es = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      today: 'Hoy',
      clear: 'Borrar',
    };
  }

  onUpload(event) {
    for (let file of event.files) {
      this.tempClientdepartment.adjuntos.push(file);
    }
  }

  onRowSelect(event) {
    this.collapsed = false;
    this.tempClientdepartment = this.selectedClientdepartment;
    // this.selectedTipoClientcollaborator = this.tiposClientcollaborators[
    //   this.tempClientcollaborator.estado - 1
    // ];
  }
  onRowUnselect(event) { 
    this.collapsed = true;
    this.tempClientdepartment = new Clientdepartment();
    this.selectedClientdepartment = null;
    this.selectedTipoClientdepartment = null;
  }

  crearClientdepartment(event) {
    this.collapsed = true;
  }

  selectClientdepartment(id){
    const ref = this.dialogService.open(ClientdepartmentComponent, {
      data:{id:id},
      header: 'Detalles', 
      width: '70%'
      });

      ref.onClose.subscribe((ret) => {

        this.loadClientdepartments();
        this.selectedYear = null
        if(this.dropDownThing != undefined && this.dropDownThing != null)
          this.dropDownThing.updateSelectedOption(null);
        this.loadClientdepartments();
       
      });

  }
  deleteClientdepartment(idClient_department,department_name){
    //alert(idClient_collaborator);
    if(confirm("Are you sure to delete "+department_name+"? ")) {
      //console.log("Implement delete functionality here");
      this.httpClient.delete(this.cbl.urlBaseDL + 'client_department/' + idClient_department, {}).toPromise().then((p) => {
        if (p) {
  
          this.loadClientdepartments();
  
        }
        });

    }

   

  }
  nueva()
  {
    const ref = this.dialogService.open(ClientdepartmentComponent, {
      data:{id:0},
      header: 'Detalles',
      width: '70%'
      });

    ref.onClose.subscribe((ret) => {

      this.loadClientdepartments(); 
      this.selectedYear = null
      if(this.dropDownThing != undefined && this.dropDownThing != null)
        this.dropDownThing.updateSelectedOption(null);
      this.loadClientdepartments();
     
    });
    
  }

  

  async getEmployeeName(id){
    let url2='empleado?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=E.idEmpleado as idEmpleado,T.empleado as empleado'
    +'&where=E.idEmpleado='+id+' '
    +'&order=E.idEmpleado DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['empleado'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/ 
    }); 

   return respuesta;
  }
  async getClientName(id){
    let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idCliente,Cliente'
    +'&where=idCliente='+id+' '
    +'&order=idCliente DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['Cliente'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }

  
async getPersenInChargeName(id){
  let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  +'&fields=idCliente,Cliente,encargadoDenuncias'
  +'&where=idCliente='+id+' '
  +'&order=idCliente DESC';  //&compacto=true

  //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

 // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

  //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
  var respuesta;
  await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
    let resp=r['resp'];
    respuesta=resp[0]['encargadoDenuncias'];
    //this.nominasTemp = r['resp'];
    console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
    /*this.total=0.0;
    this.nominas=this.nominas.map((nom)=>{
       if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
       return nom;
    });
    console.log(this.nominas,this.total);*/
  }); 

 return respuesta;
}









  loadClientdepartments()
  {
    // let url2='solicitudes?'
    // +'&fields=idsolicitud,solicitud,fechainicio,obs,fechafin,horainicio,horafin,fechas,estado'
    // +'&where=idEmpleado='+this.cbl.id_empleado+" and idTipoPermiso=0"
    // +'&order=fechainicio DESC';

    // let url2='complaint?'
    // +'&fields=idComplaint,idEmpleado,complaintDate,complaintPrivacy,complaintType,complaintAttachDocument,complaintDesc,complaintStatus,complaintCommunication'
    // +'&where=idEmpleado='+this.cbl.id_empleado+" "
    // +'&order=idComplaint DESC'; 


    console.log("xxxxxxxxxxxxxx",this.gds);



    let url2='client_department?'
    +'&fields=idClient_department,clientId,department_name,department_email'
    +'&where=clientId='+this.gds.id_cliente+""
    +'&order=idClient_department DESC'; 

    //+'&tree=solicitud->permisos=>fechainicio,fechafin,horainicio,horafin,fechas,estado';

    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL+url2).toPromise().then(async (r)=>{
      console.log(r);
      
      for(let p of r.resp)
      {
          let dias=0;
          // for(let d of p.permisos)
          // {
            // let cd=p.fechas.split(",").length;
            // p['dias']=cd; 
            // if (cd>1)
             // p['stampDate']=formatDate(p.stampDate,'dd/MM/yyyy','en-US');
            // else
            //   p['periodo']=p.fechainicio;

            // if(p.horainicio!=null && p.horafin!=null)
            // {
            //   p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
            // }
            // if(p.estado!="RECHAZADA") dias+=cd;

           // p['periodo']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(p.complaintDate,'dd/MM/yyyy','en-US');

           // p['periodo']=p.complaintDate;
          // }
          // let respEmp=this.getEmployeeName(p.idEmpleado);
          // console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
          //  p['dias']=dias;
           p['clientName']=await this.getClientName(p.clientId);

         // let respEmp=this.getEmployeeName(p.idEmpleado);
          //console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
          //  p['dias']=dias;
          //  p['employeeName']=await this.getEmployeeName(p.idEmpleado);
          // if(p.complaintHandledClientId!='0')
          // {
          //   //p['complaintInCharge']=await this.getClientName(p.complaintHandledClientId);
          //   p['complaintInCharge']=await this.getPersenInChargeName(p.complaintHandledClientId);
          // }
          // else{
          //   p['complaintInCharge']="";
          // }
           


           
           //this.getEmployeeName(p.idEmpleado);
          //r.resp.push(p);
         // r.resp.push(m);
      }
      console.log("sssssssssssssssssssss",r.resp);
      this.clientdepartments=r.resp;
      this.clientdepartmentsTemp=r.resp;
      this.searchBasedOnYear()
    });
  }
  createYearPicker(){
    let currentYear = new Date().getFullYear();
    let earliestYear = 2010;

    while (currentYear >= earliestYear) {
     this.yearArray.push({"year":currentYear})
      currentYear -= 1;
    }
  }
 
  searchBasedOnYear(){
    let tempData  = []; 
    console.log(this.selectedYear)
    if(this.selectedYear != null && this.selectedYear !== undefined){
        this.currentSearchYear = ""+this.selectedYear["year"]

        console.log("dddddddddddddd",this.clientdepartmentsTemp);
        this.clientdepartmentsTemp.forEach(value =>{

          let yearFromDb:string = ""+value["stampDate"];
          if(yearFromDb.indexOf(this.currentSearchYear)>-1)  {
            tempData.push(value);
          }       
        });         
    }else{
      this.currentSearchYear  = ''
      this.clientdepartmentsTemp.forEach(value =>{
        tempData.push(value);
      });
    }
    this.clientdepartments = tempData;
  }
}
