<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Enviar documentación al despacho</p>

<br/>
<p-toast position='center'></p-toast>

<p-fileUpload id="envioDoc" #envioDoc (uploadHandler)="myUploader($event)" skinSimple="true" chooseLabel="Seleccionar" uploadLabel="Enviar" cancelLabel="Cancelar" multiple="true"  maxFileSize="10000000" name="myfile[]" customUpload="true">
</p-fileUpload>

	
	

