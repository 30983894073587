
<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Mis asuntos</p>

<ul class="customlista"  (click)="verTramite($event);">
		
<li *ngFor="let tarea of tareas" [attr.data-id]='tarea.idTarea'>
    <div class="fecha">{{tarea.fecha.substr(0,10)}}</div> 
    <i class="pi pi-list icono"></i>{{tarea.Tarea}}</li>
</ul>
	

<app-asunto #asunto id="asunto">
</app-asunto>   
