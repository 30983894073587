import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SolicitudInterceptor implements HttpInterceptor {
    
	public static token:string='';
	
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		
		if(	request.responseType=='json' && 
			(request.method=='POST' || request.method=='PUT') &&
			request.urlWithParams.length>0 && 
			String(request.body)=='[object Object]') //&& false
		{	
			const customHeaders = new HttpHeaders({  
				'token': SolicitudInterceptor.token
				,'Content-Type': 'application/json;charset=utf-8'
			});
			
			request = request.clone({
				headers: customHeaders
			});
			
			//console.log("MODIFICADO");
		}
		else
		{
			const customHeaders = new HttpHeaders({  
				'token': SolicitudInterceptor.token
			});
		
			request = request.clone({
				headers: customHeaders
			});
		}
        return next.handle(request);
    }
}
