import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import {CBLService} from 'src/app/servicios/cbl.service'
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Respuesta } from 'src/app/libs/respuesta';
import { formatDate } from '@angular/common';
import { Clientdepartment, Complaint, Documento } from 'src/app/libs/dataObjects'
import { MessageService, SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { GlobaldataService } from '../../servicios/globaldata.service';


@Component({
  selector: 'app-clientdepartment',
  templateUrl: './clientdepartment.component.html',
  styleUrls: ['./clientdepartment.component.scss'],
})
export class ClientdepartmentComponent implements OnInit {

  dateConfig: any;
  clientdepartment: Clientdepartment = new Clientdepartment();
  uploadedFiles: any[] = [];
  readonly: boolean = false;
  remarkBody = {
    comment: null,
    file1: null,
    file2: null,
    file3: null
  };
  _pdf: SafeUrl;
  privacy:any[]=[];
  type:any[]=[];
  status:any[]=[];
  cities:any[]=[];
  selectedCity:any;

  languages: SelectItem[] = [];
 selectedLanguage: any;
 applicant: any = {};
   countries: any[];
   dataLoaded = false;

   public allclientcollaboratorcommunication: any = []; 

  @ViewChild('envioDoc') envioDoc: FileUpload;
  @ViewChild('fileUpload') fileUpload: any;
  @ViewChild('fileUpload2') fileUpload2: any;
  @ViewChild('fileUpload3') fileUpload3: any;


  @ViewChild('attachDocument') attachDocument: FileUpload;
  history: Respuesta;
  mes: string;
  selectedTeam: string;
  complaintStatus: string;
  subcompanies: any;
  categoryList: any[];
  clientesAll: any[];
  access:any[]=[];
  selectedAccess:any[]=[];

  constructor(public cbl: CBLService, private messageService: MessageService,
    private httpClient: HttpClient, public dialogService: DialogService, public ref: DynamicDialogRef,public gds:GlobaldataService,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer) {

    let id = this.config.data.id;
    console.log(id);

    if (id) {
      //id=18;

      //alert(id);
      this.loadClientdepartment(id); 
      //this.getIncendias(id);
      this.readonly = true;
    }
    else {
      this.clientdepartment = new Clientdepartment();
     // this.complaint.fechainicio = new Date();
      this.readonly = false;
      
    }

    this.dateConfig = this.cbl.dateConfig;




  }

  ngOnInit(): void {

    this.mes = "Confidential";
    //this.selectedCity="Rome";
    this.selectedCity = {name:'Rome'};
    console.log("rrrrrrrrrrrrrrr",this.mes);


   this.showClientdepartmentCommunication();

    this.countries = [
      {label:'Select Country', value:null}
  ];
  
  //setTimeout(() => {
  let result = [
  {id: 1, name: 'Pakistan'},
  {id: 2, name: 'India'},
  {id: 3, name: 'Sri Lanka'},
  ];
  
  result.forEach(function(country) {
  this.countries.push({label: country.name, value: country.name});
  }, this);
  
  this.dataLoaded = true;
  
  // }, 1000);
  
  
  
  //this.applicant = {country: 'India'};
  


  // this.privacy = [
  //   { label: 'Anonymous', value: 'Anonymous' },
  //   { label: 'Confidential', value: 'Confidential' },
   
  // ];

  this.privacy = [
    { label: 'ANONIMO', value: 'ANONIMO' },
    { label: 'CONFIDENCIAL', value: 'CONFIDENCIAL' },
   
  ];
  this.loadcompanylist();
 

  // this.type = [
  //   { label: 'Harassment', value: 'Harassment' },
  //   { label: 'Misbehavior', value: 'Misbehavior' },
  //   { label: 'Misappropriation of resources', value: 'Misappropriation of resources' },
  //   { label: 'Safety and occupational risks', value: 'Safety and occupational risks' },
  //   { label: 'Others', value: 'Others' },
   
  // ];


  this.type = [
    { label: 'Acoso', value: 'Acoso' },
    { label: 'Mala conducta', value: 'Mala conducta' },
    { label: 'Apropiación indebida de recursos', value: 'Apropiación indebida de recursos' },
    { label: 'Seguridad y riesgos laborales', value: 'Seguridad y riesgos laborales' },
    { label: 'Otros', value: 'Otros' },
   
  ];


  // this.status = [
  //   { label: 'SENT', value: 'SENT' },
  //   { label: 'READ', value: 'READ' },
  //   { label: 'ANALYSIS', value: 'ANALYSIS' },
  //   { label: 'INVESTIGATION', value: 'INVESTIGATION' },
  //   { label: 'COMMUNICATION TO THE COMPLAINT', value: 'COMMUNICATION TO THE COMPLAINT' },
  //   { label: 'RESOLVED', value: 'RESOLVED' },
  // ];

  this.status = [
    { label: 'ENVIADO', value: 'ENVIADO' }, 
    { label: 'LEIDO', value: 'LEIDO' },
    { label: 'ANALISIS', value: 'ANALISIS' },
    { label: 'INVESTIGACIÓN', value: 'INVESTIGACIÓN' },
    { label: 'COMUNICACIÓN AL DENUNCIANTE', value: 'COMUNICACIÓN AL DENUNCIANTE' },
    { label: 'RESUELTA', value: 'RESUELTA' },
  ];



  this.access = [
    { label: 'access_documents_recent', value: '1' }, 
    { label: 'access_documents_senddocument', value: '1' },
    { label: 'access_documents_mydocument', value: '1' },
    { label: 'access_documents_taxes', value: '1' },
    { label: 'access_consultation', value: '1' },
    { label: 'access_issues', value: '1' },
    { label: 'access_information', value: '1' },
    { label: 'access_labor_incidences', value: '1' },
    { label: 'access_labor_names', value: '1' },
    { label: 'access_labor_payrollsummery', value: '1' },
    { label: 'access_labor_docemployees', value: '1' },
    { label: 'access_labor_paidgrandchildres', value: '1' },
    { label: 'access_labor_employeeportal', value: '1' },
    { label: 'access_labor_request', value: '1' },
    { label: 'access_labor_entryexit_request', value: '1' },
    { label: 'access_labor_time', value: '1' },
    { label: 'access_labor_checkpermissions', value: '1' },
    { label: 'access_labor_globalquery', value: '1' },
    { label: 'access_labor_complaintsportal', value: '1' },
    { label: 'access_mydata', value: '1' },
    { label: 'access_collaborator', value: '1' },
    { label: 'access_mylatest_documents', value: '1' },
    { label: 'access_mylatest_affairs', value: '1' }
    
  ];

  //this.clientcollaborator.clientcollaboratorStatus='ENVIADO';



this.applicant = {country: 'India'}; 
//this.clientcollaborator.clientId =5001;

//alert(this.gds.id_cliente);
this.clientdepartment.clientId = this.gds.id_cliente;
    

  }
  

  accessSelection(acc,event) {
    console.log("event...",event.checked)

    if (this.selectedAccess.includes(acc.label)) {
      this.selectedAccess = this.selectedAccess.filter(data => data != acc.label)
    } else {
      this.selectedAccess.push(acc.label)
    }
    console.log("this.acc", acc)
    console.log("this.selectedAccess", this.selectedAccess)
  }
  accessSelectionSelectAll(access,event) {
    console.log("event...all",event.checked)

    console.log("empleados", access)
    if(event.checked==true){

      this.selectedAccess = access.map(data => data.label)
    }else{
      this.selectedAccess=[];
    }
  }










   public loadcompanylist()
  {

    this.gds.subCompanyObservable.subscribe((data: any) => {
      console.log("name....",data);
      this.subcompanies = data;
     
     });
   
     console.log("subcompanies....",this.subcompanies);



      let data=this.subcompanies;
      let mainList = [];
      let idClienteArr=[];
      for (let s=0;s<data.length;s++) {
      let list = {};
      //console.log("xxxxxxxxxxxxxxxx",data[s]);
      
      // list["idCliente"] = data[s].idCliente; 
      // list["Cliente"] =  data[s].Cliente;
      // list["isSelected"] = true;
      list["label"] = data[s].Cliente; 
      list["value"] =  data[s].idCliente;
      //list["isSelected"] = true;
      mainList.push(list);
      idClienteArr.push(data[s].idCliente);
      }
      console.log("subcompanies refine....",mainList);
      console.log("idClienteArr refine....",idClienteArr);
      this.categoryList=mainList;
      this.clientesAll=idClienteArr;

  }
  async getEmployeeName(id){
    let url2='empleado?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=E.idEmpleado as idEmpleado,T.empleado as empleado'
    +'&where=E.idEmpleado='+id+' '
    +'&order=E.idEmpleado DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['empleado'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }

  async getClientName(id){
    let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idCliente,Cliente'
    +'&where=idCliente='+id+' '
    +'&order=idCliente DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['Cliente'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }

  
async getPersenInChargeName(id){
  let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  +'&fields=idCliente,Cliente,encargadoDenuncias'
  +'&where=idCliente='+id+' '
  +'&order=idCliente DESC';  //&compacto=true

  //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

 // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

  //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
  var respuesta;
  await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
    let resp=r['resp'];
    respuesta=resp[0]['encargadoDenuncias'];
    //this.nominasTemp = r['resp'];
    console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
    /*this.total=0.0;
    this.nominas=this.nominas.map((nom)=>{
       if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
       return nom;
    });
    console.log(this.nominas,this.total);*/
  }); 

 return respuesta;
}









  showClientdepartmentCommunication()
  {
    
		// let url="fields=E.idempleado as value,T.empleado,S.idsolicitud";
		// url=url+"&joins=trabajador,solicitudes(E.idempleado=>S.idempleado)";
		// // url=url+"&where=E.idcliente="+this.gds.id_cliente+" and year(S.fechainicio)='"+this.ejercicio+"'";
    
		// url=url+"&groupby=E.idempleado,T.empleado"
		// url=url+"&order=T.empleado";

    let url2='complaintcommunication?'
    +'&fields=idComplaintCommunication,idComplaint,complaintCommunication,communicationType,communicationByEmployeetId,communicationByClientId,communicationDate';
    url2+='&where=idComplaint="'+this.config.data.id+'"  ';
   
    url2+='&order=idComplaintCommunication ASC';
    console.log("url.....fff............",url2);
		// this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).subscribe((res)=>{
    //   console.log("data.......",res.resp);
		// 	this.allcomplaintcommunication=res.resp;      
      
		// });


    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL+url2).toPromise().then(async (r)=>{
      //console.log(r);
      console.log("vvvvvvvvvv",r.resp);
      for(let p of r.resp)
      {
        //let dias=0;
        // for(let d of p.permisos)
        // {
          // let cd=p.fechas.split(",").length;
          // p['dias']=cd; 
          // if (cd>1)
          //   p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(p.fechafin,'dd/MM/yyyy','en-US');
          // else
          //   p['periodo']=p.fechainicio;

          // if(p.horainicio!=null && p.horafin!=null)
          // {
          //   p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
          // }
          // if(p.estado!="RECHAZADA") dias+=cd;

         // p['periodo']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(p.complaintDate,'dd/MM/yyyy','en-US');

         // p['periodo']=p.complaintDate;
        // }
        // let respEmp=this.getEmployeeName(p.idEmpleado);
        // console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
        //  p['dias']=dias;
        //  p['employeeName']=await this.getEmployeeName(p.idEmpleado);

       // let respEmp=this.getEmployeeName(p.idEmpleado);
        //console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
        // p['dias']=dias;
         if(p.communicationType=='Employee')
         {
          p['userName']=await this.getEmployeeName(p.communicationByEmployeetId);
         }
         else{ 
          p['userName']=await this.getClientName(p.communicationByClientId);
         }
         p['communicationDate']=formatDate(p.communicationDate,'dd/MM/yyyy HH:mm','en-US');
        //  p['complaintNodays']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(new Date(),'dd/MM/yyyy','en-US');

         
        
         
      }
      console.log(r.resp);
     // this.complaints=r.resp;
      this.allclientcollaboratorcommunication=r.resp;
      
    });
  }





  async checkUniqueUserName(username) {
   

    var respuesta;

    await this.httpClient.get<Respuesta>(this.cbl.urlBaseDL + 'client_department/checkUniqueUserName/' + username).toPromise().then((r)=>{

      console.log("xxxxxxxxxxxxxxxxxxxx.....",r[0])
      let resp=r[0].department_email;
      console.log("dddddddddddddddddd.....",r[0].department_email)
      if(resp==0)
      {
        respuesta=true;
      }
      else{
        respuesta=false;
      }
      
      //this.nominasTemp = r['resp'];
     // console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
      
    }); 
    return respuesta;




  }












  async solicitar() {
//alert(this.clientdepartment.clientId);
    if (this.clientdepartment.clientId == undefined || this.clientdepartment.clientId == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Department client need to enter!' });
      return;
    }


    if (this.clientdepartment.department_name == undefined || this.clientdepartment.department_name == "") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Department Name need to enter!' });
      return;
    }


    if (this.clientdepartment.department_email == undefined || this.clientdepartment.department_email == "") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Department Email need to enter!' });
      return;
    }


    console.log("xxxxxxmmmmm",this.checkUniqueUserName(this.clientdepartment.department_email));

// if (await this.checkUniqueUserName(this.clientdepartment.department_email) === false) {
//   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Department Email should be Unique!' });
//   return;
// }




   
   

   


       


    //this.clientcollaborator.clientId = this.cbl.id_cliente;
    this.clientdepartment.clientId = this.clientdepartment.clientId;
    this.clientdepartment.department_name = this.clientdepartment.department_name;
    this.clientdepartment.department_email = this.clientdepartment.department_email;
    //this.clientdepartment.collab_password = this.clientdepartment.collab_password;
    this.clientdepartment.stampDate = this.clientdepartment.stampDate;
    //this.clientcollaborator.selectedAccess = this.selectedAccess;

    // this.clientcollaborator.access_documents_recent = this.clientdepartment.access_documents_recent;
    // this.clientcollaborator.access_documents_senddocument	 = this.clientdepartment.access_documents_senddocument	;
    // this.clientcollaborator.access_documents_mydocument = this.clientdepartment.access_documents_mydocument;
    // this.clientcollaborator.access_documents_taxes = this.clientcollaborator.access_documents_taxes;
    // this.clientcollaborator.access_consultation = this.clientcollaborator.access_consultation;
    // this.clientcollaborator.access_issues = this.clientcollaborator.access_issues;
    // this.clientcollaborator.access_information = this.clientcollaborator.access_information;
    // this.clientcollaborator.access_labor_incidences = this.clientcollaborator.access_labor_incidences;
    // this.clientcollaborator.access_labor_names = this.clientcollaborator.access_labor_names;
    // this.clientcollaborator.access_labor_payrollsummery = this.clientcollaborator.access_labor_payrollsummery;
    // this.clientcollaborator.access_labor_docemployees = this.clientcollaborator.access_labor_docemployees;
    // this.clientcollaborator.access_labor_paidgrandchildren = this.clientcollaborator.access_labor_paidgrandchildren;
    // this.clientcollaborator.access_labor_employeeportal = this.clientcollaborator.access_labor_employeeportal;
    // this.clientcollaborator.access_labor_request = this.clientcollaborator.access_labor_request;
    // this.clientcollaborator.access_labor_entryexit_request = this.clientcollaborator.access_labor_entryexit_request;
    // this.clientcollaborator.access_labor_time = this.clientcollaborator.access_labor_time; 
    // this.clientcollaborator.access_labor_checkpermissions = this.clientcollaborator.access_labor_checkpermissions;
    // this.clientcollaborator.access_labor_globalquery = this.clientcollaborator.access_labor_globalquery;
    // this.clientcollaborator.access_labor_complaintsportal = this.clientcollaborator.access_labor_complaintsportal;
    // this.clientcollaborator.access_mydata = this.clientcollaborator.access_mydata;
    // this.clientcollaborator.access_collaborator = this.clientcollaborator.access_collaborator;
    // this.clientcollaborator.access_mylatest_documents = this.clientcollaborator.access_mylatest_documents;
    // this.clientcollaborator.access_mylatest_affairs = this.clientcollaborator.access_mylatest_affairs;
    
    this.cbl.insertClientdepartment(this.clientdepartment).then((p) => {
          if (p) {

          console.log("ooooooooooooookkkkkkkkkkkk",p);  
          var idClient_department=p.idClient_department; 

          console.log("ooooooooooooookkkkkkkkkkkk",idClient_department);

          this.cbl.emailToClienteDepartment(idClient_department, "Nuevo Department", `New department Added`);

          this.ref.close(true); 

          }
          });
    // {
    //   this.cbl.emailToClienteColaborator(this.cbl.id_cliente, "Ha recibido una nueva solicitud en el Portal del Cliente", `Estimado ${this.cbl.name_cliente}:\n\nUn empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de incidencia << ${this.complaint.solicitud} >>.\n\nSaludos`);
    //    this.ref.close(true);
    // }


  }

 



  async checkUniqueUserNameEdit(idClient_department,username) {
   

    var respuesta;

    await this.httpClient.get<Respuesta>(this.cbl.urlBaseDL + 'client_department/checkUniqueUserNameEdit/' + username+'/'+idClient_department).toPromise().then((r)=>{

      console.log("xxxxxxxxxxxxxxxxxxxx.....",r[0])
      let resp=r[0].department_email;
      console.log("dddddddddddddddddd.....",r[0].department_email)
      if(resp==0)
      {
        respuesta=true;
      }
      else{
        respuesta=false;
      }
      
      //this.nominasTemp = r['resp'];
     // console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
      
    }); 
    return respuesta;




  }













  async saveClientdepartment() {  

//alert(this.clientcollaborator.clientId);
//console.log("aaaaaaaaaaaaaa--------------aaaaaaaaaaaaaa",this.selectedAccess);

if (this.clientdepartment.clientId == undefined || this.clientdepartment.clientId == 0) {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Collab client need to enter!' });
  return;
}


if (this.clientdepartment.department_name == undefined || this.clientdepartment.department_name == "") {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Collab Name need to enter!' });
  return;
}




if (this.clientdepartment.department_email == undefined || this.clientdepartment.department_email == ""    ) {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Collab Email need to enter!' });
  return;
}


//console.log("xxxxxxmmmmm",this.checkUniqueUserNameEdit(this.clientcollaborator.collab_email));

// if (await this.checkUniqueUserNameEdit(this.clientdepartment.idClient_department,this.clientdepartment.department_email) === false) {
//   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Collab User should be Unique!' });
//   return; 
// }


// if (this.clientdepartment.collab_password == undefined || this.clientdepartment.collab_password == "") {


//   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Collab Password need to enter!' });
//   return;
// }

    // this.httpClient.put(this.gds.urlBaseDL+'clientes/'+this.gds.id_cliente,{idComplaint:this.complaint.idComplaint,complaintStatus:this.complaint.complaintStatus,complaintCommunication:this.complaint.complaintCommunication,complaintHandledClientId:this.complaint.complaintHandledClientId}).subscribe((res)=>{
    //   console.log(res);
    //   // this.datosCliente=Object.assign({},this.datosClienteLocal);
    //   // this.messageService.add({severity:'success', summary:'', detail:'Datos actualizados!'});
    // });

   //alert("idComplaint:"+this.complaint.idComplaint+",complaintStatus:"+this.complaint.complaintStatus+",complaintCommunication:"+this.complaint.complaintCommunication+",complaintHandledClientId:"+this.complaint.complaintHandledClientId);

// this.httpClient.put(this.cbl.urlBaseDL + 'complaint/' + this.complaint.idComplaint, {idComplaint:this.complaint.idComplaint,complaintStatus:this.complaint.complaintStatus,complaintCommunication:this.complaint.complaintCommunication,complaintHandledClientId:this.complaint.complaintHandledClientId}).toPromise();




         
        this.httpClient.post(this.cbl.urlBaseDL + 'client_department/' + this.clientdepartment.idClient_department, {idClient_department:this.clientdepartment.idClient_department,clientId:this.clientdepartment.clientId,department_name:this.clientdepartment.department_name,department_email:this.clientdepartment.department_email}).toPromise().then((p) => {
          if (p) {

          this.ref.close(true);

          }
          });

    
  }











  clientcollaboratorAttachDocument(complaintAttachDocument: any) {
    throw new Error('Method not implemented.');
  }

  cerrar() {
    this.ref.destroy();
  }



  loadClientdepartment(id) {
    let url2 = 'client_department?'
    +'&fields=idClient_department,clientId,department_name,department_email'
    + '&where=idClient_department=' + id;

    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL + url2).toPromise().then(async (r) => {
      console.log(r.resp);
      // r.resp[0].horainicio = r.resp[0].horainicio.substr(0, 5);
      // r.resp[0].horafin = r.resp[0].horafin.substr(0, 5);
      // r.resp[0].fechainicio = formatDate(r.resp[0].fechainicio, 'dd/MM/yyyy', 'en-US');
      r.resp[0].idClient_department = r.resp[0].idClient_department;
      r.resp[0].clientId = r.resp[0].clientId;
      r.resp[0].department_name = r.resp[0].department_name;
      r.resp[0].department_email = r.resp[0].department_email;
     // r.resp[0].collab_password = r.resp[0].collab_password;
     // r.resp[0].stampDate = formatDate(r.resp[0].stampDate, 'dd/MM/yyyy', 'en-US');

      
      // if(r.resp[0].complaintHandledClientId!=0)
      // {
        
      //   r.resp[0].complaintHandledClientName = await this.getPersenInChargeName(r.resp[0].complaintHandledClientId); 
      // }
      // else{
      //   r.resp[0].complaintHandledClientName = '';
      // }
      
       this.clientdepartment = new Clientdepartment(r.resp[0]);
      //this.complaint = r.resp[0];
      //this.complaint.fechafin = this.complaint.fechainicio;

      console.log("aaaaaaaaaaaaaaaaaaaaaaa",this.clientdepartment);
    });
  }

  /**functions to cpature comments and upload files start */
  onBasicUpload(inputData, key) {
    for (const file of inputData.currentFiles) {
      const dataset = this.readFile(file, key);
    }
  }

  private readFile(file: File, key) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.remarkBody[key] = JSON.stringify(reader.result);
    };
  }

  saveComment_old() {
    this.httpClient.post<Respuesta>(this.cbl.urlBaseDL + 'incidenciaremark/' + this.config.data.id, this.remarkBody).toPromise().then((r) => {
      if (r) {
        this.remarkBody.comment = null;
        this.remarkBody.file1 = null;
        this.remarkBody.file2 = null;
        this.remarkBody.file3 = null;
        this.getIncendias(this.config.data.id);
        this.clear();
      }
    });
  }
  /**functions to cpature comments and upload files end */
  getIncendias(id) {
    this.httpClient.post<Respuesta>(this.cbl.urlBaseDL + 'getincidenciaremark/' + id, {}).toPromise().then((r) => {
      this.history = r;
    });
  }

  showpdf(file) {
    let fileArray:any[] = file.split(",");
      const [,type] = fileArray[0].split(';')[0].split('/');
      const linkSource = file;
      const downloadLink = document.createElement("a");
      const fileName = "document."+type;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
  }

  clear() {
    this.fileUpload.clear();
    this.fileUpload2.clear();
    this.fileUpload3.clear();

    this.attachDocument.clear();
    
  }
}
