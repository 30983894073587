<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Portal de empleados</p>
       
<div class="p-grid p-justify-center">
    <div class="p-col-8 col-8">
        <div class="p-d-flex p-jc-center">
        <p-button (click)="invitar()">Invitar a todos los seleccionados</p-button>
    </div>
    </div> 
   
    <div class="p-col-12 col-12">
<p-table  [(selection)]="selectedEmpleados" [value]="empleados">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Empleado</th>
            <th>Whats app</th>
            <th>Departmento</th>
            <th>Email (obligatorio para usar Portal del empleado)</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-empleado>
        <tr>
            <td>
                <p-tableCheckbox [value]="empleado"></p-tableCheckbox>
            </td>
            <td><i *ngIf="empleado.invitado" class="pi pi-check"></i>{{empleado.empleado}}</td>
            <td>{{empleado.telefonomovil}}</td>
            <td>  
                <!-- {{empleado.idClient_department}} -->
                <p-dropdown placeholder="Seleccione"  id="idClient_department"  [options]="departmentList" [(ngModel)]="empleado.idClient_department"  [showClear]="false" (onChange)="onChangeEmp(empleado.idEmpleado,$event)" ></p-dropdown>
                <!-- {{empleado.idEmpleado}} --> 
            </td>
            <td>{{empleado.email}}</td>            
        </tr>
    </ng-template>
</p-table>
</div>
</div>

<p-toast position='center'></p-toast>