import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {Table} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { Respuesta } from 'src/app/respuesta';
import { formatDate } from '@angular/common';
import { UtilService } from 'src/app/servicios/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Dropdown } from "primeng/dropdown";

//X3517088C  --  123456

import {CBLService} from 'src/app/servicios/cbl.service'
import { ClientprojectComponent } from '../clientproject/clientproject.component';
import { ClientprojectdetailComponent } from '../clientprojectdetail/clientprojectdetail.component';

export interface TiposClientproject {
  nombre: string;
  id: string;
}

export class Clientproject {
  estado: string;
  fechaInicio: Date;
  fechaFin: Date;
  observaciones: string;
  tipoComplaint: number;
  adjuntos: any[] = [];
  tipo: string;
  motivo: string;
  
}
export interface Empleado{
  value:number;
  empleado:string;
}

@Component({
  selector: 'app-projects',
  templateUrl: './clientprojects.component.html',
  styleUrls: ['./clientprojects.component.scss'],
})
export class ClientprojectsComponent implements OnInit {
  @ViewChild('dropDownThing')  dropDownThing: Dropdown;
  tiposClientprojects: TiposClientproject[];
  selectedTipoClientproject: TiposClientproject;
  selectedClientproject: Clientproject = null;
  tempClientproject: Clientproject = new Clientproject();
  collapsed: boolean = true;
  yearArray:any[] = [];
  selectedYear:any;
  empList: any[];
  projectList: any[];
  
  public empleados:Empleado[]=[];




  selectedEmployee:any;
  selectedProject:any;
  selectedFromDate:any;
  selectedToDate:any;
  dateConfig: any; 



  message = "Documentos del año";
  currentSearchYear:any;
  es: any;
  dateFrom: Date;
  dateTo: Date;
  clientprojectsTemp =[]
  clientprojects: Clientproject[] = [
    {
      estado: 'Pendiente',
      tipo: 'Asistencia al médico',
      fechaInicio: new Date(),
      fechaFin: null,
      observaciones: 'Se encuentra en estado pendiente en el lugar.',
      tipoComplaint: 1,
      adjuntos: [
        { name: 'Cita previa en clínica estomatológica', size: '30.000' },
      ],
      motivo: '',
    },
    {
      estado: 'Rechazada',
      tipo: 'Olvido de fichaje',
      fechaInicio: new Date(),
      fechaFin: new Date(),
      observaciones: '',
      tipoComplaint: 2,
      adjuntos: [],
      motivo:
        'Falta de documentación acreditativa sobre el motivo de la ausencia',
    },
  ];

  uploadedFiles: any[] = [];

  constructor(public cbl:CBLService,private messageService: MessageService,private httpClient: HttpClient,public dialogService: DialogService,public gds:GlobaldataService) {
    this.tiposClientprojects = [
      { nombre: 'Asistencia al médico', id: '1' },
      { nombre: 'Olvido de fichaje', id: '2' },
    ];
  }

  ngOnInit(): void {
    this.createYearPicker();
    this.loadClientprojects();
    this.filtrarEmp();
    this.loadcompanylist();
    this.selectedEmployee='';
    this.selectedProject='';
    this.selectedFromDate='';
    this.selectedToDate='';

    
    this.dateConfig = this.cbl.dateConfig;
    this.es = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      today: 'Hoy',
      clear: 'Borrar',
    };
  }

  onUpload(event) {
    for (let file of event.files) {
      this.tempClientproject.adjuntos.push(file);
    }
  }

  onRowSelect(event) {
    this.collapsed = false;
    this.tempClientproject = this.selectedClientproject;
    // this.selectedTipoClientcollaborator = this.tiposClientcollaborators[
    //   this.tempClientcollaborator.estado - 1
    // ];
  }
  onRowUnselect(event) { 
    this.collapsed = true;
    this.tempClientproject = new Clientproject();
    this.selectedClientproject = null;
    this.selectedTipoClientproject = null;
  }

  crearClientproject(event) {
    this.collapsed = true;
  }

  selectClientprojectDetail(id,pagetype){
    const ref = this.dialogService.open(ClientprojectdetailComponent, {
      data:{id:id,pagetype:pagetype},
      header: 'Detalles  ', 
      width: '70%'
      });

      ref.onClose.subscribe((ret) => {

        this.loadClientprojects();
        this.selectedYear = null
        if(this.dropDownThing != undefined && this.dropDownThing != null)
          this.dropDownThing.updateSelectedOption(null);
        this.loadClientprojects();
       
      });

  }


  public filtrarEmp()
  {
	  
		let url="fields=E.idempleado as value,T.empleado,S.idsolicitud";
		url=url+"&joins=trabajador,solicitudes(E.idempleado=>S.idempleado)";
		// url=url+"&where=E.idcliente="+this.gds.id_cliente+" and year(S.fechainicio)='"+this.ejercicio+"'";
    url=url+"&where=E.baja='0' and E.idcliente="+this.gds.id_cliente+" ";
		url=url+"&groupby=E.idempleado,T.empleado"
		url=url+"&order=T.empleado";
    console.log("url.................",url);
		this.httpClient.get<Respuesta>(this.gds.urlBaseDL+"empleado?"+url).subscribe((res)=>{
      console.log("data.......",res.resp);
			this.empleados=res.resp;      
      
     
		});
  }

  selectClientproject(id){
    const ref = this.dialogService.open(ClientprojectComponent, {
      data:{id:id},
      header: 'Detalles',  
      width: '70%'
      });

      ref.onClose.subscribe((ret) => {

        this.loadClientprojects();
        this.selectedYear = null
        if(this.dropDownThing != undefined && this.dropDownThing != null)
          this.dropDownThing.updateSelectedOption(null);
        this.loadClientprojects();
       
      });

  }
  deleteClientproject(idClient_project,project_name){
    //alert(idClient_collaborator);
    if(confirm("Are you sure to delete "+project_name+"? ")) {
      //console.log("Implement delete functionality here");




      this.httpClient.delete(this.cbl.urlBaseDL + 'client_project/' + idClient_project, {}).toPromise().then((p) => {
        if (p) {



          this.httpClient.delete(this.cbl.urlBaseBL + 'client_project_employee/' + idClient_project, {}).toPromise().then((k) => {
            if (k) {
      
              this.loadClientprojects();
      
            }
            });
  
          this.loadClientprojects();
  
        }
        });

    }

   

  }
  nueva()
  {
    const ref = this.dialogService.open(ClientprojectComponent, {
      data:{id:0,pagetype:'edit'},
      header: 'Detalles',
      width: '70%'
      });

    ref.onClose.subscribe((ret) => {

      this.loadClientprojects(); 
      this.selectedYear = null
      if(this.dropDownThing != undefined && this.dropDownThing != null)
        this.dropDownThing.updateSelectedOption(null);
      this.loadClientprojects();
     
    });
    
  }

  public async loadcompanylist()
  {

    this.gds.subCompanyObservable.subscribe((data: any) => {
      console.log("name....",data);
      //this.subcompanies = data;
     
     });
   
     //console.log("subcompanies....",this.subcompanies);



      //let data=this.subcompanies;
      let data=await this.getAllEmployee();
      console.log("xxxxxxxxxxxxxxxx--------",data);
      let mainList = [];
      //let idClienteArr=[];
      for (let s=0;s<data.length;s++) {
      let list = {};
      list["label"] = data[s].empleado; 
      list["value"] =  data[s].idEmpleado;
       mainList.push(list);
      //idClienteArr.push(data[s].idCliente);
      }


      

      console.log("subcompanies refine....",mainList);
      //console.log("idClienteArr refine....",idClienteArr);
      this.empList=mainList;
      //this.clientesAll=idClienteArr;



      let data1=await this.getAllProject();
      console.log("xxxxxxxxxxxxxxxx--------",data);
      let mainList1 = [];
      //let idClienteArr=[];
      for (let s=0;s<data1.length;s++) {
      let list1 = {};
      list1["label"] = data1[s].project_name; 
      list1["value"] =  data1[s].idClient_project;
      mainList1.push(list1);
      //idClienteArr.push(data[s].idCliente);
      }

      console.log("ppppppppppppppppppppppp refine....",mainList1);
      this.projectList=mainList1;



  }



  async getAllEmployee(){

    //alert(this.gds.id_cliente);
    let url2='empleado?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=E.idEmpleado as idEmpleado,E.baja,T.empleado as empleado'
    +'&where=E.idCliente='+this.gds.id_cliente+' and E.baja=0 '
    //+'&where=E.idCliente='+this.gds.id_cliente+''
    //+'&order=E.idEmpleado DESC';  //&compacto=true
    + '&groupby=E.idEmpleado'
    + '&order=T.empleado ASC'; 

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp;
      //this.nominasTemp = r['resp'];
     // console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      console.log("xxxxxxgogoxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }


  async getAllProject(){

    //alert(this.gds.id_cliente);
    let url2='client_project?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idClient_project,project_name'
    +'&where=clientId='+this.gds.id_cliente+' '
    //+'&where=E.idCliente='+this.gds.id_cliente+''
    //+'&order=E.idEmpleado DESC';  //&compacto=true
    //+ '&groupby=E.idEmpleado'
    + '&order=project_name ASC'; 

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp;
      //this.nominasTemp = r['resp'];
     // console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      console.log("xxxxxxgogoxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }



  async getEmployeeName(id){
    let url2='empleado?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=E.idEmpleado as idEmpleado,T.empleado as empleado'
    +'&where=E.idEmpleado ='+id+' '
    +'&order=E.idEmpleado DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['empleado'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/ 
    }); 

   return respuesta;
  }
  async getClientName(id){
    let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idCliente,Cliente'
    +'&where=idCliente='+id+' '
    +'&order=idCliente DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['Cliente'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }


  
  pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
 

  async getTotalHourWorked(employees,idClient_project){
    let url2='client_project_employee_horario?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=sum(hour) as hour,sum(minute) as minute,sum(second) as second'
    +'&where=idClient_project='+idClient_project+'  and idEmpleado IN('+employees+')  '
    +'&order=idClient_project_employee_horario DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    //var hour;
    //var minute;
    //var second;
   var TotalTime=0;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      
      let resp=r['resp'];
      if(resp)
      {
      console.log("xxxxxxxxxxxxxgogogxxxxxxx.....",resp)
      
      var hour=resp[0]['hour'];
      var minute=resp[0]['minute'];
      var second=resp[0]['second'];

      if(hour=='' || hour==null)
      {
       hour='00';
      }
   
      if(minute=='' || minute==null)
      {
       minute='00';
      }
   
      if(second=='' || second==null)
      {
       second='00';
      }
   
    
   var hour = this.pad(hour, 2,'');

  
   var minute = this.pad(minute, 2,'');


   var second = this.pad(second, 2,'');
   

 

    var itemTime=hour+":"+minute+":"+second;
    var at = itemTime.split(':'); 
    var timeInSec = (+at[0]) * 60 * 60 + (+at[1]) * 60 + (+at[2]); 
      
      //this.nominasTemp = r['resp'];
      
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/

      TotalTime+=TotalTime+timeInSec;


    }
    }); 

    

   return TotalTime;
  }


async getPersenInChargeName(id){
  let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  +'&fields=idCliente,Cliente,encargadoDenuncias'
  +'&where=idCliente='+id+' '
  +'&order=idCliente DESC';  //&compacto=true

  //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

 // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

  //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
  var respuesta;
  await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
    let resp=r['resp'];
    respuesta=resp[0]['encargadoDenuncias'];
    //this.nominasTemp = r['resp'];
    console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
    /*this.total=0.0;
    this.nominas=this.nominas.map((nom)=>{
       if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
       return nom;
    });
    console.log(this.nominas,this.total);*/
  }); 

 return respuesta;
}






 secondsToTime(e){
  const h = Math.floor(e / 3600).toString().padStart(2,'0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
        s = Math.floor(e % 60).toString().padStart(2,'0');
  
  return h + ':' + m + ':' + s;
  //return `${h}:${m}:${s}`;
}


  loadClientprojects()
  {
    // let url2='solicitudes?'
    // +'&fields=idsolicitud,solicitud,fechainicio,obs,fechafin,horainicio,horafin,fechas,estado'
    // +'&where=idEmpleado='+this.cbl.id_empleado+" and idTipoPermiso=0"
    // +'&order=fechainicio DESC';

    // let url2='complaint?'
    // +'&fields=idComplaint,idEmpleado,complaintDate,complaintPrivacy,complaintType,complaintAttachDocument,complaintDesc,complaintStatus,complaintCommunication'
    // +'&where=idEmpleado='+this.cbl.id_empleado+" "
    // +'&order=idComplaint DESC'; 


    console.log("xxxxxxxxxxxxxx",this.gds);



    let url2='client_project?'
    +'&fields=idClient_project,clientId,project_name,employees,startDate,endDate,total_no'
    +'&where=clientId='+this.gds.id_cliente+""
    +'&order=idClient_project DESC'; 

    //+'&tree=solicitud->permisos=>fechainicio,fechafin,horainicio,horafin,fechas,estado';

    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL+url2).toPromise().then(async (r)=>{
      console.log(r);
      
      for(let p of r.resp)
      {
          let dias=0;
          // for(let d of p.permisos)
          // {
            // let cd=p.fechas.split(",").length;
            // p['dias']=cd; 
            // if (cd>1)
             // p['stampDate']=formatDate(p.stampDate,'dd/MM/yyyy','en-US');
            // else
            //   p['periodo']=p.fechainicio;

            // if(p.horainicio!=null && p.horafin!=null)
            // {
            //   p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
            // }
            // if(p.estado!="RECHAZADA") dias+=cd;

           // p['periodo']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(p.complaintDate,'dd/MM/yyyy','en-US');

           // p['periodo']=p.complaintDate;
          // }
          // let respEmp=this.getEmployeeName(p.idEmpleado);
          // console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
          //  p['dias']=dias;
           p['clientName']=await this.getClientName(p.clientId);
          

           

         // let respEmp=this.getEmployeeName(p.idEmpleado);
          //console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
          //  p['dias']=dias;

            var employees=p.employees;
            if (employees.charAt(employees.length - 1) === ',') {
              employees = employees.slice(0, -1);
            }
            var employees = (employees[0] == ',') ? employees.substr(1) : employees;

            let employeesArr=employees.split(",");

            var allEmp="";
            var allEmpArr=[];
            for (var index in employeesArr) {
            let idEmpleado=employeesArr[index];
            //let idEmpleado ='13801';
            allEmp+=await this.getEmployeeName(idEmpleado)+"<br>";
            allEmpArr[index]=await this.getEmployeeName(idEmpleado);

            }
          //  03:47:50
            console.log("xxooppp",allEmpArr);
            p['employeeName']=allEmpArr;

           // p['total_hour_worked']= await this.getTotalHourWorked(employees,p.idClient_project);


            var itemHourTemp=await this.getTotalHourWorked(employees,p.idClient_project);
           p['total_hour_worked']= this.secondsToTime(itemHourTemp);
            // p['employeeName']=await this.getEmployeeName(idEmpleado);
          // if(p.complaintHandledClientId!='0')
          // {
          //   //p['complaintInCharge']=await this.getClientName(p.complaintHandledClientId);
          //   p['complaintInCharge']=await this.getPersenInChargeName(p.complaintHandledClientId);
          // }
          // else{
          //   p['complaintInCharge']="";
          // }
           


           
           //this.getEmployeeName(p.idEmpleado);
          //r.resp.push(p);
         // r.resp.push(m);
      }
      console.log("sssssssssssssssssssss",r.resp);
      this.clientprojects=r.resp;
      this.clientprojectsTemp=r.resp;
      //this.searchBasedOnYear();
     // this.searchBasedOnProject();
    });
  }
  createYearPicker(){
    let currentYear = new Date().getFullYear();
    let earliestYear = 2010;

    while (currentYear >= earliestYear) {
     this.yearArray.push({"year":currentYear})
      currentYear -= 1;
    }
  }



  searchBasedOnProject(){


    
    

   //alert("selectedEmployee : "+this.selectedEmployee+"--"+"   selectedProject : "+this.selectedProject+"selectedFromDate : "+this.selectedFromDate+"    selectedToDate : "+this.selectedToDate);


//     let tempData  = []; 
//     console.log(this.selectedProject)
//     if(this.selectedYear != null && this.selectedYear !== undefined){
//         this.currentSearchYear = ""+this.selectedYear["year"]

//         console.log("dddddddddddddd",this.clientprojectsTemp);
//         this.clientprojectsTemp.forEach(value =>{

//           let yearFromDb:string = ""+value["stampDate"];
//           if(yearFromDb.indexOf(this.currentSearchYear)>-1)  {
//             tempData.push(value);
//           }       
//         });         
//     }else{
//       this.currentSearchYear  = ''
//       this.clientprojectsTemp.forEach(value =>{
//         tempData.push(value);
//       });
//     }
//     this.clientprojects = tempData;

// return false;

console.log("selectedProject--"+this.selectedProject); 

//alert(this.selectedProject);

    let cond='';

    if(this.selectedProject != null && this.selectedProject !== undefined && this.selectedProject !== ''  ){
      cond+=' and idClient_project="'+this.selectedProject+'"';
    }

    if(this.selectedEmployee != null && this.selectedEmployee !== undefined && this.selectedEmployee !== ''  ){
      cond+=' and employees LIKE "@@@,'+this.selectedEmployee+',@@@" ';
    }

    if(this.selectedFromDate != null && this.selectedFromDate !== undefined && this.selectedFromDate !== ''  ){

      let selectedFromDateItem=formatDate(this.selectedFromDate,'yyyy-MM-dd','en-US');

      cond+=' and startDate="'+selectedFromDateItem+'"';
    }


    if(this.selectedToDate != null && this.selectedToDate !== undefined && this.selectedToDate !== ''  ){

      let selectedToDateItem=formatDate(this.selectedToDate,'yyyy-MM-dd','en-US');

      cond+=' and endDate="'+selectedToDateItem+'"';
    }




    let url2='clientproject/client_project?'
    +'&fields=idClient_project,clientId,project_name,employees,startDate,endDate,total_no'
    +'&where=clientId='+this.gds.id_cliente+' '+cond+' '
    +'&order=idClient_project DESC'; 

    //+'&tree=solicitud->permisos=>fechainicio,fechafin,horainicio,horafin,fechas,estado';

    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL+url2).toPromise().then(async (r)=>{
      console.log(r);
      
      for(let p of r.resp)
      {
          let dias=0;
          // for(let d of p.permisos)
          // {
            // let cd=p.fechas.split(",").length;
            // p['dias']=cd; 
            // if (cd>1)
             // p['stampDate']=formatDate(p.stampDate,'dd/MM/yyyy','en-US');
            // else
            //   p['periodo']=p.fechainicio;

            // if(p.horainicio!=null && p.horafin!=null)
            // {
            //   p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
            // }
            // if(p.estado!="RECHAZADA") dias+=cd;

           // p['periodo']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(p.complaintDate,'dd/MM/yyyy','en-US');

           // p['periodo']=p.complaintDate;
          // }
          // let respEmp=this.getEmployeeName(p.idEmpleado);
          // console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
          //  p['dias']=dias;
           p['clientName']=await this.getClientName(p.clientId);

         // let respEmp=this.getEmployeeName(p.idEmpleado);
          //console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
          //  p['dias']=dias;

            var employees=p.employees;
            if (employees.charAt(employees.length - 1) === ',') {
              employees = employees.slice(0, -1);
            }
            var employees = (employees[0] == ',') ? employees.substr(1) : employees;

            let employeesArr=employees.split(",");

            var allEmp="";
            var allEmpArr=[];
            for (var index in employeesArr) {
            let idEmpleado=employeesArr[index];
            //let idEmpleado ='13801';
            allEmp+=await this.getEmployeeName(idEmpleado)+"<br>";
            allEmpArr[index]=await this.getEmployeeName(idEmpleado);

            }

            console.log("xxooppp",allEmpArr);
            p['employeeName']=allEmpArr;
            // p['employeeName']=await this.getEmployeeName(idEmpleado);
          // if(p.complaintHandledClientId!='0')
          // {
          //   //p['complaintInCharge']=await this.getClientName(p.complaintHandledClientId);
          //   p['complaintInCharge']=await this.getPersenInChargeName(p.complaintHandledClientId);
          // }
          // else{
          //   p['complaintInCharge']="";
          // }
           


           
           //this.getEmployeeName(p.idEmpleado);
          //r.resp.push(p);
         // r.resp.push(m);
      }
      console.log("sssssssssssssssssssss",r.resp);
      this.clientprojects=r.resp;
      this.clientprojectsTemp=r.resp;
     // this.searchBasedOnYear();
     // this.searchBasedOnProject();
    });







  }
 
  searchBasedOnYear(){
    let tempData  = []; 
    console.log(this.selectedYear)
    if(this.selectedYear != null && this.selectedYear !== undefined){
        this.currentSearchYear = ""+this.selectedYear["year"]

        console.log("dddddddddddddd",this.clientprojectsTemp);
        this.clientprojectsTemp.forEach(value =>{

          let yearFromDb:string = ""+value["stampDate"];
          if(yearFromDb.indexOf(this.currentSearchYear)>-1)  {
            tempData.push(value);
          }       
        });         
    }else{
      this.currentSearchYear  = ''
      this.clientprojectsTemp.forEach(value =>{
        tempData.push(value);
      });
    }
    this.clientprojects = tempData;
  }
}
