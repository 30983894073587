import { Component, OnInit,Input,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import {MenuItem} from 'primeng/api';     
import {ChartModule} from 'primeng/chart';
import {TableModule} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { FechaTipadaPipe } from '../../libs/fechaTipada.pipe';
import { Utilidades } from '../../libs/utilidades';
import { UtilService } from '../../servicios/util.service';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {FileUpload} from 'primeng/fileupload';
import { Router,ActivatedRoute  } from "@angular/router";

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss'] 
})
export class DocumentosComponent implements OnInit {

	items10: MenuItem[];
	activeItem: MenuItem;	
	
	constructor(private httpClient: HttpClient,public gds:GlobaldataService,private us:UtilService,private messageService: MessageService,
						private router:Router, private routera: ActivatedRoute)
	{
		
	}

	async ngOnInit()
	{ 
		this.items10 = [
			{id:'0',label:'Enviar',icon: 'pi pi-upload',routerLink: ['enviar']},
			{id:'1',label: 'Documentos',icon: 'pi pi-upload',routerLink: ['misdocumentos']},
			//{id:'2',label: 'Facturas', icon: 'pi pi-folder-open',
			//		items:[{id:'20',label: 'Facturas emitidas', icon: 'pi pi-folder-open',command: ()=>{this.menuId=20;}},{id:'21',label: 'Facturas recibidas', icon: 'pi pi-folder-open',command: ()=>{this.menuId=21;}}]
			//},
			{id:'3',label: 'Impuestos y resumen de Nóminas', icon: 'fa fa-fw fa-calendar',routerLink: ['impuestos']
			//,command: ()=>{
			//	this.menuId=3;this.router.navigate(['impuestos'], {relativeTo: this.routera});}
			//}
			}
		];
		
	}

}
