<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Consulta de permisos y licencias consolidadas </p>   


<!-- <div class="grid ">
    <div class="g-w12field">
     <ul>
	<li *ngFor="let company of subcompanies">
    <input type="checkbox" [attr.checked]="true"  (click)="companydoSelection(company.idCliente,$event)">
     {{company.Cliente}}
     </li>
</ul>
</div>
</div>   -->


<div class="grid ">
    <div class="g-w12field">
<div class="container">
    <div class=" mt-5">
      <div class="row">
          <div class="col-md-6">
              <!-- <ul class="list-group">
                  <li class="list-group-item">
                    <input type="checkbox" [(ngModel)]="isMasterSel" name="list_name" value="h1" (change)="checkUncheckAll()"/>  <strong>Select All / Unselect All</strong>
                  </li>
                </ul> -->
                <ul class="list-group">
                  <li class="list-group-item" *ngFor="let item of categoryList">
                    <input type="checkbox" [(ngModel)]="item.isSelected" name="list_name" value="{{item.idCliente}}" (change)="isAllSelected()"/>
                    {{item.Cliente}}
                  </li>
                </ul>
          </div>
          <div class="col-md-6">
              <!-- <code>{{checkedCategoryList}}</code> -->
              <!-- <code>{{clientesShowHideAll}}</code> -->
          </div>
      </div>
    </div>
  </div>
</div>
</div>  

  













<div class="grid filtro r-grid r-grid12-12-6">
    <div class="g-w6field">
        <small>Ejercicio:</small>
        <p-dropdown  [autoDisplayFirst]="false" [options]="gds.ejercicios" [(ngModel)]="ejercicio" (onChange)="filtrarEj();"></p-dropdown>
    </div>
    <div class="g-w6field">
        <small>Empleado:</small>
        <p-dropdown (onChange)="loadPermisos();" [autoDisplayFirst]="false" [showClear]="false" [options]="empleados" optionLabel="empleado" [(ngModel)]="selectedEmpleado"></p-dropdown>
    </div>
</div>  

<div class="grid r-grid r-grid12-6" style="width:'100%';">

<div class="g-w12field">
    <p-table [value]="disfrutados" dataKey="label">
        <!--<ng-template pTemplate="header">
            <tr>
                <th style="width:60px"></th>
                <th align="left">Permisos y licencias consolidadas de {{selectedEmpleado['value']}} {{selectedEmpleado['label']}}, en {{ejercicio}}:</th>
            </tr>
        </ng-template>-->
    
        <ng-template pTemplate="body" let-emp let-expanded="expanded">
            <tr>
                <td style="width:60px"><button type="button" pButton pRipple [pRowToggler]="emp" class="ui-button-text ui-button-rounded ui-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button></td>
                <td style="text-align:left;padding-left:1rem">{{emp.label}} ({{emp.dias}} días)</td>
            </tr>
        </ng-template>
    
        <ng-template pTemplate="rowexpansion" let-emp>
            <tr><td colspan="2"><div >Aceptado</div></td></tr>
            <tr>
                <td colspan="2"><div class="p-p-3">
            <p-table [value]="emp.permisos" >
                <ng-template pTemplate="header">
                    <tr>
                        <th>Fechas</th>
                        <th>Días</th>
                        <!--<th>Estado</th>-->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-fic>
                    <tr *ngIf="fic.estado=='ACEPTADA'">
                        <td>{{showfechas(fic.fechas)}}</td>
                        <td>{{fic.dias}}</td>
                        <!--<td>{{fic.estado}}</td>-->
                    </tr>
                </ng-template>
            </p-table></div></td></tr>
            <tr><td colspan="2"><div >Rechazado</div></td></tr>
            <tr>
                <td colspan="2"><div class="p-p-3">
            <p-table [value]="emp.permisos" >
                <ng-template pTemplate="header">
                    <tr>
                        <th>Fechas</th>
                        <th>Días</th>
                        <!--<th>Estado</th>-->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-fic>
                    <tr *ngIf="fic.estado=='RECHAZADA'">
                        <td>{{showfechas(fic.fechas)}}</td>
                        <td>{{fic.dias}}</td>
                        <!--<td>{{fic.estado}}</td>-->
                    </tr>
                </ng-template>
            </p-table></div></td></tr>
        </ng-template>
    </p-table>
</div>

</div>

  <!------------------------------horario view here----------------------------->
  <app-header-breadcrumb></app-header-breadcrumb>
<!-- <p class="tituloSeccion">Saldos horarios</p> -->
    <div class="grid filtro r-grid r-grid12-12-6">
        <!-- <div class="g-w4field">
            <small>Ejercicio:</small>
            <p-dropdown [autoDisplayFirst]="true" [showClear]="false" [options]="gds.ejercicios" [(ngModel)]="ejercicioo" (onChange)="allLoadEmpleados();"></p-dropdown>
        </div>
        <div class="g-w4field">
            <small>Mes:</small>
            <p-dropdown  [autoDisplayFirst]="true" [showClear]="false" [options]="gds.meses" [(ngModel)]="mes" (onChange)="allLoadEmpleados();"></p-dropdown>
        </div>
        <div class="g-w4field">
            <div class="f-box f-jcsb">
                <div class="f-w1" style="margin-top:10px"><p-button  (onClick)="loadPermisoss()">Informe Asistencia</p-button></div>

                <div class="f-w1" style="margin-top:10px"><p-button  (onClick)="loadMonthlyReport()">Informe mensual detallado</p-button></div>
            </div>
        </div> -->
        <!-- <div class="g-w4field"> 
            <div class="f-box f-jcsb">
                <div class="f-w1" style="margin-top:10px"><p-button [disabled]="selectedEmpleados.length<1" (onClick)="informeHorario()">Obtener informe horario</p-button></div>
            </div>
        </div>
    -->


    <div class="g-w4field">
        <small>Ejercicio:</small>
        <p-dropdown [autoDisplayFirst]="true" [showClear]="false" [options]="gds.ejercicios" [(ngModel)]="ejercicioo" (onChange)="allLoadEmpleadosMonth();"></p-dropdown>
    </div>

 
    
    <div class="g-w2.9field">
        <small>Del Mes:</small>
        <p-dropdown  [autoDisplayFirst]="true" [showClear]="false" [options]="gds.meses" [(ngModel)]="fromMes" (onChange)="allLoadEmpleadosMonth();"></p-dropdown>
    </div>
    <div class="g-w2.9field">
        <small>Al Mes:</small>
        <p-dropdown  [autoDisplayFirst]="true" [showClear]="false" [options]="gds.meses" [(ngModel)]="toMes" (onChange)="allLoadEmpleadosMonth();"></p-dropdown>
    </div>
    <div class="g-w3field">
        <div class="f-box f-jcsb">
           

            <div class="f-w1" style="margin-top:10px"><p-button  (onClick)="loadMonthlyReportFromTo()">Informe mensual detallado</p-button></div>
        </div>
    </div>
    














    
<div class="g-w12field" *ngFor="let company of subcompanies" >

<p-table [value]="empleadoss[company.idCliente]" [(selection)]="selectedEmpleados" (click)="click_tabla($event)">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem">
                <p-tableHeaderCheckbox #check (click)="empleadoSelectionSelectAll(empleadoss[company.idCliente],check)"></p-tableHeaderCheckbox>
            </th>
            <th>Empleado</th>
            <th>Empresa</th>
            <!-- <th>Saldo Horario (Horas:minutos)</th> -->
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-emp>
    
        <tr>
            <td><p-tableCheckbox #singlecheck [value]="emp" (click)="empleadoSelection(emp,singlecheck)"></p-tableCheckbox></td>
            <td [attr.data-id]="emp.idEmpleado">{{emp.empleado}} <i [attr.data-id]="emp.idEmpleado" class="fas fa-eye"></i>
            </td>
            <td>{{company.Cliente}}</td>
            <!-- <td>{{emp.saldo}}</td> -->
        </tr>
    </ng-template>

    

</p-table>
</div>













</div>

<p-toast position='center'></p-toast>