<app-header-breadcrumb></app-header-breadcrumb>
<!-- <p class="tituloSeccion">Solicitudes de empleados</p>    -->
<p *ngFor="let sol of solicitudes;index as i"  [ngClass]="{'hidemenu':  access_labor_request!='0'}">
    <p-card >
        
        <i style="margin-right:1rem" class='fas fa-umbrella-beach'></i><b>{{sol.solicitud}} - ({{sol.periodo}})</b>
        
        <div class="grid r-grid12-6">
             
            <div class="g-w6field">
                    Empleado: {{sol.empleado|uppercase}}
            </div>        
            <div class="g-w3field">
                Días solicitados: {{sol.fechas}} ({{sol.dias}} día/s)
            </div>
            <div class="g-w3field">
                Disfrutados({{sol.ano}}): {{getDisfrutados(sol.idEmpleado,sol.idtipopermiso,sol.ano)}} días
            </div>
            <div *ngIf="sol.doc!=null" class="g-w12field">
                <!-- <a href="#" (click)="gds.viewIDDoc(sol.doc)">Documento adjunto</a> -->
                <a href="#" (click)="gds.viewIDDocNew(sol.doc)">Documento adjunto</a>
               
            </div>
            <div class="g-w12field">
                <input [(ngModel)]="sol.motivo" class="voraz" pInputText placeholder="Motivo del rechazo"/>
            </div>
            <div class="g-w12field">
                <div class="f-box f-jcsb" style="margin-bottom: -1rem;">
                    <div class="f-w1"><p-button label="Aceptar" (click)="aceptar(i)"></p-button></div>
                    <div class="f-w1"><p-button label="Rechazar" (click)="rechazar(i)"></p-button></div>
                </div>           
            </div>
        </div>
    </p-card>







 



    <p *ngFor="let solcada of solicitudescada;index as k"  [ngClass]="{'hidemenu':  access_labor_request!='0'}">
        <p-card >
            
            <i style="margin-right:1rem" class='fas fa-umbrella-beach'></i><b>Cancelar salir - {{solcada.solicitud}} - ({{solcada.periodo}})</b>
            
            <div class="grid r-grid12-6">
                
                <div class="g-w6field">
                        Empleado: {{solcada.empleado|uppercase}}
                </div>        
                <div class="g-w3field">
                    Días solicitados: {{solcada.periodo}} 
                </div>
                <div class="g-w3field">
                    <!-- Disfrutados({{solcada.ano}}) -->
                </div>
               
                <div class="g-w12field">
                    <input [(ngModel)]="solcada.motivo" class="voraz" pInputText placeholder="Motivo del rechazo"/>
                </div>
                <div class="g-w12field">
                    <div class="f-box f-jcsb" style="margin-bottom: -1rem;">
                        <div class="f-w1"><p-button label="Aceptar" (click)="aceptarcada(k)"></p-button></div>
                        <div class="f-w1"><p-button label="Rechazar" (click)="rechazarcada(k)"></p-button></div>
                    </div>           
                </div>
            </div>
        </p-card>





