<!--The content below is only a placeholder and can be replaced.-->

<!--
<a routerLinkActive="active" routerLink="/login">Login</a> | 
<a routerLinkActive="active" routerLink="/main">Main</a> 
-->

<div class="ui-fluid ng-main" [ngClass]="theme"> 
    <router-outlet></router-outlet>
 </div> 





