<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Impuestos</p>

<br/>
	
<p-toast position='center'></p-toast>





            
    <div class="p-grid p-justify-start">

    <div class="p-col-12 p-md-12 p-lg-12">
    <fieldset class="fieldset-custom">
        <div class="p-grid">
            <div class="p-col-12">
    <legend>Filtro de impuestos y nóminas</legend>
        <label>Ejercicio</label>
        <p-dropdown styleClass="voraz" id="float-input" [autoDisplayFirst]="false" [showClear]="false" [options]="gds.ejercicios" [(ngModel)]="ejercicio2"></p-dropdown>
    </div></div>
    </fieldset>
    </div>
                
    <div class="p-col-12 p-md-12 p-lg-12">
    <div class="centrado"><button pButton type="button" label="Obtener cuadro resumen {{ejercicio2}}" class="ui-button-raised" (click)="filtrarImpuestos();"></button></div>
    </div>
            
    <div class="p-col-12 p-md-12 p-lg-12">
    <table id='tablaModelos' border="1" class='tablaCuadro' (click)="gds.viewDoc($event)">
    <thead><td>MODELO</td><td>ENE</td><td>FEB</td><td>MAR</td><td>ABR</td><td>MAY</td><td>JUN</td><td>JUL</td><td>AGO</td><td>SEP</td><td>OCT</td><td>NOV</td><td>DIC</td></thead>
    <tr *ngFor="let fila of tabla" [ngClass]="{'mod-mensual': fila[0]==0,'mod-trimestral': fila[0]==1,'mod-acuenta': fila[0]==2,'mod-anual': fila[0]==3}">
        <td>{{fila[1]}}</td>
        <td *ngFor="let i of rango_modelos">
        <a *ngIf="isNumber(fila[i])" href="#" [attr.data-id]='fila[i]'><i class="fa fa-file"></i></a>
        <i *ngIf="!isNumber(fila[i])">{{fila[i]}}</i>
        </td>
    </tr>
    </table>
    </div>

    <div class="p-col-12 p-md-12 p-lg-8">
    <BR/>
    <table><TR><TD>Leyenda:</TD><TD style='background:#B8CCE4'>Mensual</TD><TD style='background:#C4D79B'>Trimestral</TD><TD style='background:#FFAEC9'>A cuenta</TD><TD style='background:#FABF8F'>Anual</TD></TR></table>
    </div>
    
    </div>
