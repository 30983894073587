import { Component, OnInit,Input,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import {MenuItem} from 'primeng/api';     
//import {ChartModule} from 'primeng/chart';
//import {TableModule} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { FechaTipadaPipe } from '../../libs/fechaTipada.pipe';
import { Utilidades } from '../../libs/utilidades';
import { UtilService } from '../../servicios/util.service';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {FileUpload} from 'primeng/fileupload';
import { Respuesta } from '../../respuesta';
import { Bl } from '../../servicios/bl';
import {PrimeIcons} from 'primeng/api';

@Component({
  selector: 'app-asunto',
  templateUrl: './asunto.component.html',
  styleUrls: ['./asunto.component.scss']
})
export class AsuntoComponent implements OnInit {

  //@Input() idTarea:Number=13516;
  tarea:any={data:{'Tarea':''}};
  tramites:any=[];
  docs:any=[];
  displayAsunto:boolean=false;
  events1:any;
  
  prefijos:string[]=["Creación tarea","Asignada a","Enviado email a cliente"
        ,"Enviado doc por FAX a cliente","Enviado sms a cliente","Enviados docs a cliente"
        ,"Enviados docs a ","Peticion información a cliente","","Finalización tarea"
         ,"Enviado doc por FAX a ","Email de respuesta","Nota ","Llamada saliente","Incorporacion Documentos","Reunión","Visita"];
  
  
  constructor(private httpClient: HttpClient,public gds:GlobaldataService,public bl:Bl,
              private us:UtilService,private messageService: MessageService) { }

  ngOnInit() {
    /*this.events1 = [
      {status: 'Ordered', date: '15/10/2020 10:30', icon: PrimeIcons.SHOPPING_CART, color: '#9C27B0', image: 'game-controller.jpg'},
      {status: 'Processing', date: '15/10/2020 14:00', icon: PrimeIcons.COG, color: '#673AB7'},
      {status: 'Shipped', date: '15/10/2020 16:15', icon: PrimeIcons.ENVELOPE, color: '#FF9800'},
      {status: 'Delivered', date: '16/10/2020 10:00', icon: PrimeIcons.CHECK, color: '#607D8B'}
  ];*/
  }
  
  //,concat(coalesce(C2.Cliente,''),' ',coalesce(A2.cliente,'')) as dest 
  //setSqlBaseAll("Select TR.*, A.cliente as productor,U.usuario as usuario,concat(coalesce(C2.Cliente,''),' ',coalesce(A2.cliente,'')) as dest FROM Tramites as TR LEFT JOIN Usuarios as U on TR.idUsuario=U.idUsuario LEFT JOIN Agenda as A on TR.idProductor=A.idAgenda LEFT JOIN RegistroSalida as RS ON TR.idRegSalida=RS.idRegSalida LEFT JOIN Clientes as C2 ON RS.idCliente=C2.idCliente LEFT JOIN Agenda as A2 ON A2.idAgenda=RS.idAgenda");

  public getNombreTramite(tramite)
  {
    tramite=tramite.data;
    console.log(tramite);
    //tramite.data['tramites2.tramite']
	  var nombre:string=this.prefijos[tramite['tramites2.tipo']]+" "+tramite['tramites2.tramite'];
	  if (tramite['tramites2.tipo']==6 || tramite['tramites2.tipo']==9) nombre=nombre+" ";//+tramite.dest;
	  return nombre;
  }
  
  public show(id)
  {
	  this.tarea={data:{'Tarea':''}};
    this.displayAsunto=true;
    
    this.bl.load_tarea(id).then((res)=>{
      this.tarea=res;
      console.log(res);
      this.events1=this.tarea.tramites;

      // console.log(res);
      // console.log(res['resp'][0]);
      // console.log(res['resp'][0]['children']);

      // this.tarea=res['resp'][0];
      // this.tramites=[];//res[0].resp.tramites2_list;
      // this.docs=res['resp'][0];
    });		
		
		// "Creación tarea","Asignada a","Enviado email a cliente"
        //,"Enviado doc por FAX a cliente","Enviado sms a cliente","Enviados docs a cliente"
        //,"Enviados docs a ","Peticion información a cliente","","Finalización tarea"
        // ,"Enviado doc por FAX a ","Email de respuesta","Nota ","Llamada saliente","Incorporacion Documentos","Reunión","Visita"};

  }
  
  public findDocsTramite(idTramite)
  {
    if(!idTramite) return [];
	  var docs:any=[];
	  var ld:any=this.docs.filter(link=>(link.idTramite)===idTramite);
	  for(let d of ld)
	  {
		  //var doc:any=this.docs.filter(link=>(link.idDocumento)===d.idDoc)[0];
		  docs.push(d.documentos);
    }
    //console.log(docs);
	  return docs;
  }
}
