<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Portal de denuncias</p>   
    
<!-- <div class="grid filtro r-grid r-grid12-12-6">
    <div class="g-w6field"> 
        <small>Ejercicio:</small>
        <p-dropdown  [autoDisplayFirst]="false" [showClear]="true" [options]="gds.ejercicios" [(ngModel)]="ejercicio" (onChange)="filtrarEj();"></p-dropdown>
    </div>
    <div class="g-w6field">
        <small>Empleado:</small>
        <p-dropdown (onChange)="filtrar();" [autoDisplayFirst]="false" [showClear]="false" [options]="empleados" [(ngModel)]="empleado"></p-dropdown>
    </div>
</div>   -->


<div class="example-column">
    <p-table [value]="complaints" selectionMode="single"  [(selection)]="selectedComplaint" 
    [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando {first} de {totalPages}" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" >
        <ng-template pTemplate="header">
            <tr>
                <th>Empleado</th>
                <th>Fecha denuncia</th>
                <th>Privacidad</th>
                <th>Tipo de denuncia</th>
                <th>Nº de dias transcurridos</th>
                
                <th>Descripción</th>
                <!-- <th>Complaint Status</th>
                <th>Complaint Communication</th> -->
                <th>Doc. adjunto</th>
                
                
                <th width="10%"></th>
               
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-complaint>
            <tr [pSelectableRow]="complaint">
                <!-- <td>{{complaint.estado}}</td>
                <td>{{complaint.periodo}} </td>
                <td>{{complaint.solicitud}}</td>
                <td>{{complaint.obs}}</td> -->

                <td>{{complaint.employeeName}} </td>
                <td>{{complaint.complaintDate}}</td>
                <td>{{complaint.complaintPrivacy}}</td>
                <td>{{complaint.complaintType}}</td>
                <td>{{complaint.complaintNodays}}</td>
               
                <td>{{complaint.complaintDesc}}</td>

                <!-- <td>{{complaint.complaintStatus}}</td>
                <td>{{complaint.complaintCommunication}}</td> -->
                <td>
                    <a href="#" *ngIf=" complaint.complaintAttachDocument!='' && complaint.complaintAttachDocument!=null"  (click)='cbl.viewIDDocClient(complaint.complaintAttachDocument)'>Documento adjunto</a>
                </td>
               
               
                <td>
                    <button title='Ver detalle' pButton pRipple type="button" icon="fas fa-eye" class="p-button-outlined"
                    (click)="selectComplaint(complaint.idComplaint)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>    
</div>

<div class="example-column">*Max Nº de días denuncia 60 días.</div>
<!-- <div class="example-column">
    <p-table [value]="incidencias" >
        <ng-template pTemplate="header">
            <tr>
                <th>Estado</th>
                <th>Fecha</th>
                <th>Incidencia</th>
                <th>Observaciones</th>
                <th width="10%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-incidencia>
            <tr [pSelectableRow]="incidencia">
                <td>{{incidencia.estado}}</td>
                <td>{{incidencia.periodo}} </td>
                <td>{{incidencia.solicitud}}</td>
                <td>{{incidencia.obs}}</td>
                <td>
                    
                </td>
            </tr>
        </ng-template>
    </p-table>    
</div>
     -->

<!-- <p-table [value]="incidencias" (click)="click_tabla($event)">
    <ng-template pTemplate="header">
        <tr>
            <th>Empleado</th>
            <th>Importe</th>
            <th>Documentos</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-incidencia>
        <tr>
            <td>{{incidencia.empleado}}</td>
            <td>{{incidencia.importe| number:'1.2':'es'}}€</td>
            <td><i [attr.data-id]="doc[0]" class="fa fa-file icono enlace" *ngFor="let doc of incidencia.incidencias"></i></td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        Importe total: {{total| number:'1.2'}}€
    </ng-template>
</p-table> -->

