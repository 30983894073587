<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Nóminas colectivas</p>   
    
<div class="grid filtro r-grid">
    <!--<fieldset class="g-w4 r-grid">
    <legend>Filtro de nóminas</legend>-->

        <div class="g-w12field">
            <small>Ejercicio:</small>
            <p-dropdown (onChange)="filtrarImpuestos();" [autoDisplayFirst]="true" [showClear]="false" [options]="gds.ejercicios" [(ngModel)]="ejercicio"></p-dropdown>
        </div>
</div>                
    <!--</fieldset>-->
    
    
        <!--<div class="g-w12">-->
        <p-table (click)="gds.viewDoc($event)" id="nominas" [value]="tabla2" [style]="{'font-size':'0.8rem','text-align':'center'}">
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="12" style="font-size:1rem">Nóminas</th>
                </tr>
                <tr>
                    <th style="text-align:center">Ene</th>
                    <th style="text-align:center">Feb</th>
                    <th style="text-align:center">Mar</th>
                    <th style="text-align:center">Abr</th>
                    <th style="text-align:center">May</th>
                    <th style="text-align:center">Jun</th>
                    <th style="text-align:center">Jul</th>
                    <th style="text-align:center">Ago</th>
                    <th style="text-align:center">Sep</th>
                    <th style="text-align:center">Oct</th>
                    <th style="text-align:center">Nov</th>
                    <th style="text-align:center">Dic</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-fila>
                <tr>
                    <td style="text-align:center" *ngFor="let col of fila">
                        <a *ngIf="isNumber(col)" href="#" [attr.data-id]='col'><i class="fa fa-file"></i></a>
                        <i *ngIf="!isNumber(col)">{{col}}</i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    <!--</div>-->

    <!--<div class="g-w12">-->
        <p-table (click)="gds.viewDoc($event)" id="nominas2" [value]="tabla3" [style]="{'font-size':'0.8rem','text-align':'center'}">
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="12" style="font-size:1rem">Resumen de Nóminas</th>
                </tr>
                <tr>
                    <th style="text-align:center">Ene</th>
                    <th style="text-align:center">Feb</th>
                    <th style="text-align:center">Mar</th>
                    <th style="text-align:center">Abr</th>
                    <th style="text-align:center">May</th>
                    <th style="text-align:center">Jun</th>
                    <th style="text-align:center">Jul</th>
                    <th style="text-align:center">Ago</th>
                    <th style="text-align:center">Sep</th>
                    <th style="text-align:center">Oct</th>
                    <th style="text-align:center">Nov</th>
                    <th style="text-align:center">Dic</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-fila>
                <tr>
                    <td style="text-align:center" *ngFor="let col of fila">
                        <a *ngIf="isNumber(col)" href="#" [attr.data-id]='col'><i class="fa fa-file"></i></a>
                        <i *ngIf="!isNumber(col)">{{col}}</i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    <!--</div>
        
    </div>-->
    
