import {Component, OnInit,ViewChild } from '@angular/core';
import {MenuItem} from 'primeng/api';    
import {HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import {GlobaldataService } from '../../servicios/globaldata.service';
import { UtilService } from '../../servicios/util.service';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {FileUpload} from 'primeng/fileupload';
import { Tarea,TIPO_TAREA, Documento } from '../../dataobjects/clasesbl';
import { Bl } from '../../servicios/bl';
import { Respuesta } from '../../respuesta';

@Component({
  selector: 'app-consultas',
  templateUrl: './consultas.component.html',
  styleUrls: ['./consultas.component.scss']
})
export class ConsultasComponent implements OnInit {

  @ViewChild('envioDoc') envioDoc:FileUpload;
  itemss: MenuItem[];
  public activeIndex2: number = 0;
  consulta:string="";
  
  constructor(private us:UtilService,public bl:Bl,private httpClient: HttpClient,private gds:GlobaldataService,private messageService: MessageService)
  {  
  }

  ngOnInit() {
	this.itemss = [
		{label: 'Escribir consulta'},
		{label: 'Adjuntar archivos'}//,
	];
  }
  
  next()
  {
	this.activeIndex2=1;
  }
  
  anterior()
  {
	this.activeIndex2=0;
  }
 
  async next2()
  {	
	let tarea:Tarea=new Tarea({
		concepto:this.consulta
		,idCreador:0
		,origen:6
		,Tarea:'Consulta realizada por el cliente'
		,tipo:TIPO_TAREA.TIPO_GENERAL
		,idCliente:this.gds.id_cliente
	});

	console.log(tarea);

	let idTarea=0;
	await this.bl.nueva_tarea(tarea).then((id)=>{
		//console.log(id);
		idTarea=id;
	});	

	let meta=new Documento({descripcion:'',idCliente:this.gds.id_cliente});
		
	let docs=[];
	if(this.envioDoc.files.length>0) 
	{
		await this.bl.send_documentos(this.envioDoc.files,meta).then(async results=>
		{
			docs=results;
			console.log("RESUMEN:",idTarea,docs);
			await this.bl.add_docs_to_tarea(idTarea,0,docs);
		});
	}

	this.envioDoc.clear();
	this.consulta='';
	this.messageService.add({severity: 'info', summary: 'Envío de consulta a la gestoría', detail: 'Consulta recibida con éxito!'});
	this.activeIndex2=0;
  }

	myUploader($event)
	{
		console.log($event);
	}

}
