import { Component } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
//import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
//import {MenuItem} from 'primeng/api';     
//import {TabMenuModule} from 'primeng/tabmenu';
//import {StepsModule} from 'primeng/steps';

//import {MenubarModule} from 'primeng/menubar';

import {CardModule} from 'primeng/card';
//import {TreeModule} from 'primeng/tree';
//import {TreeNode} from 'primeng/api';
//import {FileUploadModule} from 'primeng/fileupload';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import { Routes, RouterModule,Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import {DialogModule} from 'primeng/dialog';
import { ThemeService } from 'src/app/core/services/theme.service';
import { SessionService } from 'src/app/core/services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
   title = 'Portal del cliente';
  
   theme: string;
	
	constructor(private httpClient: HttpClient,private httpHandler:HttpHandler,private router:Router,private sessionService: SessionService,private themeService: ThemeService)
	{
    var theme = this.sessionService.getItem("selected-theme");
    if (theme != null && theme.length > 0) {
      this.theme = theme;
      this.themeService.selectTheme(theme);
    } else {
      this.theme = "theme-light";
    }
	}

    ngOnInit() {
		//this.router.navigate(['/main/recientes']); 
    this.themeService.theme.subscribe((val: string) => {
      this.theme = val;
    });   
	//	this.router.navigate(['/login']);    
    }

    ngOnDestroy() {
      this.themeService.theme.observers.forEach(function (element) { element.complete(); });
    }

}
