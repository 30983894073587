import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salir',
  templateUrl: './salir.component.html',
  styleUrls: ['./salir.component.scss']
})
export class SalirComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
