	
	<app-header-breadcrumb></app-header-breadcrumb>
	<p class="tituloSeccion">Documentación</p>
	
	<p-menubar  [model]="items10" ></p-menubar>
	<!--
	<br/>
	
	<p-toast position='center'></p-toast>
	
	<div *ngIf="menuId==0">
		<h3>Enviar documentos a la gestoría:</h3><br/>
		
		<p-fileUpload id="envioDoc" #envioDoc (uploadHandler)="myUploader($event)" skinSimple="true" chooseLabel="Seleccionar" uploadLabel="Enviar" cancelLabel="Cancelar" multiple="true"  maxFileSize="10000000" name="myfile[]" customUpload="true">
		</p-fileUpload>
		
		
	</div>
	
	<div *ngIf="menuId==1">
	
	<div class="p-grid p-justify-start">
		<div class="p-col-12 p-md-12 p-lg-8">			
		<fieldset class="fieldset-custom">
		<legend>Filtro de documentos</legend>
		
		
		<div class="p-grid">
				
		<div class="p-col-12">
		<label>Nombre del documento contiene</label>
		<input id="float-input" type="text" size="30" pInputText class="voraz" autocomplete="off" [(ngModel)]="documento"> 
		</div>
		
		<div class="p-col-6">
		<label>Ejercicio</label>
		<p-dropdown placeholder="(todos)" styleClass="voraz" id="float-input" [autoDisplayFirst]="false" [showClear]="true" [options]="gds.ejercicios" [(ngModel)]="ejercicio"></p-dropdown>
		</div>
		
		<div class="p-col-6">
		<label>Materia</label>
		<p-dropdown placeholder="(todas)" styleClass="voraz" id="float-input" [autoDisplayFirst]="false" [showClear]="true" [options]="gds.materias" [(ngModel)]="materia"></p-dropdown>	
		</div>
		
		<div class="p-col-12">
		<div class="centrado"><button pButton type="button" label="Filtrar" class="ui-button-raised" (click)="filtrarDocumentos();"></button></div>
		</div>
		
		</div>
		
		</fieldset>
		</div>
	</div>
	
    <div *ngIf="ejercicio!=null">Se muestran los documentos correspondientes al ejercicio {{ejercicio}} y los configurados como permanentes!</div>

	<br/>
	<p-tree  selectionMode="single" (onNodeSelect)="gds.viewTreeDoc($event,true)" [value]="itemsArbol" styleClass="arbol-docs"  [style]="{width: '100%'}">
		
		<ng-template let-node pTemplate="doc" >
			<p>[{{node.data['fechaEntrada']|FechaTipadaPipe:node.data['tipoFecha']}}] {{node.label}}</p>
		</ng-template>
		
	</p-tree>
	</div>
	
	
	<div *ngIf="menuId==21">
		<fieldset>
		<legend>Filtro de facturas: (...aquí irían los campos similares a los actuales)</legend>
		
		<br/>
		<span class="ui-float-label">
		<input id="float-input" type="text" size="30" pInputText class="voraz"> 
		<label for="float-input">Descripción</label>
		</span>
		<br/>	
		<div class="centrado"><button pButton type="button" label="Filtrar" class="ui-button-raised"></button></div>
		</fieldset>
		<p-chart type="bar" [data]="data" height="25vh"></p-chart>
		<br/>
		
	</div>
	
	
	<div *ngIf="menuId==3">
				
		<div class="p-grid p-justify-start">

		<div class="p-col-12 p-md-12 p-lg-8">
		<fieldset class="fieldset-custom">
		<legend>Filtro de impuestos y nóminas</legend>
			<label>Ejercicio</label>
			<p-dropdown styleClass="voraz" id="float-input" [autoDisplayFirst]="false" [showClear]="false" [options]="gds.ejercicios" [(ngModel)]="ejercicio2"></p-dropdown>
		</fieldset>
		</div>
					
		<div class="p-col-12 p-md-12 p-lg-8">
		<div class="centrado"><button pButton type="button" label="Obtener cuadro resumen {{ejercicio2}}" class="ui-button-raised" (click)="filtrarImpuestos();"></button></div>
		</div>
				
		<div class="p-col-12 p-md-12 p-lg-8">
		<table id='tablaModelos' border="1" class='tablaCuadro' (click)="gds.viewDoc($event,true)">
		<thead><td>MODELO</td><td>ENE</td><td>FEB</td><td>MAR</td><td>ABR</td><td>MAY</td><td>JUN</td><td>JUL</td><td>AGO</td><td>SEP</td><td>OCT</td><td>NOV</td><td>DIC</td><td>ANUAL</td></thead>
		<tr *ngFor="let fila of tabla" [ngClass]="{'mod-mensual': fila[0]==0,'mod-trimestral': fila[0]==1,'mod-acuenta': fila[0]==2,'mod-anual': fila[0]==3}">
			<td>{{fila[1]}}</td>
			<td *ngFor="let i of rango_modelos">
			<a *ngIf="isNumber(fila[i])" href="#" [attr.data-id]='fila[i]'><i class="fa fa-file"></i></a>
			<i *ngIf="!isNumber(fila[i])">{{fila[i]}}</i>
			</td>
		</tr>
		</table>
		</div>
 
		<div class="p-col-12 p-md-12 p-lg-8">
		<BR/>
		<table><TR><TD>Leyenda:</TD><TD style='background:#B8CCE4'>Mensual</TD><TD style='background:#C4D79B'>Trimestral</TD><TD style='background:#FFAEC9'>A cuenta</TD><TD style='background:#FABF8F'>Anual</TD></TR></table>
		</div>
		
		</div>
	</div>
-->

<router-outlet></router-outlet>
	
	
