import { Component, OnInit, Input } from '@angular/core';
import {Table} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { Respuesta } from 'src/app/respuesta';
import { formatDate } from '@angular/common';
import { UtilService } from 'src/app/servicios/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Dropdown } from "primeng/dropdown";

//import {IncidenciaComponent } from '../incidencia/incidencia.component';

export class Incidencia {
  estado: string;
  fechaInicio: Date;
  fechaFin: Date;
  observaciones: string;
  tipoIncidencia: number;
  adjuntos: any[] = [];
  tipo: string;
  motivo: string;
}

@Component({
  selector: 'app-incidencias',
  templateUrl: './incidencias.component.html',
  styleUrls: ['./incidencias.component.scss']
})
export class IncidenciasComponent implements OnInit {

  public empleados=[];
  empleado:number=0;
  public ejercicio:number;
  incidencias: any;
  selectedYear: any;
  currentSearchYear: string;
  incidenciasTemp: any;
  selectedIncidencia: Incidencia = null;
  collapsed: boolean;
  selectedTipoIncidencia: any;
  tempIncidencia: Incidencia;
  tiposIncidencias: any;
  yearArray:any[] = [];
  


  constructor(private httpClient: HttpClient,public gds:GlobaldataService,private us:UtilService,private messageService: MessageService,
    private router:Router, private routera: ActivatedRoute) { 
  }



  ngOnInit(): void {

    this.empleados=this.gds.getEmpleados();
		console.log(this.empleados);
		this.empleado=this.empleados[0].value;
		this.ejercicio=0;
    this.createYearPicker();
    this.filtrar();

  }

  public filtrarEj()
  {
        let old_emp=this.empleado;
        //console.log("EJ:"+this.ejercicio);
        if(this.ejercicio==0 || this.ejercicio==null)
        {
          this.empleados=this.gds.getEmpleados();
          let enc=this.empleados.find(x=>x.value==old_emp);
          //console.log("=>",old_emp,enc);
          if (enc!=undefined) this.empleado=old_emp;
          this.filtrar();
        }	
        else
        {
          let url="fields=E.idempleado as value,T.empleado as label";
          url=url+"&joins=trabajador,documentos(E.idempleado=>D.idempleado)&oder:T.empleado";
          url=url+"&where=E.idcliente="+this.gds.id_cliente+" and substr(D.fechaejercicio,1,4)='"+this.ejercicio+"'";
          url=url+"&groupby=E.idempleado,T.empleado"
          url=url+"&order=T.empleado";
          this.httpClient.get<Respuesta>(this.gds.urlBaseDL+"empleado?"+url).subscribe((res)=>{
            //console.log(res);
            this.empleados=res.resp;
            let enc=this.empleados.find(x=>x.value==old_emp);
            //console.log("--->",old_emp,enc);
            if (enc!=undefined) this.empleado=old_emp; else this.empleado=0;
            //console.log(this.empleado);
            this.filtrar();
          });
        }
  }

  public filtrar()
  {
    //alert("aaa");
    if(this.empleado==0 || this.empleado==null) {return;}
    let ej=this.ejercicio;
    if (ej==null) ej=0;

    console.log(this.empleado+"-------"+ej);


    // let url2='solicitudes?'
    // +'&fields=idsolicitud,solicitud,fechainicio,obs,fechafin,horainicio,horafin,fechas,estado'
    // +'&where=idEmpleado='+this.empleado+" and idTipoPermiso=0"
    // +'&order=fechainicio DESC';
    //+'&tree=solicitud->permisos=>fechainicio,fechafin,horainicio,horafin,fechas,estado';


    let url2='solicitudes?'
    +'&fields=idsolicitud,solicitud,fechainicio,obs,fechafin,horainicio,horafin,fechas,estado';
    url2+='&where=idEmpleado="'+this.empleado+'" and idTipoPermiso=0 ';
   
    
    if(ej!=0)
    {
      url2+="and substr(fechainicio,1,4)='"+ej+"' ";

      
    }
  
    url2+='&order=fechainicio DESC';

    console.log(url2);
    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      //console.log(r);
      console.log("vvvvvvvvvv",r.resp);
      for(let p of r.resp)
      {
          let dias=0;
          //for(let d of p.permisos)
          //{
            let cd=p.fechas.split(",").length;
            p['dias']=cd; 
            if (cd>1)
              p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(p.fechafin,'dd/MM/yyyy','en-US');
            else
              p['periodo']=p.fechainicio;

            if(p.horainicio!=null && p.horafin!=null)
            {
              p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
            }
            if(p.estado!="RECHAZADA") dias+=cd;
          //}
          p['dias']=dias;
      }
      console.log(r.resp);
      this.incidencias=r.resp;
      this.incidenciasTemp=r.resp;
      this.searchBasedOnYear()
      
    });

  }
  createYearPicker(){
    let currentYear = new Date().getFullYear();
    let earliestYear = 2010;

    while (currentYear >= earliestYear) {
     this.yearArray.push({"year":currentYear})
      currentYear -= 1;
    }
  }
 
  searchBasedOnYear(){
    let tempData  = [];
    console.log(this.selectedYear)
    if(this.selectedYear != null && this.selectedYear !== undefined){
        this.currentSearchYear = ""+this.selectedYear["year"]
        this.incidenciasTemp.forEach(value =>{
          let yearFromDb:string = ""+value["periodo"];
          if(yearFromDb.indexOf(this.currentSearchYear)>-1)  {
            tempData.push(value);
          }       
        });         
    }else{
      this.currentSearchYear  = ''
      this.incidenciasTemp.forEach(value =>{
        tempData.push(value);
      });
    }
    this.incidencias = tempData;
  }

  onRowSelect(event) {
    this.collapsed = false;
    this.tempIncidencia = this.selectedIncidencia;
    this.selectedTipoIncidencia = this.tiposIncidencias[
      this.tempIncidencia.tipoIncidencia - 1
    ];
  }
  onRowUnselect(event) {
    this.collapsed = true;
    this.tempIncidencia = new Incidencia();
    this.selectedIncidencia = null;
    this.selectedTipoIncidencia = null;
  }






  }