import { Component, OnInit } from '@angular/core';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { Respuesta } from '../../respuesta';
import {formatDate} from '@angular/common';
//import {Solicitud,Documento} from '../libs/dataObjects'
import {MessageService} from 'primeng/api';
type statusType = "acceptBoth"|"rejectBoth"|"acceptEntrada"|"rejectEntrada"|"acceptSalida"|"rejectSalida" 

@Component({
  selector: 'app-misspunch-solicitudes',
  templateUrl: './misspunch-solicitudes.component.html',
  styleUrls: ['./misspunch-solicitudes.component.scss']
})
export class MisspunchSolicitudesComponent implements OnInit {

 updatedData:any=[];
  solicitudes:any[]=[];
  disfrutados={};
  checkbox: boolean=true;
  horarioData: any;
  displayDescription:boolean=false;
  description: any;
  constructor(public gds:GlobaldataService,private messageService: MessageService,private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.loadDisfrutados();
    this.loadSolicitudes();
    this.fetchMisspunch();
   
  }

  loadSolicitudes()
  {
    let url2='solicitudes?'
    +'&fields=idSolicitud,T.empleado as empleado,E.idEmpleado,idtipopermiso,solicitud,fechainicio,fechafin,horainicio,horafin,fechas,estado,doc,year(fechainicio) as ano'
    +'&joins=!{empleado}(S.idEmpleado),!{trabajador}(E.idTrabajador)'
    +"&where=estado='SOLICITUD' and E.idCliente="+this.gds.id_cliente
    +'&order=fechainicio';

    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      for(let p of r.resp)
      {
        p['motivo']="";
        let cd=p.fechas.split(",").length;
        p['dias']=cd; 
        if (cd>1)
          p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(p.fechafin,'dd/MM/yyyy','en-US');
        else
          p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US');

        if(p.horainicio!=null && p.horafin!=null)
        {
          p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
        }
      }
      console.log(r.resp);
      this.solicitudes=r.resp;
    });
  }

  loadDisfrutados()
  {
    let url2='solicitudes?'
    +'&fields=idSolicitud,T.empleado as empleado,E.idEmpleado as idEmpleado,idtipopermiso,solicitud,fechainicio,fechafin,horainicio,horafin,fechas,estado,doc,year(fechainicio) as ano'
    +'&joins=!{empleado}(S.idEmpleado),!{trabajador}(E.idTrabajador)'
    +"&where=estado='ACEPTADA' and idtipopermiso!=0 and year(fechainicio)="+this.gds.ejercicio_actual+" and E.idCliente="+this.gds.id_cliente;

    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      this.disfrutados={};
      for(let p of r.resp)
      {
        let cd=p.fechas.split(",").length;
        let key=p['idEmpleado']+"|"+p['idtipopermiso']+"|"+p['ano'];
        if (key in this.disfrutados)
          this.disfrutados[key]=this.disfrutados[key]+cd
        else
          this.disfrutados[key]=cd
      }
      console.log(this.disfrutados);
    });
  }

  getDisfrutados(idEmpleado,idTipoPermiso,año)
  {
    let key=idEmpleado+"|"+idTipoPermiso+"|"+año;
    if (key in this.disfrutados)
      return this.disfrutados[key];
    else
      return 0;
  }

  async aceptar(i)
  {
    //console.log(this.solicitudes[i].fechas);
    let hora_inicio=this.solicitudes[i].horainicio;
    let hora_fin=this.solicitudes[i].horafin;
    let fics=[];
    for(let dia of this.solicitudes[i].fechas.split(","))
    {
        let fecha=this.solicitudes[i].fechainicio.substr(0,4);
        fecha+="-"+dia.substr(3,2)+"-"+dia.substr(0,2);
        let fecha_inicio=fecha;
        let fecha_fin=fecha;
        if (hora_inicio!=null && hora_fin!=null)
        {
           fecha_inicio+=" "+hora_inicio;
           fecha_fin+=" "+hora_fin;
        }
        //console.log(fecha_inicio,fecha_fin);
        let fic={inicio:fecha_inicio,fin:fecha_fin,idempleado:this.solicitudes[i].idEmpleado,
                 tipo:'permiso',detalle:this.solicitudes[i].solicitud};
        fics.push(fic);
    }
    console.log(fics);
    await this.httpClient.post<Respuesta>(this.gds.urlBaseDL+'horario',fics).toPromise().then((r)=>{
      console.log(r);
    });
    this.procesarSolicitud(i,"ACEPTADA");
    this.loadDisfrutados();
  }

  rechazar(i)
  {
    this.procesarSolicitud(i,"RECHAZADA");
  }
  
  procesarSolicitud(i,estado)
  {
    let id=this.solicitudes[i]['idSolicitud'];
    this.gds.updateSolicitud(id,{estado:estado,contestacion:this.solicitudes[i]['motivo']}).then((r)=>{
      console.log(r);
    });
    let idEmpleado=this.solicitudes[i]['idEmpleado'];
    let body="Estimado "+this.solicitudes[i]['empleado']+":\n\n"
              +"Su petición <<"+this.solicitudes[i]['solicitud']+">> para los días: "+this.solicitudes[i]['fechas']
              +" ha sido "+estado+".\n\n";
    if(this.solicitudes[i]['motivo']!="") body+="Motivo: "+this.solicitudes[i]['motivo']+".";
    body+="\n\nUn saludo\n\n"+this.gds.name_cliente;
    this.gds.emailToEmpleado(idEmpleado,"Solicitud "+estado,body).then((r)=>{
      console.log(r);
    });
    
    if (estado=='ACEPTADA')
    {
      let body2=`La empresa ${this.gds.name_cliente} ha aceptado la petición de permiso o licencia:\n\n`;
      body2+=`<< ${this.solicitudes[i]['solicitud']} >>, realizada por el trabajador: ${this.solicitudes[i]["empleado"]}, para las fechas: ${this.solicitudes[i]['fechas']}\n\nSaludos`;
      console.log(body2);
      // "Estimado "+this.solicitudes[i]['empleado']+":\n\n"
      //         +"Su petición <<"+this.solicitudes[i]['solicitud']+">> para los días: "+this.solicitudes[i]['fechas']
      //         +" ha sido "+estado+".\n\n";
      // if(this.solicitudes[i]['motivo']!="") body2+="Motivo: "+this.solicitudes[i]['motivo']+".";
      // body2+="\n\nUn saludo\n\n"+this.gds.name_cliente;
      this.gds.emailToLaboral("Solicitud Permiso/Licencia "+estado,body2).then((r)=>{
        console.log(r);
      });
    }
    this.solicitudes.splice(i,1);
  }
//----------------------------------------permiso code-----
updateTime(event,data,type){
//console.log("event....",event.target.checked,data,index);
  this.checkbox=false;
    this.updatedData.forEach((element,i) => {
      element.value.forEach((value,index)=> {
        if(JSON.stringify(value) === JSON.stringify(data) ){
          if(type=='entrada'){
            data.entradaSwitch=event.target.checked;
          console.log("data.switch1",data.switch1)
         }else{
            data.salidaSwitch=event.target.checked;
          console.log("data.switch2",data.switch2)
          }
         // element[i].value[index].switch=event.target.checked==true?false:true;
        }
      });
    });
    
   this.horarioData=data;
}
accept(){
console.log("this.horarioData",this.horarioData);
for(let data in this.horarioData){
  console.log(data,":",this.horarioData[data])
}

let status:statusType
let {entradaUpdate,salidaUpdate,entradaSwitch,salidaSwitch,idhorario}=this.horarioData;
if(entradaSwitch&&salidaSwitch){
  status="acceptBoth"
}else if(entradaSwitch){
status="acceptEntrada"
  }else{
    status="acceptSalida"
  }
let data={
  entradaUpdate,
  salidaUpdate,
  status
}
this.gds.misspunchUpdateEmployees(idhorario,data).subscribe((e)=>{
  this.fetchMisspunch();

  console.log(e)
})

console.log("data accept",data);
}
reject(){

console.log("this.horarioData",this.horarioData);
let status:statusType
let {entradaUpdate,salidaUpdate,entradaSwitch,salidaSwitch,idhorario}=this.horarioData;
if(entradaSwitch&&salidaSwitch){
  status="rejectBoth"
}else if(entradaSwitch){
status="rejectEntrada"
  }else{
    status="rejectSalida"
  }
let data={
  entradaUpdate,
  salidaUpdate,
  status
}
this.gds.misspunchUpdateEmployees(idhorario,data).subscribe(e=>{
  console.log(e)
  this.fetchMisspunch();
})
console.log("data reject",data);

}
fetchMisspunch(){
  this.gds.clientDetailsObservable.subscribe((client:any)=>{
    let currentDate=new Date().toISOString().split('T')[0];
    console.log("cureent date",currentDate)
    this.gds.getMisspunchEmployees(client.idCliente,currentDate).subscribe((e:any)=>{
      this.updatedData=[...(e?.updatedData)]
      this.updatedData.forEach(element => {
        element.value.forEach(value => {
          value.entradaSwitch=false;
          value.salidaSwitch=false; 
        });
      });
      console.log("mispunch  this.updatedData", this.updatedData)

    })
  })
}
showDescription(description){
  this.displayDescription=true;
this.description=description;
console.log("description",description)
}
}
