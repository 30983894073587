import {formatDate} from '@angular/common';

export const enum ORIGEN_TAREA {UPLOAD_EXTRANET=6,SISTEMA=7};
export const enum TIPO_TAREA {TIPO_TODAS=-1,TIPO_GENERAL=0,TIPO_ESPECIFICA=1};

export class Documento {
    idUsuario?:Number=0;
    idCliente?:Number=0;
    descripcion:String;
    idMateria?:Number=0;
    idSubMateria?:Number=0;

    constructor(d:Documento)
    {
        Object.assign(this,d);
    }
}

export class Tarea {
    idUsuario?:Number=0;
    idCliente:Number;
    notas?:String='';
    concepto?:String='';
    Tarea:String;
    tipo:TIPO_TAREA;
    idCreador?:Number;
    origen?:Number;
    fecha?:String=(new Date()).toISOString().substr(0,10);
    observaciones?:String='';
    estado?:Number=1;

    constructor(d:Tarea)
    {
        Object.assign(this,d);
    }
}

/*
export class Profile {
    name: string;
    email: string;
    age: number;
  
    constructor({ name = 'Ricardo', email = 'ricardo@aaa.com', age = 47 }) {
        this.name = name;
        this.email = email;
        this.age = age;
        return this;
      }
}
*/