import { Component, OnInit,Input,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import {MenuItem} from 'primeng/api';     
import {ChartModule} from 'primeng/chart';
import {TableModule} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { FechaTipadaPipe } from '../../libs/fechaTipada.pipe';
import { Respuesta } from '../../respuesta';
import { Utilidades } from '../../libs/utilidades';
import { UtilService } from '../../servicios/util.service';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {FileUpload} from 'primeng/fileupload';
import {DialogModule} from 'primeng/dialog';
import { AsuntoComponent } from '../../componentes/asunto/asunto.component';


@Component({
  selector: 'app-misasuntos',
  templateUrl: './misasuntos.component.html',
  styleUrls: ['./misasuntos.component.scss']
})
export class MisasuntosComponent implements OnInit {

  @ViewChild('asunto') asunto:AsuntoComponent;  
  tareas:any=[];

  constructor(private httpClient: HttpClient,private gds:GlobaldataService,private us:UtilService,private messageService: MessageService) { }

  ngOnInit() {
	//this.asunto.show(13516);	

	/*
	if(excludeUploads) condi+=((condi.equals(""))?"":" AND ")+" T.tarea<>'Documentos enviados desde Extranet' ";
	if(excludeContabilizar) condi+=((condi.equals(""))?"":" AND ")+" T.tarea not in ('Contabilizar facturas recibidas','Contabilizar facturas emitidas') ";
	*/

	this.httpClient.post<Respuesta>(this.gds.urlBaseDL+'tareas/query',{
		cliente:this.gds.id_cliente,
		order:'fecha DESC,idTarea DESC',
		where:"tarea not in ('Documentos enviados desde Extranet','Contabilizar facturas recibidas','Contabilizar facturas emitidas')"
	}).subscribe((res)=>{
		console.log(res);
		this.tareas=res.resp;	
	}); 
  }
  
  verTramite(ev)
  {
	  var id:Number=this.gds.getID(ev);
	  if(id>0) this.asunto.show(id);
	  return false;
  }

}
