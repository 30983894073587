import { Routes,RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { Login2Component } from './login2/login2.component';
import { MainComponent } from './main/main.component';
import { RecientesComponent } from './main/recientes/recientes.component';
import {DialogModule} from 'primeng/dialog';

const routes: Routes = [
  {path:'', component:Login2Component, pathMatch:'full'},
  {path: 'login', component: Login2Component },
  {path: 'login/user/:user/apikey/:apikey', component: Login2Component } ,
  {path: 'login/user/:user/apikey/:apikey/idCliente/:idCliente', component: Login2Component } 
];

@NgModule({
  declarations: [ 
    
  ],
  imports: [
    
    RouterModule.forRoot(routes)  //
    ],
  exports: [
    RouterModule,
  ],
  providers: [],

})
export class AppRoutingModule {}





 
