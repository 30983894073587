<app-header-breadcrumb></app-header-breadcrumb>
<app-asunto #asunto id="asunto">
</app-asunto>
<app-solicitudes></app-solicitudes> 
<app-misspunch-solicitudes></app-misspunch-solicitudes> 


<p class="tituloSeccion">Publicaciones más recientes</p>

<div class="p-grid " style="margin-top: 20px;">

	<div class="p-col-12 p-md-12 p-lg-12">
		<div class="my-card" style="width:100%;">
			<div class="p-grid  p-justify-between" style="margin-left:0px;width:100%;" (click)="gds.viewDoc($event);">
				<div [attr.data-id]='item[0]' *ngFor="let item of publicaciones;" class="enlace p-col-12 p-md-6 p-lg-3 p-grid p-align-center p-justify-between">
					<div class="p-col-2 p-md-2 p-lg-2"><i class="fa fa-file icono" style="font-size:3rem;"></i></div>
					<div class="p-col-10 p-md-10 p-lg-10">{{item[1]}}</div>
				</div>
			</div>
		</div>
	</div>

    <div class="p-col-12 p-md-4 p-lg-4" [ngClass]="{'hidemenu':  access_mylatest_documents!='0'}">
		<p-card header="Mis últimos documentos" [style]="{width: '100%','overflow-y':'auto'}" styleClass="ui-card-custom">
		<div>
		<p-scrollPanel>
			<!-- <ul (click)="gds.viewDoc($event);" style="white-space: nowrap;" class="customlista">		
			<li *ngFor="let item of recientes;" [attr.data-id]='item[0]'>
				<div class="fecha">{{item[3].substr(0,10)}}</div> 
				<i class="fa fa-file icono"></i>
				{{item[1]}}
				<i *ngIf="item[2]" class="pi pi-check"></i>
			</li>
			</ul> -->

			<ul (click)="selectMisDocument($event);" style="white-space: nowrap;" class="customlista">		
				<li *ngFor="let item of recientes;" [attr.data-id]='item[0]' [attr.data-restricted]='item[4]'>
					
					<div class="fecha">{{item[3].substr(0,10)}}</div> 
					<!-- <i class="fa fa-file icono" ></i>
					<i class="fa fa-circle red-circle"></i> -->

					<i [ngClass]="item[4] == '1' ? 'fa fa-circle red-circle' : 'fa fa-file icono'" ></i>
					{{item[1]}}
					<!-- --{{item[4]}}---{{item[0]}} -->
					<i *ngIf="item[2]" class="pi pi-check"></i>
				</li>
				</ul>
				
		</p-scrollPanel>
		</div>
		</p-card>
	</div>
    
   
	<div class="p-col-12 p-md-4 p-lg-4" [ngClass]="{'hidemenu':  access_mylatest_affairs!='0'}">
		<p-card header="Mis últimos asuntos" [style]="{width: '100%','overflow-y':'auto'}" >
		<div>
		<p-scrollPanel>
		<ul (click)="verTramite($event);" class="customlista">		
		<li *ngFor="let asunto of asuntos;" [attr.data-id]='asunto.idTarea'>
			<div class="fecha">{{asunto.fecha.substr(0,10)}}</div> 
			<i class="pi pi-list icono"></i>
			{{asunto.Tarea}}
		</li>
		</ul>
		</p-scrollPanel>
		</div>
		</p-card> 
	</div>
	<div class="p-col-12 p-md-4 p-lg-4" [ngClass]="{'hidemenu':  access_mylatest_documents!='0'}">
		<p-card header="Colaboradores " [style]="{width: '100%','overflow-y':'auto'}" styleClass="ui-card-custom">
		<div>
		<p-scrollPanel>
			<div *ngFor="let cols of colaboratorsList; let  i = index">
				<div>
				<div class="p-grid">
					<div class="p-col-3 p-md-3 p-lg-3 ">
						<img *ngIf="cols.profileUrl" src="{{cols.profileUrl}}" class="profile" >
						<img *ngIf="!cols.profileUrl" src="https://portalasesoria.oficinadigital.eu/oficinaDigital/userImages/passport-photo.jpg" class="profile" >
						
					</div>
					<div class="p-col-9 p-md-9 p-lg-9">
						<span>{{cols.nombre}}</span><br/>
						<span>Servicios: {{cols.services.join(", ")}}</span><br/>
						<span *ngIf="cols.email"> <a href= "mailto:{{cols.email}}"> {{cols.email}}</a></span><br/><br/>
						<span>
							<span *ngIf="cols.chatUrl" class="p-button p-component p-ripple p-button-label ng-star-inserted" (click)="openUrlForColaborator(cols.chatUrl);" >Llamada/Chat</span>
							&nbsp;&nbsp;
							<span *ngIf="cols.videoUrl" class="p-button p-component p-ripple p-button-label ng-star-inserted" (click)="openUrlForColaborator(cols.videoUrl);">Video Conferencia</span>
						</span>
					</div>
				</div>
				<hr/>
			</div>
			</div>
		</p-scrollPanel>
		</div>
		</p-card>
	</div>
	<div style="padding: 10px;">
</div>
	<!--
		<div class="p-col-12 p-md-12 p-lg-12">
		<p-card header="Últimas publicaciones de la asesoría" class="ui-card-v">
			<ul (click)="gds.viewDoc($event);" class="customlista">		
			<li *ngFor="let item of publicaciones;" [attr.data-id]='item[0]'>
				<div class="fecha">{{item[2].substr(0,10)}}</div> 
				<i class="fa fa-file icono"></i>
				{{item[1]}}
			</li>
			</ul>
		</p-card>
	</div>
	-->

	

</div>


	