import { Component, OnInit } from '@angular/core';
import {  Input,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import {MenuItem} from 'primeng/api';     
import {ChartModule} from 'primeng/chart';
import {TableModule} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { FechaTipadaPipe } from '../../libs/fechaTipada.pipe';
import { Utilidades } from '../../libs/utilidades';
import { UtilService } from '../../servicios/util.service';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {FileUpload} from 'primeng/fileupload';
import { Router,ActivatedRoute  } from "@angular/router";
import { Respuesta } from 'src/app/respuesta';

@Component({
  selector: 'app-docsemp',
  templateUrl: './docsemp.component.html',
  styleUrls: ['./docsemp.component.scss']
})
export class DocsempComponent implements OnInit {

  public empleados=[];
  empleado:number=0;
  public ejercicio:number;

  itemsArbol:TreeNode[];	
  
  constructor(private httpClient: HttpClient,public gds:GlobaldataService,private us:UtilService,private messageService: MessageService,
    private router:Router, private routera: ActivatedRoute) { }

  ngOnInit(): void {
		this.empleados=this.gds.getEmpleados();
		console.log(this.empleados);
		this.empleado=this.empleados[0].value;
		this.ejercicio=0;
      	this.filtrar();
  }

  public filtrarEj()
  {
	let old_emp=this.empleado;
	//console.log("EJ:"+this.ejercicio);
	if(this.ejercicio==0 || this.ejercicio==null)
	{
		this.empleados=this.gds.getEmpleados();
		let enc=this.empleados.find(x=>x.value==old_emp);
		//console.log("=>",old_emp,enc);
		if (enc!=undefined) this.empleado=old_emp;
		this.filtrar();
	}	
	else
	{
		let url="fields=E.idempleado as value,T.empleado as label";
		url=url+"&joins=trabajador,documentos(E.idempleado=>D.idempleado)&oder:T.empleado";
		url=url+"&where=E.idcliente="+this.gds.id_cliente+" and substr(D.fechaejercicio,1,4)='"+this.ejercicio+"'";
		url=url+"&groupby=E.idempleado,T.empleado"
		url=url+"&order=T.empleado";
		this.httpClient.get<Respuesta>(this.gds.urlBaseDL+"empleado?"+url).subscribe((res)=>{
			//console.log(res);
			this.empleados=res.resp;
			let enc=this.empleados.find(x=>x.value==old_emp);
			//console.log("--->",old_emp,enc);
			if (enc!=undefined) this.empleado=old_emp; else this.empleado=0;
			//console.log(this.empleado);
			this.filtrar();
		});
	}
  }

  public filtrar()
  {
    if(this.empleado==0 || this.empleado==null) {this.itemsArbol=[]; return;}

	let ej=this.ejercicio;
	if (ej==null) ej=0;

    var params={
			fields:"idDocumento,descripcion,idMateria,idSubMateria,fechaEntrada,tipoFecha,fechaEjercicio",
			joins:"materias(idMateria),materias(idSubMateria)",
			order:"M.materia,M2.materia,fechaEjercicio DESC,fechaIncorporacion",
			where:"idEmpleado="+this.empleado+" and idCliente="+this.gds.id_cliente+" AND D.idSubMateria<>0 and D.idSubMateria!=16 and D.idSubMAteria!=18 AND seguridad=1 "+((ej!=0)?" AND (fechaEjercicio LIKE '"+ej+"%')":""),
			tree:"materias.materia(idMateria,materias.materia),materias2.materia(idSubMateria)=>idDocumento,descripcion,fechaEntrada,tipoFecha,fechaEjercicio",
		};
		
		this.httpClient.post<any>(this.gds.urlBaseDL+"documentos/query",{'vlo':Utilidades.packVLO(params)}).subscribe((res)=>{

			res=res.resp;
			
			for (var rama of res)
			{
				rama['collapsedIcon']="fa fa-folder";
				rama['expandedIcon']="fa fa-folder-open";
				rama['type']="rama";
				for (var subrama of rama['children'])
				{
					subrama['collapsedIcon']="fa fa-folder";
					subrama['expandedIcon']="fa fa-folder-open";
					subrama['type']="rama";
					for (var item of subrama['children'])
					{
						item['type']="doc";
						item['icon']="fa fa-file";
						item['label']=item['descripcion'];
						item['key']=item['idDocumento'];
						//item['data']={'fechaEntrada':item['fechaEntrada'].substr(0,10),'tipoFecha':item['tipoFecha'],'fechaEjercicio':item['fechaEjercicio']}
						item['data']={'fechaEjercicio':item['fechaEjercicio']};
					}
				}
			}
			this.itemsArbol=res;
		});
  }
  

}
