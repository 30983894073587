<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Enviar consulta a la asesoría</p>

<p-toast position='center'></p-toast>

<p-steps [model]="itemss"  [(activeIndex)]="activeIndex2" [readonly]="true"></p-steps>
<br/>
<div *ngIf="activeIndex2==0">
<textarea placeholder="Puede escribir aquí su nueva consulta..."  style="width:100%;resize:none" autoResize="false" rows="6" [(ngModel)]="consulta"></textarea>
<br/>
<div class="centrado">
		<button pButton type="button" label="Siguiente" class="ui-button-raised" (click)="next()"></button>
</div>
	
</div>

<div *ngIf="activeIndex2==1">
	<br/>
	<div class="grid grid-2c">
		<div class="row-1c2" style="font-size:1.0em">
			Si lo desea, puede adjuntar documentos a su consulta:
		</div>	
		<div class="row-1c2">
			<p-fileUpload id="envioDoc" #envioDoc [showUploadButton]="false" chooseLabel="Seleccionar ficheros"  cancelLabel="Cancelar" multiple="true"  maxFileSize="10000000" customUpload="true" (uploadHandler)="myUploader($event)"></p-fileUpload>
		</div>
		<div>
			<button pButton type="button" label="Anterior" class="ui-button-raised" (click)="anterior()"></button>
		</div>
		<div>
			<button pButton type="button" label="Siguiente" class="ui-button-raised" (click)="next2()"></button>
		</div>
	</div>	
</div>

<div *ngIf="activeIndex2==2">
<div style="display:flex;justify-content: center;align-items: center;">
<div style="text-align: center;min-width:900px;max-width:1000px;height:200px;background-color:blue;">contenido</div>
</div>
</div>

