import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { Respuesta } from '../../respuesta';
import {formatDate} from '@angular/common';
import {MessageService} from 'primeng/api';
import {Utilidades} from '../../libs/utilidades';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { Data, Router } from '@angular/router';
declare let jsPDF;

export interface Empleado{ 
  value:number; 
  empleado:string;
}

@Component({
  selector: 'app-consultaglobalmonth',
  templateUrl: './consultaglobalmonth.component.html',
  styleUrls: ['./consultaglobalmonth.component.scss']
})

export class ConsultaglobalmonthComponent implements OnInit {

  disfrutados:any[]=[];

  public selectedEmpleado:Empleado=null;
  public empleados:Empleado[]=[];
  
  
  //empleado:number=0;
  public ejercicio:number=this.gds.ejercicio_actual;
// --------------------horario component variables------------------------
ejercicioo: number;
mes: string = "01";
fromMes: string = "01";
toMes: string = "01";



empleadoss: any = [];
empleadoss3001: any = [];
public selectedEmpleados: any = [];
ClentName: any;
clientId: any;
month: string;
selectedEmployessForPdfDownload = [];
// --------------------horario component variables------------------------
 
  constructor(private messageService: MessageService,private httpClient: HttpClient,public gds:GlobaldataService,private router: Router) {
    this.gds.clientDetailsObservable.subscribe((data: any) => {
      this.ClentName = data.cliente, this.clientId = data.idCliente; console.log("client datatatat", data)
    
    })
   }

   subcompanies: any = [];
   
   clientesSelected: any = [];
   clientesAll: any = [];
   clientesShowHideAll: any = [];

   isMasterSel:boolean;
  categoryList:any;
  checkedCategoryList:any;
  
   

  ngOnInit(): void {

   
   
    this.clientesSelected = [this.gds.id_cliente];
    

    //this.clientesAll = [3001,5001,6001,7001];
    this.loadcompanylist();
    this.clientesShowHideAll = [];

    console.log("all clcient",this.clientesSelected);
    this.filtrarEj();
    this.ejercicioo = this.gds.ejercicio_actual;
    this.mes = this.gds.mes_actual;
    console.log("mes...", this.mes)
    console.log("mes...", this.gds.meses)
    this.fromMes = this.gds.mes_actual;
    this.toMes = this.gds.mes_actual;
    

    this.allLoadEmpleados();
   


    this.isMasterSel = false;
  
    

      // this.categoryList = [
      //   {idCliente:1, Cliente:'PHP',isSelected:false},
      //   {idCliente:2,Cliente:'Laravel',isSelected:false},
      //   {idCliente:3,Cliente:'Angular',isSelected:true},
      //   {idCliente:4,Cliente:'React',isSelected:true},
      //   {idCliente:5,Cliente:'Vue',isSelected:true},
      //   {idCliente:6,Cliente:'JQuery',isSelected:false},
      //   {idCliente:7,Cliente:'Javascript',isSelected:false},
      // ];
  
    this.getCheckedItemList();

    
    
  }







  checkUncheckAll() {
    for (var i = 0; i < this.categoryList.length; i++) {
      this.categoryList[i].isSelected = this.isMasterSel;
    }
    this.getCheckedItemList();
  }
   
  isAllSelected() {
    this.isMasterSel = this.categoryList.every(function(item:any) {
        return item.isSelected == true;
      })
    this.getCheckedItemList();
  }
  
  getCheckedItemList(){
    this.checkedCategoryList = [];
    var clientesShowHideAllItem =[];


    for (var i = 0; i < this.categoryList.length; i++) {
      if(this.categoryList[i].isSelected)
      {
        this.checkedCategoryList.push(this.categoryList[i]);
        clientesShowHideAllItem.push(this.categoryList[i].idCliente);
      }
      
    }




    this.checkedCategoryList = JSON.stringify(this.checkedCategoryList);

    this.clientesShowHideAll = clientesShowHideAllItem;

    this.allLoadEmpleados();



    this.gds.subCompanyObservable.subscribe((data: any) => {
      console.log("name....",data);
      this.subcompanies = data;
     
     });


    console.log("name xxxxxxxxxxxx....",clientesShowHideAllItem);
    console.log("name yyyyyyyyyy....",this.subcompanies);
    let itemArr =[];
     for (var s = 0; s < this.subcompanies.length; s++) {
      let itemobj ={};
      if(clientesShowHideAllItem.indexOf(this.subcompanies[s].idCliente)!=-1)
      {
          itemobj["idCliente"] = this.subcompanies[s].idCliente;
          itemobj["Cliente"] =  this.subcompanies[s].Cliente;
          itemobj["apikey"] = this.subcompanies[s].apikey;
          itemobj["cif"] = this.subcompanies[s].cif;
          itemArr.push(itemobj);

      }

      


      // console.log("name kkkkkkkk....",this.subcompanies[s].Cliente);
      
    }

    console.log("name kkkkkkkk....",itemArr);
    this.subcompanies=itemArr;



    
  }










  allLoadEmpleados()
  {
     //alert("aaaaaaaaaaa");
    // this.loadEmpleados(3001);
    // this.loadEmpleados(5001);
    // this.loadEmpleados(6001);
    // this.loadEmpleados(7001);

        this.gds.subCompanyObservable.subscribe((data: any) => {
          console.log("fffffffffllllppp....",data);
          for (let g = 0; g <= data.length; g++) {
            var idCliente=data[g].idCliente;

            console.log("CLIENT ID....",idCliente);
            this.loadEmpleados(idCliente);
           }
          });


  }

  allLoadEmpleadosMonth()
  {
//alert("aaaaaaaaaaa");
    // this.loadEmpleados(3001);
    // this.loadEmpleados(5001);
    // this.loadEmpleados(6001);
    // this.loadEmpleados(7001);

        this.gds.subCompanyObservable.subscribe((data: any) => {
          console.log("fffffffffllllppp....",data);
          for (let g = 0; g <= data.length; g++) {
            var idCliente=data[g].idCliente;
            this.loadEmpleadosMonth(idCliente);
           }
          });


  }

  allCompanyLoadEmpleados() {

  
  
  }
  


  loadcompanylist()
  {

    this.gds.subCompanyObservable.subscribe((data: any) => {
      console.log("name....",data);
      this.subcompanies = data;
     
     });
   
     console.log("subcompanies....",this.subcompanies);



      let data=this.subcompanies;
      let mainList = [];
      let idClienteArr=[];
      for (let s=0;s<data.length;s++) {
      let list = {};
      //console.log("xxxxxxxxxxxxxxxx",data[s]);

      list["idCliente"] = data[s].idCliente; 
      list["Cliente"] =  data[s].Cliente;
      list["isSelected"] = true;
      mainList.push(list);
      idClienteArr.push(data[s].idCliente);
      }
      console.log("subcompanies refine....",mainList);
      console.log("idClienteArr refine....",idClienteArr);
      this.categoryList=mainList;
      this.clientesAll=idClienteArr;

  }

  loadPermisos()
  {
    if(this.selectedEmpleado==null) {this.disfrutados=[];return;}
    let url2='solicitudes?'
    +'&fields=idtipopermiso,solicitud,fechainicio,fechafin,horainicio,horafin,fechas,estado'
    +'&where=idEmpleado='+this.selectedEmpleado.value+" and idTipoPermiso!=0  and estado!='SOLICITUD'and year(fechainicio)='"+this.ejercicio+"'"
    +'&order=solicitud,fechainicio DESC'
    +'&tree=solicitud->permisos=>fechainicio,fechafin,horainicio,horafin,fechas,estado';


    console.log("url............permiso",url2)
    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
    console.log("url............ r.resp", r.resp)

      for(let p of r.resp)
      {
          let dias=0;
          for(let d of p.permisos)
          {
            let cd=d.fechas.split(",").length;
            d['dias']=cd; 
            if (cd>1)
              d['periodo']=formatDate(d.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(d.fechafin,'dd/MM/yyyy','en-US');
            else
              d['periodo']=formatDate(d.fechainicio,'dd/MM/yyyy','en-US');

            if(d.horainicio!=null && d.horafin!=null)
            {
              d['periodo']+=" "+d.horainicio.substr(0,5)+"-"+d.horafin.substr(0,5);
            }
            if(d.estado!="RECHAZADA") dias+=cd;
          }
          p['dias']=dias; 
      }
      console.log(r.resp);
      this.disfrutados=r.resp;
    });
  }

  public filtrarEj()
  {
	  let old_emp=this.selectedEmpleado;
		//let url="fields=E.idempleado as value,T.empleado,S.idsolicitud,C.Cliente";
    let url="fields=E.idempleado as value,T.empleado,S.idsolicitud";
		//url=url+"&joins=trabajador,solicitudes(E.idempleado=>S.idempleado),clientes(E.idCliente=>C.idCliente)";
    url=url+"&joins=trabajador,solicitudes(E.idempleado=>S.idempleado)";
		// url=url+"&where=E.idcliente="+this.gds.id_cliente+" and year(S.fechainicio)='"+this.ejercicio+"'";
    //url=url+"&where=E.baja='0' and E.idcliente="+this.gds.id_cliente+" and year(S.fechainicio)='"+this.ejercicio+"'";
    url=url+"&where=E.baja='0' and E.idcliente in("+this.clientesAll+") and year(S.fechainicio)='"+this.ejercicio+"'";
		url=url+"&groupby=E.idempleado,T.empleado"
		//url=url+"&order=T.empleado";
    url=url+"&order=E.idCliente";
    console.log("url.................",url);
		this.httpClient.get<Respuesta>(this.gds.urlBaseDL+"empleado?"+url).subscribe((res)=>{
      console.log("data.......",res.resp);
			this.empleados=res.resp;      
      if(old_emp!=null)
      {
        let enc=this.empleados.find(x=>x.value==old_emp.value);
        if (enc!=undefined) this.selectedEmpleado=enc; else this.selectedEmpleado=null;
      }
      if(this.selectedEmpleado==null && this.empleados.length>0) this.selectedEmpleado=this.empleados[0]; 
			this.loadPermisos();
		});
  }

  showfechas(fecha)
  {
    return Utilidades.replaceAll(fecha,",",", "); 
  }
// ---------------------------------------horario code--------------------------


showHorarioDiario(idEmpleado) { 

  //alert(this.fromMes+"---"+ this.toMes);
//this.router.navigate(['main/hdiarioglobal', idEmpleado, this.ejercicioo, this.mes]);
this.router.navigate(['main/hdiarioglobal', idEmpleado, this.ejercicioo, this.fromMes, this.toMes]);
}
public click_tabla(e) {
  if ('data-id' in e.target.attributes) this.showHorarioDiario(e.target.attributes["data-id"].value);
}

public loadEmpleados(clientesId) {
  // let url2 = 'empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
  //   + "&fields=E.idEmpleado,T.empleado,time_format(sec_to_time(sum(timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo"
  //   + '&where=E.baja in(0,1) and (H.tipo="" or H.tipo="visita") and  E.idCliente=' + this.gds.id_cliente + " and year(H.inicio)=" + this.ejercicioo + " and month(H.inicio)=" + this.mes
  //   + '&groupby=T.empleado'
  //   + '&order=T.empleado';


  // let url2 = 'empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
  //   + "&fields=E.idEmpleado,T.empleado,time_format(sec_to_time(sum(timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo"
  //   + '&where=E.baja in(0) and (H.tipo="" or H.tipo="visita") and  E.idCliente=' + this.gds.id_cliente + " and year(H.inicio)=" + this.ejercicioo + " and month(H.inicio)=" + this.mes
  //   + '&groupby=T.empleado'
  //   + '&order=T.empleado';

  // let url2 = 'empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado),clientes(E.idCliente=>C.idCliente)'
  // + "&fields=E.idEmpleado,T.empleado,time_format(sec_to_time(sum(timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo,C.Cliente"
  // + '&where=E.baja in(0) and (H.tipo="" or H.tipo="visita") and  E.idCliente=' + this.gds.id_cliente + " and year(H.inicio)=" + this.ejercicioo + " and month(H.inicio)=" + this.mes
  // + '&groupby=T.empleado'
  // + '&order=T.empleado';

  // let url2 = 'empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
  // + "&fields=E.idEmpleado,T.empleado,time_format(sec_to_time(sum(timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo,E.idCliente"
  // + '&where=E.baja in(0) and (H.tipo="" or H.tipo="visita") and  E.idCliente =' + clientesId + " and year(H.inicio)=" + this.ejercicioo + " and (   month(H.inicio) >="+ this.fromMes+" and month(H.inicio)<=" + this.toMes+")   "
  // + '&groupby=T.empleado'
  // + '&order=E.idCliente asc'; 

  let url2 = 'empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
  + "&fields=E.idEmpleado,T.empleado,time_format(sec_to_time(sum(timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo,E.idCliente"
  + '&where=E.baja in(0)  and  E.idCliente =' + clientesId + " and year(H.inicio)=" + this.ejercicioo + " and (   month(H.inicio) >="+ this.fromMes+" and month(H.inicio)<=" + this.toMes+")   "
  + '&groupby=T.empleado'
  + '&order=E.idCliente asc';
  
  console.log(this.gds.urlBaseDL + url2);
  console.log("called in load employeee")

  console.log("wwwwwwwwwwwww"+this.clientesShowHideAll+"----kokoooo--"+clientesId);

   // if(this.clientesShowHideAll==clientesId) 
   //this.clientesShowHideAll=[5001,6001,7001];
  if(this.clientesShowHideAll.indexOf(clientesId) !== -1) {
    return this.httpClient.get(this.gds.urlBaseDL + url2).toPromise().then((r) => {
      this.empleadoss[clientesId] = r['resp'];
      console.log(this.empleadoss);
    }); 
  }
  else{

    return this.httpClient.get(this.gds.urlBaseDL + url2).toPromise().then((r) => {
      this.empleadoss[clientesId] = "";
      console.log(this.empleadoss);
    });

  }




  
}







public loadEmpleadosMonth(clientesId) {
 

  // let url2 = 'empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
  // + "&fields=E.idEmpleado,T.empleado,time_format(sec_to_time(sum(timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo,E.idCliente"
  // + '&where=E.baja in(0) and (H.tipo="" or H.tipo="visita") and  E.idCliente =' + clientesId + " and year(H.inicio)=" + this.ejercicioo + " and month(H.inicio)=" + this.mes
  // + '&groupby=T.empleado'
  // + '&order=E.idCliente asc';

  // let url2 = 'empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
  // + "&fields=E.idEmpleado,T.empleado,time_format(sec_to_time(sum(timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo,E.idCliente"
  // + '&where=E.baja in(0) and (H.tipo="" or H.tipo="visita") and  E.idCliente =' + clientesId + " and year(H.inicio)=" + this.ejercicioo + " and (   month(H.inicio) >="+ this.fromMes+" and month(H.inicio)<=" + this.toMes+")  "
  // + '&groupby=T.empleado'
  // + '&order=E.idCliente asc';


  let url2 = 'empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
  + "&fields=E.idEmpleado,T.empleado,time_format(sec_to_time(sum(timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo,E.idCliente"
  + '&where=E.baja in(0)  and  E.idCliente =' + clientesId + " and year(H.inicio)=" + this.ejercicioo + " and (   month(H.inicio) >="+ this.fromMes+" and month(H.inicio)<=" + this.toMes+")  "
  + '&groupby=T.empleado'
  + '&order=E.idCliente asc';

  console.log("ooooooooooooooooooooooooo",this.gds.urlBaseDL + url2);
  console.log("called in load employeee")

  console.log("aaaaaaa"+this.clientesShowHideAll+"----zokoooo--"+clientesId);

   // if(this.clientesShowHideAll==clientesId) 
   //this.clientesShowHideAll=[5001,6001,7001];
  if(this.clientesShowHideAll.indexOf(clientesId) !== -1) {
    return this.httpClient.get(this.gds.urlBaseDL + url2).toPromise().then((r) => {
      this.empleadoss[clientesId] = r['resp'];
      console.log(this.empleadoss);
    }); 
  }
  else{

    return this.httpClient.get(this.gds.urlBaseDL + url2).toPromise().then((r) => {
      this.empleadoss[clientesId] = "";
      console.log(this.empleadoss);
    });

  }




  
}








informeHorario() {
  //if(!this.selectedEmpleados.length) return;
  let ids = this.selectedEmpleados.map((e) => { return e.idEmpleado });
  this.messageService.add({ severity: 'info', summary: 'Informe horario', detail: 'En breve recibirá el informe solicitado por email!' });
  return this.httpClient.post(this.gds.urlBaseBL + "reportHorario", { ids: ids, mes: this.mes, ej: this.ejercicioo, idCliente: this.gds.id_cliente }).toPromise().then((r) => {
    console.log(r);
  });
}

loadMonthlyReport() {

  let year = this.ejercicioo
  let month = this.mes
  
  //let clientId=this.clientesAll;
  //let AllclientId =clientId.join(',');

let arrClientid=[];
  for (var i = 0; i < this.categoryList.length; i++) {
    if(this.categoryList[i].isSelected)
    {
      
      arrClientid.push(this.categoryList[i].idCliente);
    }
    
  }


  let AllclientId =arrClientid.join(',');

  let ids = this.selectedEmpleados.map((e) => { return e.idEmpleado });
  let allids =ids.join(',');

  console.log("year ...",year);
  console.log("Month ...",month);
  console.log("client ...",arrClientid);
  console.log("employee id ...",ids);
  if(allids=='')
  {
    allids='0';
  }

  this.router.navigate(['main/reportglobal', allids,AllclientId, this.ejercicioo, this.mes]);

  

}


loadMonthlyReportFromTo() {

  let year = this.ejercicioo
  let fromMonth = this.fromMes
  let toMonth = this.toMes
  //let clientId=this.clientesAll;
  //let AllclientId =clientId.join(',');

  let arrClientid=[];
  for (var i = 0; i < this.categoryList.length; i++) {
    if(this.categoryList[i].isSelected)
    {
      
      arrClientid.push(this.categoryList[i].idCliente);
    }
    
  }


  let AllclientId =arrClientid.join(',');

  let ids = this.selectedEmpleados.map((e) => { return e.idEmpleado });
  let allids =ids.join(',');

  console.log("year ...",year);
  console.log("fromMonth ...",fromMonth);
  console.log("toMonth ...",toMonth);
  console.log("client ...",arrClientid);
  console.log("employee id ...",ids);
  if(allids=='')
  {
    allids='0';
  }

  this.router.navigate(['main/reportglobalMonth', allids,AllclientId, this.ejercicioo, this.fromMes, this.toMes]);

  

}


loadPermisoss_old() {
  let date = this.ejercicioo

  this.ejercicioo
  let data = {
    idCliente: this.clientId,
    fechainicio: this.ejercicioo,
    month: this.mes
  }
  const month = [
    {
      "label": "Enero",
      "value": "01"
    },
    {
      "label": "Febrero",
      "value": "02"
    },
    {
      "label": "Marzo",
      "value": "03"
    },
    {
      "label": "Abril",
      "value": "04"
    },
    {
      "label": "Mayo",
      "value": "05"
    },
    {
      "label": "Junio",
      "value": "06"
    },
    {
      "label": "Julio",
      "value": "07"
    },
    {
      "label": "Agosto",
      "value": "08"
    },
    {
      "label": "Septiembre",
      "value": "09"
    },
    {
      "label": "Octubre",
      "value": "10"
    },
    {
      "label": "Noviembre",
      "value": "11"
    },
    {
      "label": "Diciembre",
      "value": "12"
    }
  ].filter((data) => data.value == this.mes).map(data => data.label).toString();
  this.month = month
  console.log("month", month)
  console.log("in report call")
  this.gds.downloadLeaveReport(data).subscribe((res: any) => {
    console.log(res)
   res= res.map(d=> {return {
      "employeeName": d.employeeName,
      // "totalLeave": "totalLeave",
      "Asistencia al médico":d['Asistencia al médico'] ? d['Asistencia al médico']: 0,
      "Vacaciones":d['Vacaciones']?d['Vacaciones']: 0,
      "Enfermedad": d['Enfermedad']?d['Enfermedad']:0,
      "Libre disposición":d['Libre disposición']?d['Libre disposición']: 0,
      "Nacimiento de hijo":d['Nacimiento de hijo']?d['Nacimiento de hijo']: 0,
      "Matrimonio":d['Matrimonio']?d['Matrimonio']:0,
      "Fallecimiento familiar": d['Fallecimiento familiar']?d['Fallecimiento familiar']:0,
      "Realización de examen, curso":d['Realización de examen, curso']?d['Realización de examen, curso']: 0,
      "Excedencia por conciliación":d['Excedencia por conciliación']?d['Excedencia por conciliación']: 0,
      "Excedencia voluntaria":d['Excedencia voluntaria']?d['Excedencia voluntaria']: 0,
      "Baja de la empresa":d['Baja de la empresa']?d['Baja de la empresa']:0,
      "Cambio de jornada":d['Cambio de jornada']?d['Cambio de jornada']:0
    }})
    console.log("resss...",res)
    let res1=[...res]
    console.log("this.selectedEmployessForPdfDownload", this.selectedEmployessForPdfDownload)
    if (this.selectedEmployessForPdfDownload.length > 0 && res.length>this.selectedEmployessForPdfDownload.length) {
      res = res.map(data => {
        if (this.selectedEmployessForPdfDownload.includes(data.employeeName)) {
          console.log("in if")
         console.log("data man",data);
         console.log("data['Asistencia al médico']",data['Asistencia al médico']?data['Asistencia al médico']: 0);
          return data
        } else {
          console.log("in else")
          return this.selectedEmployessForPdfDownload.map((data,i) => {
            let c=i+1
            console.log("c",c)
              return {
                "employeeName": data,
                // "totalLeave": "totalLeave",
                "Asistencia al médico": 0,
                "Vacaciones": 0,
                "Enfermedad": 0,
                "Libre disposición": 0,
                "Nacimiento de hijo": 0,
                "Matrimonio": 0,
                "Fallecimiento familiar": 0,
                "Realización de examen, curso": 0,
                "Excedencia por conciliación": 0,
                "Excedencia voluntaria": 0,
                "Baja de la empresa": 0,
                "Cambio de jornada": 0
              }
          })
        }
      }).flat();
    }else if(this.selectedEmployessForPdfDownload.length >res.length){
      this.selectedEmployessForPdfDownload.forEach(data=>{
        let obj={
          "employeeName": data,
          // "totalLeave": "totalLeave",
          "Asistencia al médico": 0,
          "Vacaciones": 0,
          "Enfermedad": 0,
          "Nacimiento de hijo": 0,
          "Libre disposición": 0,
          "Matrimonio": 0,
          "Fallecimiento familiar": 0,
          "Realización de examen, curso": 0,
          "Excedencia por conciliación": 0,
          "Excedencia voluntaria": 0,
          "Baja de la empresa": 0,
          "Cambio de jornada": 0
        }
           res.push(obj)
      })
    }
    
    console.log("cccc", res)
    console.log("cccc1", res1)
    let k;
    if(res.length>this.selectedEmployessForPdfDownload.length){
     res=res.filter((a, i) => res.findIndex((s) => a.employeeName === s.employeeName) === i) 
      console.log("converted", res)
    }
    else{
    res=res.filter((a, i) =>{
      
      let p=  res1.filter((s) =>JSON.stringify(a).localeCompare(JSON.stringify(s)) ==0)
      if(p.length>0){
       return p
      }
      console.log("pppp",p)
       }  )
  }

   
//     {
// console.log("JSON.stringify(a)",JSON.stringify(a))
// console.log("JSON.stringify(s)",JSON.stringify(s))
// if(JSON.stringify(a).localeCompare(JSON.stringify(s)) ==0 ){
//   console.log("ss",s)
// return s

// }
    
    // }
  
      
    console.log("cccc final", res)

    let doc = new jsPDF('l', 'pt', 'a4');

    let columns = [
      { title: "Empleado", dataKey: "employeeName" },
      // { title: "totalLeave", dataKey: "totalLeave" },
      { title: "Asistencia al médico", dataKey: "Asistencia al médico" },
      { title: "Vacaciones", dataKey: "Vacaciones" },
      { title: "Enfermedad", dataKey: "Enfermedad" },
      { title: "Libre disposición", dataKey: "Libre disposición" },
      { title: "Matrimonio", dataKey: "Matrimonio" },
      { title: "Nacimiento de hijon", dataKey: "Nacimiento de hijo" },
      { title: "Fallecimiento familiar", dataKey: "Fallecimiento familiar" },
      { title: "Realización de examen, curso", dataKey: "Realización de examen, curso" },
      { title: "Excedencia por conciliación", dataKey: "Excedencia por conciliación" },
      { title: "Excedencia voluntaria", dataKey: "Excedencia voluntaria" },
      { title: "Baja de la empresa", dataKey: "Baja de la empresa" },
      { title: "Cambio de jornada", dataKey: "Cambio de jornada" },


    ];
    let clintName = this.ClentName;
    let width = doc.internal.pageSize.getWidth()
    doc.autoTable(columns, res, {
      margin: { top: 100 },
      addPageContent: function (data) {
        doc.setFontSize(9);

        doc.text(`INFORME DE ASISTENCIA SOLICITADO Y GENERADO HASTA ${month.toUpperCase()}/${date}`, width / 2, 20, { align: 'center' });
        doc.text(`EMPRESA:       ${clintName}`, 40, 60);
        //doc.text(`INFORME GENERADO EL: ${new Date().toLocaleDateString()}`, 40, 80);
        //doc.text(`INFORME GENERADO EL: ${new Date().getDate()}`, 40, 80);
        //doc.text(`INFORME GENERADO EL: ${new Date().toLocaleString()}`, 40, 80);
        doc.text(`INFORME GENERADO EL: ${new Date().toLocaleString('es-ES', {hour12: false})}`, 40, 80);
      }
    });

    doc.save(`${date}-${month.toUpperCase()}.pdf`);
  })
}

loadPermisoss() {
  let date = this.ejercicioo
  //let clientId=[3001,5001,6001,7001];
//let clientId=this.clientesShowHideAll;
let clientId=this.clientesAll;
  this.ejercicioo
  let data = {
    idCliente: clientId,
    fechainicio: this.ejercicioo,
    month: this.mes
  }
  const month = [
    {
      "label": "Enero",
      "value": "01"
    },
    {
      "label": "Febrero",
      "value": "02"
    },
    {
      "label": "Marzo",
      "value": "03"
    },
    {
      "label": "Abril",
      "value": "04"
    },
    {
      "label": "Mayo",
      "value": "05"
    },
    {
      "label": "Junio",
      "value": "06"
    },
    {
      "label": "Julio",
      "value": "07"
    },
    {
      "label": "Agosto",
      "value": "08"
    },
    {
      "label": "Septiembre",
      "value": "09"
    },
    {
      "label": "Octubre",
      "value": "10"
    },
    {
      "label": "Noviembre",
      "value": "11"
    },
    {
      "label": "Diciembre",
      "value": "12"
    }
  ].filter((data) => data.value == this.mes).map(data => data.label).toString();
  this.month = month
  console.log("month", month)
  console.log("in report call")
  this.gds.downloadLeaveReportGlobal(data).subscribe((res: any) => {
    console.log(res)
   res= res.map(d=> {return {
      "employeeName": d.employeeName,
      "clientName": d.clientName,
      // "totalLeave": "totalLeave",
      "Asistencia al médico":d['Asistencia al médico'] ? d['Asistencia al médico']: 0,
      "Vacaciones":d['Vacaciones']?d['Vacaciones']: 0,
      "Enfermedad": d['Enfermedad']?d['Enfermedad']:0,
      "Libre disposición":d['Libre disposición']?d['Libre disposición']: 0,
      "Nacimiento de hijo":d['Nacimiento de hijo']?d['Nacimiento de hijo']: 0,
      "Matrimonio":d['Matrimonio']?d['Matrimonio']:0,
      "Fallecimiento familiar": d['Fallecimiento familiar']?d['Fallecimiento familiar']:0,
      "Realización de examen, curso":d['Realización de examen, curso']?d['Realización de examen, curso']: 0,
      "Excedencia por conciliación":d['Excedencia por conciliación']?d['Excedencia por conciliación']: 0,
      "Excedencia voluntaria":d['Excedencia voluntaria']?d['Excedencia voluntaria']: 0,
      "Baja de la empresa":d['Baja de la empresa']?d['Baja de la empresa']:0,
      "Cambio de jornada":d['Cambio de jornada']?d['Cambio de jornada']:0
    }})
    console.log("resss...",res)
    let res1=[...res]
    console.log("this.selectedEmployessForPdfDownload", this.selectedEmployessForPdfDownload)
    if (this.selectedEmployessForPdfDownload.length > 0 && res.length>this.selectedEmployessForPdfDownload.length) {
      res = res.map(data => {
        if (this.selectedEmployessForPdfDownload.includes(data.employeeName)) {
          console.log("in if")
         console.log("data man",data);
         console.log("data['Asistencia al médico']",data['Asistencia al médico']?data['Asistencia al médico']: 0);
          return data
        } else {
          console.log("in else")
          return this.selectedEmployessForPdfDownload.map((data,i) => {
            let c=i+1
            console.log("c",c)
              return {
                "employeeName": data,
                "clientName": data['clientName']+"",
                // "totalLeave": "totalLeave",
                "Asistencia al médico": 0,
                "Vacaciones": 0,
                "Enfermedad": 0,
                "Libre disposición": 0,
                "Nacimiento de hijo": 0,
                "Matrimonio": 0,
                "Fallecimiento familiar": 0,
                "Realización de examen, curso": 0,
                "Excedencia por conciliación": 0,
                "Excedencia voluntaria": 0,
                "Baja de la empresa": 0,
                "Cambio de jornada": 0
              }
          })
        }
      }).flat();
    }else if(this.selectedEmployessForPdfDownload.length >res.length){
      this.selectedEmployessForPdfDownload.forEach(data=>{
        let obj={
          "employeeName": data,
          "clientName": data.clientName+"bbb",
          // "totalLeave": "totalLeave",
          "Asistencia al médico": 0,
          "Vacaciones": 0,
          "Enfermedad": 0,
          "Nacimiento de hijo": 0,
          "Libre disposición": 0,
          "Matrimonio": 0,
          "Fallecimiento familiar": 0,
          "Realización de examen, curso": 0,
          "Excedencia por conciliación": 0,
          "Excedencia voluntaria": 0,
          "Baja de la empresa": 0,
          "Cambio de jornada": 0
        }
           res.push(obj)
      })
    }
    
    console.log("cccc", res)
    console.log("cccc1", res1)
    let k;
    if(res.length>this.selectedEmployessForPdfDownload.length){
     res=res.filter((a, i) => res.findIndex((s) => a.employeeName === s.employeeName) === i) 
      console.log("converted", res)
    }
    else{
    res=res.filter((a, i) =>{
      
      let p=  res1.filter((s) =>JSON.stringify(a).localeCompare(JSON.stringify(s)) ==0)
      if(p.length>0){
       return p
      }
      console.log("pppp",p)
       }  )
  }

   
//     {
// console.log("JSON.stringify(a)",JSON.stringify(a))
// console.log("JSON.stringify(s)",JSON.stringify(s))
// if(JSON.stringify(a).localeCompare(JSON.stringify(s)) ==0 ){
//   console.log("ss",s)
// return s

// }
    
    // }
  
      
    console.log("cccc final", res)

    let doc = new jsPDF('l', 'pt', 'a4');

    let columns = [
      { title: "Empleado", dataKey: "employeeName" },
      { title: "Company", dataKey: "clientName" },
      // { title: "totalLeave", dataKey: "totalLeave" },
      { title: "Asistencia al médico", dataKey: "Asistencia al médico" },
      { title: "Vacaciones", dataKey: "Vacaciones" },
      { title: "Enfermedad", dataKey: "Enfermedad" },
      { title: "Libre disposición", dataKey: "Libre disposición" },
      { title: "Matrimonio", dataKey: "Matrimonio" },
      { title: "Nacimiento de hijon", dataKey: "Nacimiento de hijo" },
      { title: "Fallecimiento familiar", dataKey: "Fallecimiento familiar" },
      { title: "Realización de examen, curso", dataKey: "Realización de examen, curso" },
      { title: "Excedencia por conciliación", dataKey: "Excedencia por conciliación" },
      { title: "Excedencia voluntaria", dataKey: "Excedencia voluntaria" },
      { title: "Baja de la empresa", dataKey: "Baja de la empresa" },
      { title: "Cambio de jornada", dataKey: "Cambio de jornada" },


    ];
    let clintName = this.ClentName;
    let width = doc.internal.pageSize.getWidth()
    doc.autoTable(columns, res, {
      // margin: { top: 100 },
      // addPageContent: function (data) {
      //   doc.setFontSize(9);

      //   doc.text(`INFORME DE ASISTENCIA SOLICITADO Y GENERADO HASTA ${month.toUpperCase()}/${date}`, width / 2, 20, { align: 'center' });
      //   doc.text(`EMPRESA:       ${clintName}`, 40, 60);
      //   //doc.text(`INFORME GENERADO EL: ${new Date().toLocaleDateString()}`, 40, 80);
      //   //doc.text(`INFORME GENERADO EL: ${new Date().getDate()}`, 40, 80);
      //   //doc.text(`INFORME GENERADO EL: ${new Date().toLocaleString()}`, 40, 80);
      //   doc.text(`INFORME GENERADO EL: ${new Date().toLocaleString('es-ES', {hour12: false})}`, 40, 80);
      // }

      margin: { horizontal: 10 },
      styles: { overflow: "linebreak" },
      bodyStyles: { valign: "top" },
      columnStyles: { email: { columnWidth: "wrap" } },
      theme: "striped",
      showHead: "everyPage",
      didDrawPage: function (data) {
    
        // Header
        doc.setFontSize(9);
        doc.setTextColor(40);
        //doc.text("Report", data.settings.margin.left, 22);
        doc.text(`INFORME GENERADO EL: ${new Date().toLocaleString('es-ES', {hour12: false})}`, data.settings.margin.left, 22);
    
        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();
    
        doc.setFontSize(10);
    
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      }
    });

    doc.save(`${date}-${month.toUpperCase()}.pdf`);
  })
}






















companydoSelection(idCliente,event) {
  console.log("event...",idCliente)

  if(event.target.checked==true){
    console.log('checkbox is checked');
    //alert(idCliente+"checkbox is checked");
  }
  else{
    console.log('checkbox is unchecked');
    //alert(idCliente+"checkbox is unchecked");
  }

  this.clientesShowHideAll = idCliente;

  console.log("all checked list",this.clientesShowHideAll);

  this.allLoadEmpleados();
  


}





empleadoSelection(emp,event) {
  console.log("event...",event.checked)

  if (this.selectedEmployessForPdfDownload.includes(emp.empleado)) {
    this.selectedEmployessForPdfDownload = this.selectedEmployessForPdfDownload.filter(data => data != emp.empleado)
  } else {
    this.selectedEmployessForPdfDownload.push(emp.empleado)
  }
  console.log("this.empleado", emp)
  console.log("this.selectedEmployessForPdfDownload", this.selectedEmployessForPdfDownload)
}
empleadoSelectionSelectAll(empleadoss,event) {
  console.log("event...all",event.checked)

  console.log("empleadoss", empleadoss)
  if(event.checked==true){

    this.selectedEmployessForPdfDownload = empleadoss.map(data => data.empleado)
  }else{
    this.selectedEmployessForPdfDownload=[];
  }
}

}
