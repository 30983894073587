import { Component, OnInit } from '@angular/core';
import { Input,ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import {MenuItem} from 'primeng/api';     
import {ChartModule} from 'primeng/chart';
import {TableModule} from 'primeng/table';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { FechaTipadaPipe } from '../../libs/fechaTipada.pipe';
import { Utilidades } from '../../libs/utilidades';
import { UtilService } from '../../servicios/util.service';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {FileUpload} from 'primeng/fileupload';
import { Router,ActivatedRoute  } from "@angular/router";
import { Respuesta } from '../../respuesta';

@Component({
  selector: 'app-impuestos',
  templateUrl: './impuestos.component.html',
  styleUrls: ['./impuestos.component.scss']
})
export class ImpuestosComponent implements OnInit {

  //itemsArbol:TreeNode[];	
	//items10: MenuItem[];
	//menuId: number=0;
	data: any;
	cars:any;
	//ejercicios:any;
	//ejercicio: number=0;
	ejercicio2: number=0;
	documento:string="";
	rango_modelos:any;
	color:any;
	
	//materias:any;
	materia:number=0;
	tiposModelo:any;
	tipos:any;
	strFilterModelos:string="";
	//strFilterMaterias:string="";
	tabla:Array<Array<Object>>=[];
	
	//@ViewChild('envioDoc') envioDoc:FileUpload;  //ElementRef
	
	constructor(private httpClient: HttpClient,public gds:GlobaldataService,private us:UtilService,private messageService: MessageService,
						private router:Router, private routera: ActivatedRoute)
	{
		
		this.rango_modelos=Utilidades.range(2,13);
		
		this.color=['#FABF8F','red','#FABF8F','#FABF8F'];
		
	
	
	this.data = {
            labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
            datasets: [
                {
                    label: 'Facturas recibidas de Telefonica S.L.',
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: [65, 59, 80, 81, 56, 55, 40,46]
                }
            ]
        }
	}

	ngOnInit()
	{ 		
		var queryparams="where=C.idCliente="+this.gds.id_cliente;
		queryparams+="&joins=clientes(idTipoCliente=>idTipo)";
		queryparams+="&fields=C.idCliente,T.*"
		this.httpClient.get<Respuesta>(this.gds.urlBaseDL+'tipocliente?'+queryparams).toPromise().then((res)=>{
			console.log(res);
			this.tiposModelo=res.resp[0];
			this.tiposModelo["anuales"]=this.tiposModelo["anuales"].split(",");
			this.tiposModelo["trimestrales"]=this.tiposModelo["trimestrales"].split(",");
			this.tiposModelo["acuenta"]=this.tiposModelo["acuenta"].split(",");
			this.tiposModelo["mensuales"]=this.tiposModelo["mensuales"].split(",").filter((m:String)=>{return m.substr(0,1)!='['});
			
			var l=this.tiposModelo["anuales"];
			l=l.concat(this.tiposModelo["trimestrales"]);
			l=l.concat(this.tiposModelo["acuenta"]);
			l=l.concat(this.tiposModelo["mensuales"]);

			this.tipos=l;

			l=l.filter((m:String)=>{return m.substr(0,1)!='['});
			//console.log(l)

			l=l.map((m:String)=>{return m.trim();});
			this.strFilterModelos=l.join("|");
			//console.log(this.strFilterModelos);		

			//console.log(l.filter((m:String)=>{return m.substr(0,1)=='['}));

			//this.strFilterMaterias=this.tipos.filter((m:String)=>{return m.substr(0,1)=='['}).map((t)=>{return "'"+t.substr(1,t.length-2)+"'";}).join(",");
			//console.log(this.strFilterMaterias);
			
			this.filtrarImpuestos();
		});

		//this.ejercicio=this.gds.ejercicios[0].value;
		this.ejercicio2=this.gds.ejercicios[0].value;
	

		/*this.gds.get_materias().then((res)=>{
			var mats=(res.filter(mat => mat[1] === 0 && mat[0]!=200));
			this.materias=mats.map((mat) => {
				return { label: mat[2], value: mat[0] };
			});
		});*/
		

		//SELECT D.*,M.materia as materia,M2.materia as submateria,tercero,C.cliente as cliente,U.nombre as user,U2.nombre as user2 FROM Documentos as D LEFT JOIN Terceros as T ON D.idTercero=t.idTercero LEFT JOIN Clientes as C ON C.idCliente=D.idCliente LEFT JOIN Materias as M ON D.idMateria=M.idMateria LEFT JOIN Materias as M2 ON D.idSubMateria=M2.idMateria LEFT JOIN Usuarios as U2 ON D.idUserCreate=U2.idUsuario LEFT JOIN Usuarios as U ON D.idUsuario=U.idUsuario  WHERE D.idCliente=205 AND(etiquetas rlike '.2018') AND ((etiquetas rlike '^100.|^180.|^190.|^200.|^347.|^ 390.|^202.|^111.|^115.|^303.')  OR M2.materia='Nóminas' OR M2.materia='Resumen Nominas')
		
	}
	
	filtrarImpuestos()
	{
		this.tabla=[];
		for(var m of this.tiposModelo["mensuales"])
		{
			if(m.substr(0,1)=='[') m=m.substr(1,m.length-2);
			var t=[0,m];
			for(let m=2;m<14;m++) t.push('x');
			this.tabla.push(t);
		}
		for(var m of this.tiposModelo["trimestrales"])
		{
			if(m.substr(0,1)=='[') m=m.substr(1,m.length-2);
			var t=[1,m];
			for(let m=2;m<14;m++) t.push('');
			t[4]='x';t[7]='x';t[10]='x';t[13]='x';
			this.tabla.push(t);
		}
		for(var m of this.tiposModelo["acuenta"])
		{
			if(m.substr(0,1)=='[') m=m.substr(1,m.length-2);
			var t=[2,m];
			for(let m=2;m<14;m++) t.push('');
			t[5]='x';t[11]='x';t[13]='x';
			this.tabla.push(t);
		}
		for(var m of this.tiposModelo["anuales"])
		{
			if(m.substr(0,1)=='[') m=m.substr(1,m.length-2); 
			var t=[3,m];
			for(let m=2;m<14;m++) t.push('');
			t[13]='x';
			this.tabla.push(t);
		}
		
		var filtro="idCliente="+this.gds.id_cliente+" AND fechaEjercicio LIKE '"+this.ejercicio2+"%' AND ((etiquetas rlike '^MOD,+("+this.strFilterModelos+")+($|,)') ";
		//if(this.strFilterMaterias.length>0) filtro+=" OR M.materia IN ("+this.strFilterMaterias+") )"; else 
		filtro+=")";
console.log(filtro);
		this.httpClient.post<Respuesta>(this.gds.urlBaseDL+'documentos/query',{
			fields:'D.idDocumento,D.etiquetas,M.materia,substr(fechaejercicio,6,2) as fecha'
			,joins:"materias(idSubMateria)"
			,where:filtro
		}).subscribe((res)=>{
			console.log(res);
			const i_meses={'01':2,'02':3,'03':4,'04':5,'05':6,'06':7,'07':8,'08':9,'09':10,'10':11,'11':12,'12':13,
			'T1':4,'T2':7,'T3':10,'T4':13,'P1':5,'P2':11,'P3':13,'':13 };
			for(let modelo of res.resp)
			{
				for(let i=0;i<this.tipos.length;i++)
				{
					//if (modelo['etiquetas'].startsWith("MOD,"+this.tabla[i][1]) || modelo['M_materia']==this.tabla[i][1])
					if (modelo['etiquetas'].startsWith("MOD,"+this.tabla[i][1]))
					{
						//console.log(modelo['fecha'],i_meses[modelo['fecha']]);
						this.tabla[i][i_meses[modelo['fecha']]]=modelo['idDocumento'];
						break;
					}
				}
			}
		});
	}
	
	isNumber(obj:Object)
	{
		return (typeof(obj) === 'number');
		//return typeOf(obj);
	}
	
	/*cmd_ver_modelo(ev)
	{
		//console.log(ev);
		this.gds.viewDoc(ev,true);
		return false;
	}*/
	
	


}
