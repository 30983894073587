<div class="grid r-grid r-grid12-6 clientproject-css" style="width:'100%';">
    
    <div class="g-w12field">
        <input type="hidden" id="idClient_project" [disabled]="readonly" [(ngModel)]="clientproject.idClient_project"
         />

         <input type="hidden" id="clientId" [disabled]="readonly" [(ngModel)]="clientproject.clientId"
         />
        

        </div> 
        

    <!-- <div class="g-w12field">
        <label for="complaintPrivacy">Empresa</label>

       
        <p-dropdown placeholder="Seleccione"  id="clientId"  [options]="categoryList" [(ngModel)]="clientproject.clientId"  [showClear]="true"  [readonly]="true"></p-dropdown>

         
    </div> -->

  
   

    

    <div class="g-w12field" >
        <label>Proyecto</label>
        <input pInputText id="project_name" [(ngModel)]="clientproject.project_name" styleClass="fecha-hora"   />
    </div>


    <div class="g-w12field">
        <label for="complaintPrivacy">Asignado a:</label>

       
        <p-multiSelect placeholder="Seleccione"  id="employees"  [options]="empList" [(ngModel)]="clientproject.employees" display="chip" ></p-multiSelect>


        <!-- <p-multiSelect [options]="cities" [(ngModel)]="selectedCities1" defaultLabel="Select a City" optionLabel="name">
        </p-multiSelect> -->

         
    </div> 



    <div class="g-w12field" >
        <label for="startDate">Fecha de inicio</label> 
        <!-- <input pInputText id="project_name" [(ngModel)]="clientproject.project_name" styleClass="fecha-hora"    /> -->

        <p-calendar id="startDate" [(ngModel)]="clientproject.startDate" [showIcon]="true" [locale]="dateConfig"
            [touchUI]="true" [selectOtherMonths]="true" [readonlyInput]="true"  dateFormat="dd/mm/yy"></p-calendar>
    </div>


    <div class="g-w12field" >
        <label for="endDate">Fecha de fin</label> 
        <p-calendar id="endDate" [(ngModel)]="clientproject.endDate" [showIcon]="true" [locale]="dateConfig"
        [touchUI]="true" [selectOtherMonths]="true" [readonlyInput]="true"  dateFormat="dd/mm/yy"></p-calendar>
    </div>


    <!-- <div class="g-w12field" >
        <label>Contraseña</label>
        <input pInputText id="collab_password" [(ngModel)]="clientcollaborator.collab_password" styleClass="fecha-hora"    />
    </div> -->



    <div class="g-w12field" >
        <label>Nº total horas</label>
        <!-- <input pInputText id="total_no" [(ngModel)]="clientproject.total_no" styleClass="fecha-hora"   /> -->

        <input  pInputText type="number" id="total_no" [(ngModel)]="clientproject.total_no" styleClass="fecha-hora"   /> 

    </div>



    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w2">
                
                <p-button *ngIf="!readonly" label="Guardar" (click)="solicitar()"></p-button>
                <p-button *ngIf="readonly" label="Guardar" (click)="saveClientproject()" style="margin-right:10px"></p-button>
                <!-- <p-button *ngIf="readonly" label="Guardar" (click)="saveClientcollaborator()" style="margin-right:10px"></p-button> -->
                
            </div>
        </div> 
    </div>


</div>


























<div *ngIf="pagetype=='detail'" class="grid r-grid r-grid12-6 clientproject-css" style="width:'100%';"> 
    
  

    <div class="g-w12field" *ngIf="allclientprojectemployee.length > 0">
    <div  class="p-grid p-justify-start"  *ngFor="let itememp of allclientprojectemployee">
        <div class="p-col-12 p-md-12 p-lg-12">
        
        <fieldset>
            <div class="p-grid">
                <div class="p-col-12">
        <legend><strong> Trabajador: </strong> {{itememp.employeeName}} </legend>
        <table class="clientprojectdetail-table" *ngIf="itememp.mainDetail.length > 0">
            <thead>
              <!-- <th>Fecha</th>
              <th>Hora/s Minuto/s</th>
              <th>Proyecto</th> -->
              <th>Descripcion</th>
            </thead>
            <tbody>
              <tr  *ngFor="let itemempsub of itememp.mainDetail">
                <!-- <td>{{itemempsub.dateTime}}</td>
                <td>{{itemempsub.hour}}h{{itemempsub.minute}}min</td>
                <td>{{itemempsub.projectName}}</td> -->
                <td>{{itemempsub.work_desc}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
        </fieldset>

    </div>
</div>
</div>

</div>








<p-toast position='center'></p-toast>