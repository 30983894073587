
<p-dialog header="Trámites del asunto:  {{tarea.tarea}}" [positionTop]="100" [contentStyle]="{'max-height':'80vh'}" [modal]="true" [blockScroll]="false" [(visible)]="displayAsunto" [style]="{'width':'80%'}">

<div *ngIf="tarea.consulta!==''">
	<br/>
	<p style="font-weight: 300;background-color:ligthgrey;border:1px solid grey">Consulta: {{tarea.consulta}}</p>
</div>

<div class="card">
    <p-timeline [value]="tarea.tramites" align="alternate">
        <ng-template pTemplate="content" let-tramite>
			{{tramite.tramite}} 
			<br/><small>[{{tramite.fecha}}]</small>
			<ul *ngIf="tramite.docs.length>0" class="custom-list-tablaCuadro2" (click)="gds.viewDoc($event);">
				<li class="enlace" *ngFor="let doc of tramite.docs" [attr.data-id]='doc.id'>
					<i class="fa fa-file"></i>
					{{doc.documento}}
				</li>
				
			</ul>
        </ng-template>
    </p-timeline>
</div>

<!--
<ol class="custom-list-tablaCuadro">
<li *ngFor="let tramite of tarea.tramites">
	[{{tramite.fecha}}] - {{tramite.tramite}}
	
	<ul *ngIf="tramite.docs.length>0" class="custom-list-tablaCuadro2" (click)="gds.viewDoc($event);">
		
	<li *ngFor="let doc of tramite.docs">
		<a href="#" [attr.data-id]='doc.id'>
		<i class="fa fa-file"></i>
		{{doc.documento}}
		</a>
	</li>
	
	</ul>
	
</li>
</ol>
-->
</p-dialog>
